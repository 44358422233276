import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Alert, Button, Heading, Layout, TextField } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import CImage from "../common/CImage";
import AuthService from "../auth/auth-service";

const Login = () => {
  const [inputLogin, setInputLogin] = useState({ email: "", password: "" });
  const [message, setMessage] = useState("");

  const { updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  const service = new AuthService();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const user = await service.login(inputLogin.email, inputLogin.password);

      updateUser(user);
      if (user && user.hasRole("FLEET_ADMIN")) {
        navigate("/");
      } else {
        setMessage("You don't have access to the control tower");
      }
    } catch (err) {
      setMessage(err.response.data.message || "Connection error");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputLogin((prevInputLogin) => ({ ...prevInputLogin, [name]: value }));
  };

  return (
    <Layout direction="row" spacing={4} sx={{ width: "100vw", height: "100vh" }}>
      <Layout direction="column" justifyContent="center" spacing={4} sx={{ width: "50%", padding: 8 }}>
        <Heading variant="h2">
          Welcome to the
          <br />
          <Heading component="span" variant="h2" color="primary">
            Fleet Control Tower
          </Heading>
        </Heading>

        {message && <Alert severity="warning">{message}</Alert>}

        <Layout direction="column" spacing={2}>
          <TextField
            type="email"
            name="email"
            label="Email"
            value={inputLogin.email}
            onChange={handleChange}
            error={message}
          />

          <TextField
            type="password"
            name="password"
            label="Password"
            value={inputLogin.password}
            onChange={handleChange}
            error={message}
          />

          <Button variant="contained" color="primary" label="Login" onClick={handleSubmit} fullWidth />
        </Layout>
      </Layout>
      <Layout direction="column" sx={{ width: "50%" }} fullHeight justifyContent="flex-end">
        <CImage label="login_rh2pi0.png" />
      </Layout>
    </Layout>
  );
};

export default Login;
