import React, { useContext, useState } from "react";

import { useNavigate } from "react-router";
import { Button, Dialog, Icon, IconButton, Menu, MenuItem } from "@fleet.co/tarmac";
import { faChevronDown, faChevronRight, faEllipsisVertical, faFileArchive } from "@fortawesome/pro-regular-svg-icons";
import FormatDate from "../../../tools/FormatDate";
import { formatMoney } from "../../../helpers/formatHelpers";
import FileValidationSubRow from "./FileValidationSubRow";

import { downloadFileBlob } from "../../../helpers/FilesHelpers";
import styles from "../../views/FileValidation.module.scss";
import UserContext from "../../../tools/UserContext";

const FileValidationRow = (props) => {
  const { order, reload } = props;
  const [isOpen, setOpen] = useState(false);
  const [showZipDownload, setShowZipDownload] = useState(false);
  const { user: adminUser } = useContext(UserContext);

  const orderFilesUrl = `/orders/${order.id}/files`;
  const companyFilesUrl = `/companies/${order.company.id}/files`;

  const navigate = useNavigate();

  const menuContent = [
    { label: "All Order Files", action: () => navigate(orderFilesUrl), icon: <Icon icon={faFileArchive} /> },
    { label: "All Company Files", action: () => navigate(companyFilesUrl), icon: <Icon icon={faFileArchive} /> },
  ];

  const globalStatusData = {
    ERROR: { color: "#F5ABAB", text: "ERROR" },
    INCOMPLETE: { color: "#F5ABAB", text: "Incomplete" },
    WAITING_VALIDATION: { color: "#F8CCA4", text: "Waiting Validation" },
    VALIDATED: { color: "#A4DBC1", text: "Validated" },
  };

  const globalStatus = globalStatusData[order.globalFileStatus];

  const downloadZip = async () => {
    const response = await adminUser.api.getOrderZipFiles(order.id);

    downloadFileBlob(response.data, `contract_${order.contract_number}.zip`);
    setShowZipDownload(false);
  };

  const allFiles = order.filesToDisplay;
  const sortByUpdateDate = (o1, o2) => o2.updated_at - o1.updated_at;
  const lastUpdateFile = allFiles.sort(sortByUpdateDate)[0];

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setShowZipDownload(false)} />
      <Button color="primary" label="Download .ZIP" onClick={downloadZip} />
    </>
  );

  return (
    <>
      <tr onClick={() => setOpen(!isOpen)}>
        <td>{order.request_number || "-"}</td>
        <td>
          <span className={styles.spanLink} onClick={() => navigate(`/companies/${order.company_id}`)}>
            {order.company.name}
          </span>
        </td>
        <td>{`${order.purchaser.firstName} ${order.purchaser.lastName}`}</td>
        <td className={styles.filesStatusTd}>
          <span className={styles.filesStatusTag} style={{ backgroundColor: globalStatus.color }}>
            {globalStatus.text}
          </span>
        </td>
        <td>
          <FormatDate date={order.order_date} />
        </td>
        <td style={{ textAlign: "right" }}>{formatMoney(order.rent * order.contract_duration, order.rent_currency)}</td>
        <td>{order.leaser || "-"}</td>
        <td> {<FormatDate date={lastUpdateFile.updated_at} /> || "-"}</td>
        <td className={styles.actionsCell}>
          <div className={styles.chevron}>
            <Icon
              onClick={() => setOpen(!isOpen)}
              icon={isOpen ? faChevronDown : faChevronRight}
              color="secondary"
              size="S"
            />
          </div>
          <div style={{ position: "relative" }} onClick={(e) => e.stopPropagation()}>
            <Menu
              AnchorComponent={IconButton}
              anchorProps={{ icon: <Icon icon={faEllipsisVertical} />, color: "secondary", size: "M" }}
            >
              {menuContent.map((item, index) => (
                <MenuItem key={index} label={item.label} icon={item.icon} onClick={item.action} />
              ))}
            </Menu>
          </div>
        </td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan="8" className={styles.subTable}>
            <table>
              <thead>
                <tr>
                  <td style={{ width: "15%" }}>File Type</td>
                  <td style={{ width: "30%" }}>File Name</td>
                  <td style={{ width: "10%" }}>User</td>
                  <td style={{ width: "15%" }}>Status</td>
                  <td style={{ width: "10%" }}>Upload Date</td>
                  <td style={{ width: "10%" }}>Notification Date</td>
                  <td style={{ width: "10%" }}>
                    Actions
                    {order.globalFileStatus === "VALIDATED" && order.status === "LEASER_MORE_DOCUMENTS" && (
                      <Icon
                        className={styles.downloadIcon}
                        icon={faFileArchive}
                        onClick={() => setShowZipDownload(true)}
                        color="secondary"
                        size="S"
                      />
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {order.globalFileStatus === "ERROR"
                  ? "INFORMATIONS MANQUANTES"
                  : order.filesToDisplay.map((file) => (
                      <FileValidationSubRow order={order} key={file.id} file={file} reload={reload} />
                    ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
      {/* ======= VALIDATED global file status : download a zip */}

      <Dialog
        onClose={() => setShowZipDownload(false)}
        title="Compress Files"
        open={showZipDownload}
        Actions={ActionComponent}
      >
        Do you want to compress all the accepted files for {order.company.name}?
      </Dialog>
    </>
  );
};

export default FileValidationRow;
