import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Drawer, Heading, Layout, Select, Text, TextField } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

import OfferCompanyDetails from "./OfferCompanyDetails";
import { useToastContext } from "../../../contexts/ToastContext";

const offerSourceOptions = [
  { label: "-", value: "" },
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Offer", value: "OFFER" },
];

const offerTypeOptions = [
  { label: "-", value: "" },
  { label: "No discount", value: "NO_DISCOUNT" },
  { label: "1 month offered", value: "MONTH_OFFERED" },
  { label: "2 months offered", value: "2_MONTHS_OFFERED" },
  { label: "3 months offered", value: "3_MONTHS_OFFERED" },
];

const emptyOffer = {
  name: "",
  partner_name: "",
  source: "",
  type: null,
  code: null,
  title: null,
  description: null,
  first_order: false,
  validity_start: null,
  validity_end: null,
  orders: [],
};

const PunctualOfferModal = (props) => {
  const { reload, closeAction, offer: offerFromProps, open } = props;

  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();
  const [isLoading, setIsLoading] = useState(false);
  const [allFieldsAreFilled, setAllFieldsAreFilled] = useState(false);
  const [offer, setOffer] = useState(emptyOffer);

  const editMode = Boolean(offerFromProps);

  const handleChange = (name, value) => {
    setOffer({ ...offer, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setOffer({ ...offer, [name]: value });
  };

  const saveChange = useCallback(async () => {
    setIsLoading(true);

    try {
      if (editMode) {
        // Add check if slug exist in backend
        await adminUser.api.modifyOffer(offerFromProps.id, offer);
      } else {
        await adminUser.api.addOffer(offer);
      }
      setIsLoading(false);
      reload();
      closeAction();
    } catch (err) {
      setIsLoading(false);
      addToast(err.response?.data?.message || "Couldn't proceed. Please verify your information");
    }
  }, [adminUser.api, closeAction, editMode, offer, offerFromProps, reload, addToast]);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await adminUser.api.deleteOffer(offerFromProps.id, offer);
      setIsLoading(false);
      reload();
      closeAction();
    } catch (err) {
      setIsLoading(false);
      addToast(err.response?.data?.message || "Couldn't proceed.");
    }
  }, [adminUser.api, closeAction, offer, offerFromProps, reload, addToast]);

  useEffect(() => {
    if (!offer.source) {
      setAllFieldsAreFilled(false);

      return;
    }

    // Check if all fields are filled in case of partnership or in case of offer
    const mandatoryFields = {
      PARTNERSHIP: ["name", "type", "code", "validity_start", "validity_end", "partner_name"],
      OFFER: ["name", "source", "type", "code", "title", "description"],
    };

    const isPartnershipFilled = mandatoryFields[offer.source].every((field) => offer[field] !== null);

    setAllFieldsAreFilled(isPartnershipFilled);
  }, [offer]);

  useEffect(() => {
    if (offerFromProps) {
      setOffer(offerFromProps);
    } else {
      setOffer(emptyOffer);
    }
  }, [offerFromProps]);

  const Actions = (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={saveChange}
        loading={isLoading}
        disabled={!allFieldsAreFilled}
        label={editMode ? "Save changes" : "Create"}
      />

      {editMode ? (
        <Button variant="outlined" color="error" onClick={handleDelete} disabled={!allFieldsAreFilled} label="Delete" />
      ) : null}
    </>
  );

  return (
    <Drawer open={open} onClose={closeAction} title={editMode ? "Edit Promo" : "Add promo"} Actions={Actions}>
      <Layout direction="column" spacing={3} isScrollable fullHeight padding={1}>
        <Select
          label="Offer type"
          value={offer.source || ""}
          options={offerSourceOptions}
          onChange={(value) => handleChange("source", value)}
          disabled={editMode}
        />

        {offer.source && (
          <Layout direction="column" spacing={3}>
            {offer.source === "OFFER" && (
              <Checkbox
                label="First order only"
                disabled={offer.source === "PARTNERSHIP"}
                checked={offer.first_order}
                onChange={() => setOffer({ ...offer, first_order: !offer.first_order })}
              />
            )}
            <Layout direction="row" spacing={2}>
              <TextField
                label="Name"
                name="name"
                placeholder="Fleet"
                onChange={handleInputChange}
                value={offer.name}
                disabled={editMode}
              />

              <Select
                label="Month offered"
                value={offer.type}
                options={offerTypeOptions}
                onChange={(value) => handleChange("type", value)}
                disabled={editMode}
              />
            </Layout>

            {offer.source === "PARTNERSHIP" && (
              <Layout direction="row" spacing={2}>
                <TextField
                  label="Partner Name"
                  name="partner_name"
                  placeholder="Ironhack"
                  onChange={handleInputChange}
                  value={offer.partner_name}
                  disabled={editMode}
                />
              </Layout>
            )}

            {offer.source === "PARTNERSHIP" && (
              <Layout direction="row" spacing={2}>
                <Layout direction="column" spacing={0.5} fullWidth>
                  <DatePicker
                    label="Starting Date"
                    name="validity_start"
                    onChange={(date) => handleChange("validity_start", date)}
                    value={offer.validity_start}
                  />
                </Layout>
                <Layout direction="column" spacing={0.5} fullWidth>
                  <DatePicker
                    label="Ending Date"
                    name="validity_end"
                    onChange={(date) => handleChange("validity_end", date)}
                    value={offer.validity_end}
                  />
                </Layout>
              </Layout>
            )}

            <Layout direction="row" alignItems="center" spacing={2}>
              <Text variant="body1">
                {import.meta.env.REACT_APP_WEBSITEURL}
                /p/
              </Text>
              <TextField
                label="Promo Link"
                name="code"
                placeholder="Code ..."
                onChange={handleInputChange}
                value={offer.code}
                disabled={editMode}
              />
            </Layout>

            {offer.source === "OFFER" && (
              <Layout direction="column" spacing={2}>
                <TextField
                  label="Edit title"
                  name="title"
                  placeholder="Florian sponsors you"
                  onChange={handleInputChange}
                  value={offer.title}
                />

                <TextField
                  label="Edit description"
                  name="description"
                  multiline
                  rows={4}
                  placeholder="Get one month's rent free on your next order."
                  onChange={handleInputChange}
                  value={offer.description}
                />
              </Layout>
            )}

            {editMode && (
              <Button
                variant="outlined"
                color="secondary"
                href={`${import.meta.env.REACT_APP_WEBSITEURL}/p/${offer.code}`}
                label="Preview"
              />
            )}

            {Boolean(offer?.orders?.length) && (
              <Layout direction="column" spacing={3}>
                <Heading variant="h3">Sponsored Companies :</Heading>
                <Layout direction="column">
                  {offer.orders.map((order, i) => (
                    <OfferCompanyDetails key={i} order={order} />
                  ))}
                </Layout>
              </Layout>
            )}
          </Layout>
        )}
      </Layout>
    </Drawer>
  );
};

export default PunctualOfferModal;
