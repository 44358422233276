import React from "react";
import { Link } from "react-router";
import { Button, Icon, Layout, Text, theme } from "@fleet.co/tarmac";
import { faTowerObservation } from "@fortawesome/pro-regular-svg-icons";
import SearchBar from "../blocks/SearchBar";
import CImage from "../common/CImage";

import useLocalStorage from "../../hooks/useLocalStorage";
import { SearchProvider } from "../../contexts/SearchContext";

const Header = () => {
  const [, , removeAccessToken] = useLocalStorage("accessToken");

  return (
    <SearchProvider>
      <Layout
        direction="row"
        fullWidth
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        sx={{
          height: "75px",
          padding: "0 8px",
          position: "fixed",
          backgroundColor: theme.palette.background.secondary,
          zIndex: 500,
          top: 0,
          "& > *": {
            flewGrow: 1,
          },
        }}
      >
        <Link to="/">
          <Layout direction="row" alignItems="center" spacing={1}>
            <CImage label="fleet_newLogo_wdsjyf" width={100} />
            <Icon icon={faTowerObservation} size="M" />
            <Text color="info">Control Tower</Text>
          </Layout>
        </Link>

        <SearchBar />

        <Layout direction="row" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            href={`${import.meta.env.REACT_APP_COCKPITURL}/devices`}
            targetBlank
            label="Go to Cockpit"
          />

          <Button variant="text" color="secondary" label="Log Out" href="/" onClick={removeAccessToken} />
        </Layout>
      </Layout>
    </SearchProvider>
  );
};

export default Header;
