import React, { useContext, useState } from "react";
import { Icon } from "@fleet.co/tarmac";
import {
  faAddressCard,
  faCheck,
  faEdit,
  faEye,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import FileTypeNiceName from "../../blocks/FileTypeNiceName";
import FileStatusNiceName from "../../blocks/FileStatusNiceName";
import DeleteConfirmModal from "../../blocks/DeleteConfirmModal";
import UserContext from "../../../tools/UserContext";
import styles from "./CompanyFileRow.module.scss";
import UsersSelect from "../../common/UsersSelect";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyFileRow = (props) => {
  const { file } = props;
  const { company } = useContext(CompanyContext);
  const { user: adminUser } = useContext(UserContext);
  const [isEditable, setIsEditable] = useState(false);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const user = file.user ? `${file.user.firstName} ${file.user.lastName}` : "Not Assigned";

  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const handleShowConfirmDelete = () => {
    setshowConfirmModal(!showConfirmModal);
  };
  const updateFileStatus = async (status) => {
    await adminUser.api.modifyFile(file.id, { status });
    props.reload();
  };

  const fileTypeIcon = {
    BALANCE_SHEET: faFileAlt,
    KBIS: faFileAlt,
    SIGNATORY_ID: faAddressCard,
    SIGNATORY_ID_VERSO: faAddressCard,
    IBAN: faFileInvoice,
    SIGNING_AUTHORITY: faFileAlt,
    SEPA_MANDATE: faFileInvoiceDollar,
    INSURANCE: faFileAlt,
  };

  const handleChange = (userId) => {
    props.onChangeUser(file.id, userId);
  };

  return (
    <tr key={`${file.company_id}-${file.id}`}>
      <td>
        <div className={styles.flex}>
          <div className={styles.typeIconContainer}>
            <Icon className={styles.typeIcon} icon={fileTypeIcon[file.type]} color="secondary" size="S" />
          </div>
          <FileTypeNiceName type={file.type} />
        </div>
      </td>

      <td>
        {`${company ? company.name : ""} - `}
        <FileTypeNiceName type={file.type} />
        {" - "} {file.original_name || file.description}
      </td>

      <td className="bo-dropdown-menu-container" onClick={() => setIsStatusMenuOpen(!isStatusMenuOpen)}>
        <FileStatusNiceName status={file.status || "EMPTY"} isEditable />
        {isStatusMenuOpen && (
          <div className="bo-dropdown-menu invoice-menu">
            <option onClick={() => updateFileStatus("TO_EXAMINE")}>TO EXAMINE</option>
            <option onClick={() => updateFileStatus("ACCEPTED")}>ACCEPTED</option>
            <option onClick={() => updateFileStatus("REFUSED")}>REFUSED</option>
          </div>
        )}
      </td>
      <td>
        <div className={styles.user}>
          {isEditable ? (
            <>
              <UsersSelect value={file.user_id} companyId={company.id} onChange={(value) => handleChange(value)} />
              <Icon
                style={{ marginLeft: "12px" }}
                icon={faCheck}
                onClick={() => setIsEditable(!isEditable)}
                color="secondary"
                size="S"
              />
            </>
          ) : (
            <>
              {user}
              <Icon
                style={{ marginLeft: "12px" }}
                icon={faEdit}
                onClick={() => setIsEditable(!isEditable)}
                color="secondary"
                size="S"
              />
            </>
          )}
        </div>
      </td>

      <td className="flex-row">
        <div className={styles.actionCellContainer}>
          <div className={styles.actionIconContainer}>
            <Icon icon={faEye} onClick={props.openFile} color="secondary" size="S" />
          </div>
          <div className={styles.actionIconContainer}>
            <Icon
              className={styles.trash}
              icon={faTrashAlt}
              onClick={handleShowConfirmDelete}
              color="secondary"
              size="S"
            />
          </div>
        </div>
      </td>

      <DeleteConfirmModal
        closeAction={handleShowConfirmDelete}
        deleteAction={props.deleteAction}
        target="this file"
        open={showConfirmModal}
      />
    </tr>
  );
};

export default CompanyFileRow;
