import React from "react";
import { Button, Dialog, Select } from "@fleet.co/tarmac";

const options = [
  {
    label: "Order history",
    value: "Historique des commandes",
  },
  {
    label: "Max outstanding amount",
    value: "Encours max atteint",
  },
  {
    label: "Spanish order",
    value: "Commande Espagnole",
  },
  {
    label: "German order",
    value: "Commande Allemande",
  },
  {
    label: "English order",
    value: "Commande Anglaise",
  },
  {
    label: "Customer/leaser relationship",
    value: "Relation client/leaser",
  },
  {
    label: "Sales/leaser relationship",
    value: "Relation sales/leaser",
  },
  {
    label: "Open envelope with another leaser",
    value: "Enveloppe ouverte chez un autre leaser",
  },
  {
    label: "Leaser rate more advantageous elsewhere",
    value: "Leaser rate plus avantageux ailleurs",
  },
  {
    label: "Reactivity of the leaser",
    value: "Réactivité du leaser",
  },
  {
    label: "Unpaid",
    value: "Impayé",
  },
  {
    label: "Only leaser having accepted",
    value: "Seul leaser ayant accepté",
  },
  {
    label: "Split order",
    value: "Commande splitée",
  },
  {
    label: "Amount too high",
    value: "Montant trop important",
  },
  {
    label: "Other",
    value: "Autre",
  },
];

const ConfirmLeaserChoice = (props) => {
  const { openModal, orderLeaserInfo, updateOrder, changeReason, open } = props;
  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => openModal(false)} />
      <Button
        color="primary"
        label="Send"
        onClick={() => orderLeaserInfo.leaser_reason && updateOrder() && openModal(false)}
      />
    </>
  );

  return (
    <Dialog title="Explain your choice" onClose={() => openModal(false)} Actions={ActionComponent} open={open}>
      <Select
        label="Tell us why you chose this leaser"
        options={options}
        onChange={(value) => changeReason(value)}
        value={orderLeaserInfo.leaser_reason}
      />
    </Dialog>
  );
};

export default ConfirmLeaserChoice;
