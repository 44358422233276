import React from "react";
import { Alert } from "@fleet.co/tarmac";

const ErrorNotification = (props) => {
  if (!props.errors || props.errors.length === 0) return null;

  return (
    <Alert severity="warning" onClose={props.clear}>
      {props.errors.map((errorMessage, i) => (
        <div key={i}>
          {errorMessage}
          <br />
        </div>
      ))}
    </Alert>
  );
};

export default ErrorNotification;
