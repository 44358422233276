import { useCallback, useEffect } from "react";

/**
 * exemple : https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
 * @param {function} effect
 * @param {Number} delay
 * @param {Array[deps]} deps
 */
const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

export default useDebouncedEffect;
