import React from "react";
import { Icon } from "@fleet.co/tarmac";
import { faCertificate, faCheck } from "@fortawesome/pro-regular-svg-icons";
import styles from "./AddDeviceProductGroupTable.module.scss";

import AddDeviceProductRow from "./AddDeviceProductRow";
import { productSpecs } from "../../../../data/productSpecs";
import { formatMoney } from "../../../../helpers/formatHelpers";

const AddDeviceProductGroupTable = (props) => {
  const getAvailableProductWording = () => {
    if (props.product_group.products.length > 1) {
      return (
        <>
          {" "}
          modèles
          <br /> disponibles
        </>
      );
    }

    return (
      <>
        {" "}
        modèle
        <br /> disponible
      </>
    );
  };

  const getLowerPrice = () => {
    const sorted_price = props.product_group.products
      .map((product) => product.prices.find((price) => price.currency === props.order.rent_currency)?.amount)
      .sort((a, b) => a - b);

    return formatMoney(sorted_price[0], props.order.rent_currency);
  };

  const getTypology = () => {
    const typologyKey = {
      new: { name: "NEUF", color: "#ACEACD" },
      asnew: { name: "CERTIFIÉ APPLE", color: "#B0B2DB" },
      refurb: { name: "RECONDITIONNÉ +", color: "#F8CCA4" },
    };

    const typologyDevicesList = props.product_group.products
      .map((product) => product.quality)
      .filter((quality) => quality !== null);

    const typologyDevicesListWithoutDuplicate = [...new Set(typologyDevicesList)];
    const typologyNicenameList = typologyDevicesListWithoutDuplicate.map((s) => typologyKey[s].name);
    const typologyNicenameListJoined = typologyNicenameList.join(" ");
    const typologyColor = typologyKey[typologyDevicesList[0]]?.color;

    return (
      <>
        <div className={styles.productGroupStateIcon}>
          <Icon
            className={styles.certificateIcon}
            style={{ color: typologyColor }}
            icon={faCertificate}
            color="secondary"
            size="S"
          />
          <Icon className={styles.checkIcon} icon={faCheck} color="secondary" size="S" />
        </div>
        <p
          className={styles.productGroupStateWording}
          style={typologyDevicesListWithoutDuplicate.length > 1 ? { color: "red" } : null}
        >
          {" "}
          {typologyNicenameListJoined}
        </p>
      </>
    );
  };

  return (
    <div className={styles.productGroupTable}>
      <div className={styles.productGroupTableHeader}>
        <div className={styles.matchingProducts} onClick={() => props.handleGroupOpen(props.product_group.id)}>
          <p className={styles.matchingProductsNumber}>{props.product_group.products.length}</p>
          <p className={styles.matchingProductsWording}>{getAvailableProductWording()}</p>
        </div>

        <div
          className={styles.productGroupPictureContainer}
          onClick={() => props.handleGroupOpen(props.product_group.id)}
        >
          <img
            className={styles.productGroupPicture}
            src={props.product_group.img_url}
            alt={props.product_group.name}
          />
        </div>

        <div
          className={styles.productGroupNameAndDescription}
          onClick={() => props.handleGroupOpen(props.product_group.id)}
        >
          <p className={styles.productGroupName}>{props.product_group.name}</p>
          <p className={styles.productGroupDescription}>{props.product_group.description}</p>
        </div>

        {props.product_group.products.length > 0 && (
          <>
            <div className={styles.productGroupState} onClick={() => props.handleGroupOpen(props.product_group.id)}>
              {getTypology()}
            </div>

            <div className={styles.productGroupPriceBox} onClick={() => props.handleGroupOpen(props.product_group.id)}>
              <p className={styles.productGroupPrice}>{getLowerPrice()}</p>

              <p className={styles.productGroupPriceText}>/months (without tax)</p>
            </div>
          </>
        )}

        {!props.product_group.products.length && (
          <>
            <div className={styles.productGroupState} onClick={() => props.handleGroupOpen(props.product_group.id)} />
            <div
              className={styles.productGroupPriceBox}
              onClick={() => props.handleGroupOpen(props.product_group.id)}
            />
          </>
        )}
      </div>

      <div style={!props.isOpen ? { display: "none" } : null} className={styles.productsTable}>
        <div className={styles.productsTableHeader}>
          <div>Price</div>
          {productSpecs
            .filter(
              (spec) => spec.categories.includes(props.product_group.category) && spec.applyTo.includes("PRODUCT"),
            )
            .map((spec) => (
              <div>{spec.label}</div>
            ))}
          <div>Delivery Range</div>
          <div />
        </div>

        <hr />
        {props.product_group.products.map((p) => (
          <AddDeviceProductRow
            order={props.order}
            reloadOrder={props.reloadOrder}
            product={p}
            key={p.id}
            all_product_groups={props.all_product_groups}
            getProductGroups={props.getProductGroups}
            product_group={props.product_group}
            openModal={props.openModal}
          />
        ))}

        {!props.product_group.products.length && (
          <div className={styles.noProduct}>
            This Product Group has no Product.
            <span>Click here to add one. </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDeviceProductGroupTable;
