import { Button, Checkbox, Drawer, Layout, Select, Text, TextField, useTheme } from "@fleet.co/tarmac";
import { useContext, useEffect, useState } from "react";
import { COUNTRIES_LIST, CURRENCIES_LIST } from "src/common/i18n-consts";
import UserContext from "../../tools/UserContext";

import styles from "../tables/productGroups/ProductGroupModal.module.scss";
import { useToastContext } from "../../contexts/ToastContext";
import { brands, categories, productSpecs } from "../../data/productSpecs";

const initialProductGroup = {
  name: "",
  brand: "",
  category: "",
};

const initialProduct = {
  product_group_id: "",
  name: "",
  img_url: "",
  visibility: "DRAFT",
  available_countries: [],
  compliance: [],
  connection_type: [],
  screen_stand: [],
  weight: "",
  length: "",
  width: "",
  height: "",
  co2_saved: "",
  usage: "BASIC",
  min_shipping: "",
  max_shipping: "",
  prices: [],
  primarySku: {
    sku_code: "",
  },
  // Take every spec from productSpecs and create a key with the spec name and an empty value
  ...productSpecs
    .filter((spec) => spec.applyTo.includes("PRODUCT"))
    .reduce((acc, spec) => ({ ...acc, [spec.name]: spec.multiple ? [] : "" }), {}),
};

const exchangeRates = {
  EUR: 1,
  USD: 1.04,
  GBP: 0.83,
};

const NewReferenceModal = ({ isOpen, handleClose, order }) => {
  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();
  const defaultAddress = order.company.addresses.find((a) => a.is_default);

  const brandsList = brands.map((b) => b.slug);

  const [productGroup, setProductGroup] = useState(initialProductGroup);
  const [product, setProduct] = useState(() => ({
    ...initialProduct,
    available_countries: [...COUNTRIES_LIST],
  }));

  const [isFormValid, setIsFormValid] = useState(false);
  const [currencyPrices, setCurrencyPrices] = useState({
    EUR: "",
    USD: "",
    GBP: "",
  });

  const ERROR_MESSAGES = {
    NAME_ALREADY_EXISTING: "The name already exists, choose an another one.",
  };

  const theme = useTheme();

  const formPgValidated = () => productGroup.name && productGroup.brand && productGroup.category;

  const formProductValidated = () => {
    const isProductGroupValid = formPgValidated();

    const isProductValid = productSpecs
      .filter(
        (spec) =>
          (spec.categories.includes(productGroup.category) &&
            spec.specSection === "characteristics" &&
            !spec.multiple &&
            spec.applyTo.includes("PRODUCT")) ||
          (spec.name === "keyboard_layout" && spec.categories.includes(productGroup.category)),
      )
      .every((spec) => {
        if (spec.multiple) {
          return product[spec.name]?.length > 0;
        }
        return product[spec.name] !== "";
      });

    const isWeightValid = product.weight;
    const isPriceValid = Object.values(currencyPrices).every((price) => price);

    return isProductGroupValid && isProductValid && isWeightValid && isPriceValid;
  };

  useEffect(() => {
    setIsFormValid(formProductValidated());
  }, [productGroup, product]);

  const reinitializeModal = () => {
    setProductGroup(initialProductGroup);
    setProduct(initialProduct);
    setCurrencyPrices({
      EUR: "",
      USD: "",
      GBP: "",
    });
    setIsFormValid(false);
  };

  const handleChangeProductGroup = (e) => {
    const { name, value } = e.target;

    if (name === "available_countries") {
      const optionsSelected = [...productGroup.available_countries];
      const optionIndex = optionsSelected.findIndex((v) => v === value);

      if (optionIndex >= 0) {
        optionsSelected.splice(optionIndex, 1);
      } else {
        optionsSelected.push(value);
      }

      setProductGroup({ ...productGroup, [name]: optionsSelected });
    } else {
      setProductGroup((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  const handleChangeProduct = (e) => {
    const { name, value } = e.target;

    if (name === "weight") {
      setProduct((prevInfo) => ({ ...prevInfo, [name]: value.replace(/[.,]/g, "") }));
    } else {
      setProduct((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  const onSelectOption = (field, value) => {
    if (field === "category") {
      setProduct({ ...initialProduct });
      if (productGroup.category) {
        setProductGroup({ ...initialProductGroup, [field]: value });
      }
      setCurrencyPrices({
        EUR: "",
        USD: "",
        GBP: "",
      });
    }
    setProductGroup((pg) => ({ ...pg, [field]: value }));
  };

  const brandOptions = [{ label: "-", value: "" }, ...brandsList.map((b) => ({ label: b.toUpperCase(), value: b }))];

  const categoryOptions = [
    { label: "-", value: "" },
    ...categories.map((c) => {
      if (c === "SCREEN") {
        return { label: "SCREEN & TV", value: c };
      }

      return { label: c, value: c };
    }),
  ];

  let pgId = "";
  const createProduct = async () => {
    try {
      const pg = await adminUser.api.addProductGroupNewReference(productGroup);
      pgId = pg.data.id;
      product.product_group_id = pg.data.id;
      product.name = pg.data.name;
      const newProduct = await adminUser.api.addProduct(product);

      const newDevice = {
        product_id: newProduct.data.id,
        shipping_address_id: defaultAddress?.id,
        keyboard_layout: newProduct.data.keyboard_layout,
        newDeviceName: newProduct.data.name,
        newReference: true,
      };

      await adminUser.api.addDeviceToOrder(order.company.id, order.id, newDevice);

      addToast("New Product Group and Product added successfully.", "success");
      handleClose();
      reinitializeModal();
    } catch (err) {
      try {
        if (!ERROR_MESSAGES[err.response.data.message]) {
          await adminUser.api.deleteProductGroup(pgId);
        }
        addToast(err.response?.data?.message || "Couldn't proceed.");
      } catch (err) {
        console.error(err);
      }
      console.error(err);
    }
  };

  const handleSelectSpec = (spec, value) => {
    setProduct((prevInfo) => ({ ...prevInfo, [spec]: value }));
  };

  const handlePriceChange = (value, currency) => {
    if (value === "") {
      setCurrencyPrices({
        EUR: "",
        USD: "",
        GBP: "",
      });
      setProduct((prevProduct) => ({
        ...prevProduct,
        prices: [],
      }));
      return;
    }

    const numericValue = Number(value);
    const basePrice = numericValue / exchangeRates[currency];

    const updatedPrices = {
      EUR: Number((basePrice * exchangeRates.EUR).toFixed(2)),
      USD: Number((basePrice * exchangeRates.USD).toFixed(2)),
      GBP: Number((basePrice * exchangeRates.GBP).toFixed(2)),
    };

    setCurrencyPrices(updatedPrices);

    const formattedPrices = Object.entries(updatedPrices).map(([currency, amount]) => ({
      currency,
      amount: amount.toString(),
    }));

    setProduct((prevProduct) => ({
      ...prevProduct,
      prices: formattedPrices,
    }));
  };

  const DrawerActions = (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          handleClose();
          reinitializeModal();
        }}
        color="secondary"
        label="Close"
        fullWidth
      />
      <Button
        variant="contained"
        onClick={createProduct}
        color="primary"
        label="Save"
        fullWidth
        disabled={!isFormValid}
      />
    </>
  );

  return (
    <Drawer
      title="Add a new product reference"
      open={isOpen}
      onClose={() => {
        handleClose();
        reinitializeModal();
      }}
      Actions={DrawerActions}
    >
      <Layout direction="column" spacing={3}>
        <Layout direction="row" spacing={1} alignItems="center">
          <Layout
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              width: "30px",
              height: "30px",
              borderRadius: "100px",
              border: "3px solid darkGreen",
            }}
          >
            <Text variant="h5" color="darkGreen" bold>
              1
            </Text>
          </Layout>
          <Text variant="body1">Create a Product Group </Text>
          <Text variant="body1" color="secondary">
            - Specify characteristics
          </Text>
        </Layout>
        <div className={styles.selectPair}>
          <Select
            label="Brand"
            required
            value={productGroup.brand || ""}
            options={brandOptions}
            onChange={(value) => onSelectOption("brand", value)}
          />

          <Select
            label="Category"
            required
            value={productGroup.category}
            options={categoryOptions}
            onChange={(value) => onSelectOption("category", value)}
          />
        </div>

        <TextField
          label="Name"
          name="name"
          value={productGroup.name}
          onChange={handleChangeProductGroup}
          placeholder='ex : Microsoft Surface Book 3 13.5""'
          required
        />
        <Layout direction="row" spacing={1} alignItems="center">
          <Layout
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              width: "30px",
              height: "30px",
              borderRadius: "100px",
              border: "3px solid ",
              borderColor: formPgValidated() ? theme.palette.green[950] : theme.palette.grey[300],
            }}
          >
            <Text variant="h5" color={!formPgValidated() ? "disabled" : "darkGreen"} bold>
              2
            </Text>
          </Layout>
          <Text variant="body1" color={!formPgValidated() && "disabled"}>
            Create a Product{" "}
          </Text>
          <Text variant="body1" color={!formPgValidated() ? "disabled" : "secondary"}>
            - Specify characteristics and prices
          </Text>
        </Layout>

        <Layout direction="column" spacing={2}>
          <Layout direction="row" fullWidth flexWrap="wrap" spacing={2}>
            {formPgValidated() &&
              productSpecs
                .filter(
                  (spec) =>
                    (spec.categories.includes(productGroup.category) &&
                      spec.specSection === "characteristics" &&
                      spec.applyTo.includes("PRODUCT")) ||
                    (spec.name === "keyboard_layout" && spec.categories.includes(productGroup.category)),
                )
                .map((spec) => {
                  const specOptions = [...spec.options(productGroup.category)];

                  return (
                    <Layout direction="column" flexGrow={1} flexBasis="33%" key={spec.name}>
                      {!spec.multiple ? (
                        <Select
                          label={`${spec.label} *`}
                          options={specOptions}
                          onChange={(option) => handleSelectSpec(spec.name, option)}
                          value={product[spec.name] ?? ""}
                        />
                      ) : (
                        <>
                          <Text variant="body2">{spec.label}</Text>
                          {spec.options(productGroup.category).map((o) => (
                            <Checkbox
                              key={o.value}
                              label={o.label}
                              name={spec.name}
                              checked={product[spec.name]?.includes(o.value) || false}
                              onChange={() => {
                                const currentValues = product[spec.name] || [];
                                const newValues = currentValues.includes(o.value)
                                  ? currentValues.filter((v) => v !== o.value)
                                  : [...currentValues, o.value];

                                handleChangeProduct({ target: { name: spec.name, value: newValues } });
                              }}
                            />
                          ))}
                        </>
                      )}
                    </Layout>
                  );
                })}
          </Layout>

          {formPgValidated() && (
            <Layout direction="row" spacing={2}>
              <TextField
                label="Weight (g)"
                required
                name="weight"
                type="number"
                value={product.weight}
                onChange={handleChangeProduct}
              />
            </Layout>
          )}
        </Layout>

        {formPgValidated() && (
          <Layout direction="row" spacing={2}>
            {CURRENCIES_LIST.map((currency) => (
              <TextField
                key={currency}
                label={currency}
                required
                name={`${currency.toLowerCase()}_price`}
                type="number"
                value={currencyPrices[currency]}
                onChange={(e) => handlePriceChange(e.target.value, currency)}
              />
            ))}
          </Layout>
        )}
      </Layout>
    </Drawer>
  );
};

export default NewReferenceModal;
