import { Layout, Text } from "@fleet.co/tarmac";
import React from "react";

const LeaserScoring = ({ scoring, order }) => {
  if (scoring === undefined) return "";

  const hasScoring = scoring.total || scoring.total === 0;

  const criteria = [
    { value: "BASE_SCORE", label: "Leaser Priority" },
    { value: "WAS_LAST_ORDER", label: "Client already ordered with Leaser" },
    { value: "HAS_ENVELOPE", label: "Leaser has valid envelope" },
    { value: "YOUNG_CLIENT", label: "Leaser Priority" },
    { value: "SMALL_ORDER", label: `Company less than 3 years old (${order.company.creation_year})` },
    { value: "WEIGHT_BALANCING", label: "Leaser not at objective" },
    { value: "ELIGIBLE APPLE_FINANCIAL_SERVICE", label: "Eligible to Apple Financial Service" },
  ];

  return (
    hasScoring && (
      <Layout direction="column" spacing={1}>
        <Text variant="body1">Total Score: {scoring.total}</Text>
        <Layout direction="column" spacing={0.5}>
          {criteria.map((c) => {
            const orderCriterion = scoring.criteria.find((cr) => cr.name === c.value);

            if (!orderCriterion) return "";

            return (
              <Text variant="body2" key={c.value}>
                {c.label} : {orderCriterion.value}
              </Text>
            );
          })}
        </Layout>
      </Layout>
    )
  );
};

export default LeaserScoring;
