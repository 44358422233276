import React, { useMemo } from "react";
import { Checkbox, Layout } from "@fleet.co/tarmac";
import { useLogistics } from "../../../../contexts/LogisticsContext";

const MissingSerialFilter = () => {
  const { filters, handleFilterChange } = useLogistics();
  const isActive = useMemo(
    () => filters.some((filter) => filter.field === "only_missing_serial_number" && filter.value),
    [filters],
  );

  const handleChange = () => {
    handleFilterChange("only_missing_serial_number", !isActive);
  };

  return (
    <Layout sx={{ padding: 0.5 }}>
      <Checkbox label="Only show devices without S/N" checked={isActive} onChange={handleChange} />
    </Layout>
  );
};

export default MissingSerialFilter;
