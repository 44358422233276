import { Button, DataGrid, Drawer, Heading, Icon, IconButton, Layout, Text, TextField } from "@fleet.co/tarmac";
import { faChevronDown, faChevronUp, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { queryOptions, useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense, useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import ApiService from "src/tools/ApiService";
import UserContext from "src/tools/UserContext";
import "./CopilotPrompts.style.css";

export const authorizedCopilotPromptUsersEmails = [
  "armelle.casanova@fleet.co",
  "guy.houot@fleet.co",
  "robin.marillia@fleet.co",
  "geoffroy.danest@fleet.co",
  "developer@fleet.co",
];

type CopilotPrompt = {
  id: number;
  prompt: string;
  likes: number;
  dislikes: number;
  status: "active" | "inactive" | "deleted";
  created_at: string;
  updated_at: string;
};

export const copilotQueries = {
  list: () =>
    queryOptions({
      queryKey: ["copilotPrompts"],
      queryFn: async () => {
        const apiService = ApiService.getInstance();
        const prompts = await apiService.getCopilotPrompts();
        return prompts.data as CopilotPrompt[];
      },
      staleTime: 5000,
    }),
};

const useCreateCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: Partial<CopilotPrompt>) => {
      const apiService = ApiService.getInstance();
      await apiService.createCopilotPrompt(payload);
    },
    mutationKey: copilotQueries.list().queryKey,
  });
};

const useUpdateCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: Partial<CopilotPrompt>) => {
      const apiService = ApiService.getInstance();
      await apiService.updateCopilotPrompt(payload.id, payload);
    },
  });
};

const useDeleteCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: { id: number }) => {
      const apiService = ApiService.getInstance();
      await apiService.deleteCopilotPrompt(payload.id);
    },
  });
};

const useSelectCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: { id: number }) => {
      const apiService = ApiService.getInstance();
      await apiService.selectCopilotPrompt(payload.id);
    },
  });
};

function CopilotPromptEditModal({
  open,
  onClose,
  initialValue,
  onSubmit,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  initialValue?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onDelete: () => void;
}) {
  const Actions = (
    <Layout direction="row" spacing={1} flex={1}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        label="Update"
        type="submit"
        form="copilot-prompt-form"
        sx={{ flex: 1 }}
      />
      <Button variant="contained" color="error" size="small" label="Delete" onClick={onDelete} sx={{ flex: 1 }} />
    </Layout>
  );

  return (
    <Drawer title="Edit prompt" onClose={onClose} open={open} Actions={Actions}>
      <Layout component="form" direction="row" spacing={1} onSubmit={onSubmit} flex={1} id="copilot-prompt-form">
        <TextField
          key={initialValue}
          label="Prompt"
          multiline
          name="prompt"
          sx={{ flex: 1 }}
          defaultValue={initialValue}
          autoFocus
        />
      </Layout>
    </Drawer>
  );
}

function CopilotPromptForm({
  initialValue,
  onSubmit,
}: {
  initialValue?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <Layout component="form" direction="row" spacing={1} onSubmit={onSubmit} flex={1}>
      <TextField label="Prompt" multiline name="prompt" sx={{ flex: 1 }} defaultValue={initialValue} />
      <Button
        variant="contained"
        color="primary"
        size="small"
        label={initialValue ? "Update Prompt" : "Add Prompt"}
        type="submit"
      />
    </Layout>
  );
}

function CopilotPrompt({ prompt }: { prompt: CopilotPrompt }) {
  const [collapsed, setCollapsed] = useState(true);
  const formattedPrompt = prompt.prompt.replace(/\n/g, "  \n");

  return (
    <Layout
      sx={{
        position: "relative",
        paddingBottom: collapsed ? 0 : "2rem",
        minHeight: "3rem",
      }}
    >
      <div className={`markdown-body ${collapsed ? "collapsed" : ""}`}>
        <ReactMarkdown>{formattedPrompt}</ReactMarkdown>
      </div>
      <Layout direction="row" alignItems="center">
        <IconButton
          onClick={() => setCollapsed(!collapsed)}
          icon={<Icon icon={collapsed ? faChevronDown : faChevronUp} />}
        />
      </Layout>
    </Layout>
  );
}

export function CopilotPromptsContent() {
  const [editingId, setEditingId] = useState<number | null>(null);
  const { user: adminUser } = useContext(UserContext);

  const { data: prompts } = useSuspenseQuery(copilotQueries.list());
  const { mutate: createCopilotPrompt } = useCreateCopilotPromptMutation();
  const { mutate: updateCopilotPrompt } = useUpdateCopilotPromptMutation();
  const { mutate: selectCopilotPrompt } = useSelectCopilotPromptMutation();
  const { mutate: deleteCopilotPrompt } = useDeleteCopilotPromptMutation();

  if (!authorizedCopilotPromptUsersEmails.includes(adminUser?.email)) {
    return <div>You are not authorized to access this page</div>;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const prompt = formData.get("prompt") as string;
    if (editingId) {
      updateCopilotPrompt({ id: editingId, prompt });
      setEditingId(null);
    } else {
      createCopilotPrompt({ prompt });
    }
  };

  const handleDelete = (promptId: number | null) => {
    if (promptId) {
      deleteCopilotPrompt({ id: promptId });
      setEditingId(null);
    }
  };

  const columns = [
    {
      field: "prompt",
      headerName: "Prompt",
      flex: 1,
      minWidth: 700,
      renderCell: (params: { row: CopilotPrompt }) => {
        return <CopilotPrompt prompt={params.row} />;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      renderCell: (params: { row: CopilotPrompt }) => (
        <Layout direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          {params.row.status === "active" ? (
            <Text bold>Current</Text>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              label="Select as current"
              onClick={() => selectCopilotPrompt({ id: params.row.id })}
            />
          )}
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            label="Edit"
            onClick={() => setEditingId(params.row.id)}
            startIcon={faEdit}
          />
        </Layout>
      ),
    },
  ];

  return (
    <Layout direction="column" spacing={4} paddingX={8}>
      <Heading variant="h2">Copilot prompts</Heading>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Add a new prompt
        </Text>
        <CopilotPromptForm initialValue="" onSubmit={handleSubmit} />
      </Layout>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Variables
        </Text>
        <Text variant="body1">You can use the following variables in your prompts:</Text>
        <DataGrid
          rows={[{ id: 1, name: "{{company.name}}", description: "The name of the company" }]}
          columns={[
            { field: "name", headerName: "Variable" },
            { field: "description", headerName: "Description" },
          ]}
        />
      </Layout>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Prompts
        </Text>
        <DataGrid rows={prompts} columns={columns} getRowHeight={() => "auto"} />
      </Layout>
      <CopilotPromptEditModal
        open={editingId !== null}
        onClose={() => setEditingId(null)}
        onDelete={() => handleDelete(editingId)}
        initialValue={editingId ? prompts.find((p) => p.id === editingId)?.prompt : undefined}
        onSubmit={handleSubmit}
      />
    </Layout>
  );
}

export function CopilotPrompts() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CopilotPromptsContent />
    </Suspense>
  );
}
