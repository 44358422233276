import React from "react";
import { Link } from "react-router";

import { Button, Heading, Layout } from "@fleet.co/tarmac";

const NoRoute = () => (
  <Layout
    direction="row"
    className="main"
    style={{
      height: " 100%",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 0 0 5em",
      textAlign: "center",
    }}
  >
    <Layout direction="column" className="flex-center-col" spacing={4}>
      <Heading variant="h1">
        La page que vous recherchez
        <br /> n’a pas été trouvée
      </Heading>
      <Link to="/">
        <Button variant="contained" color="primary">
          Retour à l'accueil
        </Button>
      </Link>
    </Layout>
    <figure
      className="image"
      style={{
        maxWidth: "450px",
        transform: "scaleX(-1)",
      }}
    >
      <img loading="lazy" src="/images/noroute.png" alt="Contactez-nous" />
    </figure>
  </Layout>
);

export default NoRoute;
