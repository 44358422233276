import React from "react";
import type { Company } from "src/types/Company.type";

type CompanyContextType = {
  company: Company | null;
  saveCompany: (payload: Partial<Company>) => Promise<void>;
};

const CompanyContext = React.createContext<CompanyContextType>({
  company: null,
  saveCompany: () => Promise.resolve(),
});

export default CompanyContext;
