// User
import ApiService from "./ApiService";

class User {
  load = () => {
    this.api = ApiService.getInstance();

    return this;
  };

  // Add helpers here
  hasRole = (role) => this.roles && this.roles.filter((r) => r.role === role).length;
}

export default User;
