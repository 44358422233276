import React, { useContext, useEffect, useState } from "react";
import { COUNTRY_CURRENCY_MAPPING } from "src/common/i18n-consts";
import UserContext from "../../../tools/UserContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import EnvelopeRow from "../../tables/envelopes/EnvelopeRow";
import EnvelopeModal from "../../blocks/EnvelopeModal";
import { formatMoney } from "../../../helpers/formatHelpers";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyFundingInfo = (props) => {
  const { modalOpened, setModalOpened } = props;
  const { user: adminUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [allEnvelopes, setAllEnvelopes] = useState([]);
  const [numOfAcceptedEnvelopes, setNumOfAcceptedEnvelopes] = useState(0);
  const [amountOfAvailableEnvelopes, setAmountOfAvailableEnvelopes] = useState(0);
  const [specificEnvelope, setSpecificEnvelope] = useState(null);

  const statuses = [
    { value: "FUNDING_REQUESTED", label: "FUNDING REQUESTED" },
    { value: "FUNDING_MORE_DOCUMENTS", label: "FUNDING MORE DOCUMENTS" },
    { value: "TODO", label: "TODO" },
    { value: "CLOSED", label: "CLOSED" },
    { value: "ACCEPTED", label: "ACCEPTED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  const getAllEnvelopes = async () => {
    setIsLoading(true);

    const fetchedEnvelopes = await adminUser.api.getEnvelopes();

    const allEnvelopesFromCompany = fetchedEnvelopes.data.filter((e) => e.company_id === company.id);
    const status_order = {
      ACCEPTED: 0,
      TODO: 1,
      FUNDING_MORE_DOCUMENTS: 2,
      FUNDING_REQUESTED: 3,
      REJECTED: 4,
      CLOSED: 5,
    };

    const sortedEnvelopes = allEnvelopesFromCompany.sort((a, b) => status_order[a.status] - status_order[b.status]);
    let allAcceptedEnvelopesFromCompany;
    let totalOfAcceptedEnvelopes;

    setAllEnvelopes(sortedEnvelopes);

    if (allEnvelopesFromCompany.length > 0) {
      allAcceptedEnvelopesFromCompany = allEnvelopesFromCompany.filter((e) => e.status === "ACCEPTED");

      if (allAcceptedEnvelopesFromCompany.length !== 0) {
        setNumOfAcceptedEnvelopes(allAcceptedEnvelopesFromCompany.length);
        totalOfAcceptedEnvelopes =
          allAcceptedEnvelopesFromCompany.length > 1
            ? allAcceptedEnvelopesFromCompany.reduce((a, b) => a + parseFloat(b.current_amount), 0)
            : allAcceptedEnvelopesFromCompany[0].current_amount;
        setAmountOfAvailableEnvelopes(totalOfAcceptedEnvelopes);
      }
    }

    setIsLoading(false);
  };

  const openEditEnvelope = (envelope) => {
    setModalOpened({ type: "EDIT_FUNDING" });
    setSpecificEnvelope(envelope);
  };

  const closeEnvelopeModal = () => {
    setModalOpened({ type: "" });
    setSpecificEnvelope(null);
    getAllEnvelopes();
  };

  useEffect(() => {
    getAllEnvelopes();
  }, [company.id]);

  const companyCurrency = COUNTRY_CURRENCY_MAPPING[company?.country];

  if (isLoading) {
    return <LoadingSpinner isFullScreen={false} />;
  }

  return (
    <div>
      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "10%", textAlign: "center" }}>Status</td>
            <td style={{ width: "10%", textAlign: "center" }}>Leasers</td>
            <td style={{ width: "10%", textAlign: "center" }}>Amount left</td>
            <td style={{ width: "10%", textAlign: "center" }}>Amount spent</td>
            <td style={{ width: "10%", textAlign: "center" }}>Amount granted</td>
            <td style={{ width: "10%", textAlign: "center" }}>Time left</td>
            <td style={{ width: "10%", textAlign: "center" }}>Request ID</td>
            <td style={{ width: "11%", textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
        <tbody />
      </table>
      {allEnvelopes.map((envelope, index) => (
        <EnvelopeRow
          envelope={envelope}
          reloadEnvelopes={getAllEnvelopes}
          key={index}
          index={index}
          openEditEnvelope={openEditEnvelope}
          fromCompanyView
        />
      ))}
      <p style={{ margin: "1em 10px" }}>
        {numOfAcceptedEnvelopes} funding
        {formatMoney(amountOfAvailableEnvelopes, companyCurrency)}
      </p>

      <EnvelopeModal
        statuses={statuses}
        open={modalOpened.type === "ADD_NEW_FUNDING" || modalOpened.type === "EDIT_FUNDING"}
        closeAction={closeEnvelopeModal}
        companyId={company.id}
        envelope={specificEnvelope}
      />
    </div>
  );
};

export default CompanyFundingInfo;
