import React, { useCallback, useState } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { Checkbox, Icon, IconButton, Layout, Tag, Text } from "@fleet.co/tarmac";
import ContractDecisionDevicesRow from "./ContractDecisionDevicesRow";
import styles from "./ContractEndingCSM.module.scss";

const ContractDecisionRow = (props) => {
  const { order, isMassSelectEnabled, selectForMassEdit, isSelected, reloadOrders } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const startDate = new Date(order.contract_start_date).toLocaleDateString("fr");
  const endDate = new Date(order.contract_end_date).toLocaleDateString("fr");

  const getMonthsLeft = useCallback(() => {
    const monthsDiff = (d1, d2) => {
      const years = d2.getFullYear() - d1.getFullYear();
      const months = years * 12 + (d2.getMonth() - d1.getMonth());

      return months;
    };
    const today = new Date();
    const monthsPast = monthsDiff(new Date(order.contract_start_date), today);

    return order.contract_duration - monthsPast;
  }, [order.contract_start_date, order.contract_duration]);

  const getStatusName = useCallback(() => {
    if (order.renewal_decision) {
      return <Tag variant="filled" label="Done" backgroundColor="green" />;
    }

    if (getMonthsLeft() < 0) {
      return <Tag variant="filled" label="Extended" backgroundColor="red" />;
    }

    return <Tag variant="filled" label="Expire soon" backgroundColor="yellow" />;
  }, [getMonthsLeft, order.renewal_decision]);

  return (
    order && (
      <Layout
        className={`${styles.container} ${isMenuOpen ? styles.containerOpen : null}`}
        onClick={selectForMassEdit}
        key={order.id}
      >
        <Layout direction="row" fullWidth className={styles.contractRow}>
          <div className={styles.littleCell}>
            {isMassSelectEnabled && <Checkbox checked={isSelected} onChange={selectForMassEdit} />}
          </div>
          <div>{order.contract_number || order.request_number}</div>
          <div>{startDate}</div>
          <div>{endDate}</div>
          <div>
            {getMonthsLeft() <= 0 && (
              <Text variant="body1" color="error">
                {" "}
                {getMonthsLeft()} months
              </Text>
            )}
            {getMonthsLeft() > 0 && <Text variant="body1"> {getMonthsLeft()} months</Text>}
          </div>
          <div>{order.company.name}</div>
          <div>{getStatusName()}</div>
          <div>
            {order.renewal_decision_date ? new Date(order.renewal_decision_date).toLocaleDateString("fr") : "-"}
          </div>
          <div>
            <IconButton
              variant="contained"
              color={isMenuOpen ? "primary" : "secondary"}
              icon={<Icon icon={isMenuOpen ? faCaretUp : faCaretDown} color="secondary" size="S" />}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </div>
        </Layout>

        {isMenuOpen && (
          <Layout direction="column">
            <Layout direction="row" fullWidth className={styles.tableHeadDevices}>
              <div>Device</div>
              <div>Year</div>
              <div>Serial number</div>
              <div>Rent</div>
              <div>Payment status</div>
              <div>Buyback offer HT</div>
              <div>Client decision</div>
            </Layout>
            {order.devices.map((device) => (
              <ContractDecisionDevicesRow device={device} reloadOrders={reloadOrders} order={order} />
            ))}
          </Layout>
        )}
      </Layout>
    )
  );
};

export default ContractDecisionRow;
