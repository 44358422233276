import React from "react";
import { Button, Dialog } from "@fleet.co/tarmac";

const RenewalReminderConfirmation = (props) => {
  const { closeModal, confirmAction, numberOfMail, isLoading, open } = props;

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={closeModal} />
      <Button color="primary" label={isLoading ? "Sending..." : "Send"} onClick={confirmAction} />
    </>
  );

  return (
    <Dialog title="Reminder confirmation" onClose={closeModal} Actions={ActionComponent} open={open}>
      You are about to send <strong>{numberOfMail} </strong> reminders. One for each selected order. Do you confirm ?
    </Dialog>
  );
};

export default RenewalReminderConfirmation;
