import React, { useMemo } from "react";
import { Button, DataGrid } from "@fleet.co/tarmac";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useLogistics } from "../../../contexts/LogisticsContext";
import DeviceInfoCell from "./cells/DeviceInfoCell";
import AddressCell from "./cells/AddressCell";
import CompanyNameCell from "./cells/CompanyNameCell";
import KeyboardLayoutCell from "./cells/KeyboardLayoutCell";
import ContactCell from "./cells/ContactCell";
import ProviderCell from "./cells/ProviderCell";
import DeliveryDateCell from "./cells/DeliveryDateCell";
import TrackingCell from "./cells/TrackingCell";
import SerialNumberCell from "./cells/SerialNumberCell";
import FormatDate from "../../../tools/FormatDate";
import EditDeviceModal from "../../blocks/EditDeviceModal";
import MassEditModal from "../../blocks/MassEditModal";
import MissingSerialFilter from "./customFilters/MissingSerialFilter";
import StatusCell from "./cells/StatusCell";
import { deviceStatus } from "../../../data/deviceStatus";

const LogisticsTable = () => {
  const {
    devices,
    fetchDevices,
    loading,
    openEditDeviceModal,
    setMassEditDevices,
    selectedDevice,
    isEditDeviceModalOpen,
    closeEditDeviceModal,
    massEditDevices,
    isMassEditModalOpen,
    setIsMassEditModalOpen,
    filters,
    handleFilerModelChange,
  } = useLogistics();

  const columns = useMemo(
    () => [
      {
        field: "company_name",
        headerName: "Company",
        actionType: "sort",
        valueGetter: ({ row: device }) => device.order.company.name,
        renderCell: CompanyNameCell,
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        actionType: "multiSelect",
        valueOptions: deviceStatus.map((status) => status.value),
        valueGetter: ({ row: device }) => device.status,
        renderCell: StatusCell,
        width: 170,
      },
      {
        field: "deviceInfo",
        headerName: "Device",
        actionType: "sort",
        renderCell: DeviceInfoCell,
        valueGetter: ({ row: device }) => device.name,
        flex: 1,
      },
      {
        field: "serialNumber",
        sortable: false,
        headerName: "S/N",
        actionType: "custom",
        CustomFilterComponent: MissingSerialFilter,
        valueGetter: ({ row }) => row.serial_number,
        renderCell: SerialNumberCell,
        width: 150,
      },
      {
        field: "keyboard_layout",
        headerName: "Keyboard",
        align: "center",
        renderCell: KeyboardLayoutCell,
        flex: 1,
      },
      {
        field: "formattedAddress",
        headerName: "Address",
        renderCell: AddressCell,
        actionType: "sort",
        valueGetter: ({ row: device }) => device.order.address,
        flex: 1,
      },
      {
        field: "formattedContact",
        headerName: "Contact",
        renderCell: ContactCell,
        flex: 1,
      },
      {
        field: "promised_delivery_date",
        headerName: "Promised ETA",
        valueGetter: ({ row: device }) => new Date(device.promised_delivery_date).toLocaleDateString(),
        renderCell: ({ row: device }) => <FormatDate date={device.promised_delivery_date} />,
        flex: 1,
        actionType: "sort",
      },
      {
        field: "delivery_date",
        headerName: "Delivery",
        valueGetter: ({ row: device }) => device.delivery_date || device.expected_delivery_date,
        renderCell: DeliveryDateCell,
        flex: 1,
        actionType: "sort",
      },
      {
        field: "provider",
        headerName: "Provider",
        renderCell: ProviderCell,
        flex: 1,
        valueGetter: ({ row: device }) => device.provider,
        actionType: "sort",
      },
      {
        field: "tracking_number",
        headerName: "Tracking",
        renderCell: TrackingCell,
        flex: 1,
        valueGetter: ({ row: device }) => device.tracking_number,
        actionType: "sort",
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: ({ row: device }) => (
          <Button
            variant="outlined"
            color="secondary"
            label="Edit"
            startIcon={faEdit}
            size="small"
            onClick={() => openEditDeviceModal(device)}
          />
        ),
        flex: 1,
      },
    ],
    [openEditDeviceModal],
  );

  return (
    <>
      <DataGrid
        columns={columns}
        loading={loading}
        rows={devices}
        filterMode="server"
        onSelectRow={(devices) => setMassEditDevices(devices)}
        onFilterModelChange={handleFilerModelChange}
        rowHeight={100}
        checkboxSelection={!isEditDeviceModalOpen}
        rowSelectionModel={selectedDevice ? [selectedDevice?.id] : undefined}
        pagination
        filterModel={{ items: filters }}
        pageSize={50}
        initialState={{
          filters: {
            items: [...filters],
          },
        }}
      />
      <EditDeviceModal
        key={selectedDevice?.id}
        open={isEditDeviceModalOpen}
        reloadPage={fetchDevices}
        device={selectedDevice}
        onClose={closeEditDeviceModal}
      />
      <MassEditModal
        open={isMassEditModalOpen}
        reloadPage={fetchDevices}
        devices={massEditDevices}
        onClose={() => setIsMassEditModalOpen(false)}
      />
    </>
  );
};

export default LogisticsTable;
