import { useCallback, useContext, useState } from "react";
import qs from "qs";
import { SearchBar as FleetSearchBar } from "@fleet.co/tarmac";

import { useNavigate } from "react-router";
import { useSearchContext } from "../../contexts/SearchContext";
import UserContext from "../../tools/UserContext";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";

import UserResult from "./UserResult";
import CompanyResult from "./CompanyResult";
import DeviceResult from "./DeviceResult";
import OrderResult from "./OrderResult";

const concatObjectKeysIntoArray = (object) => {
  const concatenatedArray = [];

  for (const key in object) {
    if (Array.isArray(object[key])) {
      concatenatedArray.push(
        ...object[key].map((item) => ({ ...item, resultType: key.charAt(0).toUpperCase() + key.slice(1) })),
      );
    }
  }

  return concatenatedArray;
};

const SearchBar = () => {
  const { user: adminUser } = useContext(UserContext);
  const { results, setResults, addToSearchHistory } = useSearchContext();
  const [queryString, setQueryString] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchResults = async () => {
    if (queryString.length > 2) {
      setLoading(true);
      const queryToSend = `?${qs.stringify({ query: queryString })}`;
      const fetchedResults = await adminUser.api.useGlobalSearchBar(queryToSend);

      setResults(fetchedResults.data);
      setLoading(false);
    }
  };

  const onValueSelected = useCallback(
    (_, value) => {
      if (value) {
        let url;

        switch (value.resultType) {
          case "Users":
            url = `/companies/${value.company_id}/users`;
            break;
          case "Companies":
            url = `/companies/${value.id}`;
            break;
          case "Devices":
            url = `/orders/${value.order.id}/devices`;
            break;
          case "Orders":
            url = `/orders/${value.id}`;
            break;
          default:
            break;
        }

        if (url) {
          addToSearchHistory(value.resultType.toLowerCase(), value);
          navigate(url);
        }
      }
    },
    [addToSearchHistory, navigate],
  );

  useDebouncedEffect(fetchResults, 500, [queryString]);

  return (
    <FleetSearchBar
      value={null}
      placeholder="Search"
      options={concatObjectKeysIntoArray(results)}
      onInputChange={(_, newInputValue) => setQueryString(newInputValue)}
      groupBy={(option) => option.resultType}
      getOptionLabel={() => ""}
      loading={loading}
      noOptionsText={queryString.length > 2 ? "No results found" : "No history"}
      filterOptions={(x) => x}
      onChange={onValueSelected}
      renderOption={(option) => (
        <>
          {option.resultType === "Users" && <UserResult user={option} />}
          {option.resultType === "Companies" && <CompanyResult company={option} />}
          {option.resultType === "Devices" && <DeviceResult device={option} />}
          {option.resultType === "Orders" && <OrderResult order={option} />}
        </>
      )}
      sx={{ width: "60%" }}
    />
  );
};

export default SearchBar;
