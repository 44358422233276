import React, { useMemo } from "react";
import { Layout, Tooltip } from "@fleet.co/tarmac";
import CopyButton from "../../../../tools/CopyButton";

const ContactCell = ({ row: device }) => {
  const nameInfo = useMemo(() => {
    if (!device.address) {
      return null;
    }
    const all_addresses = device.order.company.addresses;
    const initial_address_id = device.address ? device.address.id : null;

    const selectedAddress = all_addresses ? all_addresses.find((a) => a.id === initial_address_id) : null;
    const first_name = selectedAddress ? selectedAddress.firstName : "";
    const last_name = selectedAddress ? selectedAddress.lastName : "";

    return `${first_name} ${last_name}`;
  }, [device.address, device.order.company.addresses]);

  return (
    <Tooltip title={nameInfo}>
      <Layout direction="column">
        <CopyButton value={nameInfo}>{nameInfo}</CopyButton>
      </Layout>
    </Tooltip>
  );
};

export default ContactCell;
