import React from "react";
import { Layout, Text } from "@fleet.co/tarmac";

const ProviderCell = ({ row: device }) => (
  <Layout direction="column">
    <Text variant="body2">{device.provider}</Text>
    <Text variant="body2">{device.provider_request_number}</Text>
  </Layout>
);

export default ProviderCell;
