import React from "react";
import { Layout } from "@fleet.co/tarmac";

const LoadingSpinner = () => (
  <Layout direction="column" alignItems="center" justifyContent="center" sx={{ width: "100vw", height: "100vh" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 64 64">
      <path
        fill="#01563E"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity={0.25}
      />
      <path
        fill="#01563E"
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
      >
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        />
      </path>
    </svg>
  </Layout>
);

export default LoadingSpinner;
