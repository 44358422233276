import React, { useState } from "react";
import { Icon, Layout, Tag, Text } from "@fleet.co/tarmac";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-regular-svg-icons";
import styles from "./DevicePenalty.module.scss";

const DeviceReturnStatusTag = (props) => {
  const { disabled, deviceInfo, setDeviceInfo } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    if (!disabled) setIsMenuOpen(!isMenuOpen);
  };

  const label = (
    <Layout direction="row" spacing={1} onClick={handleClick}>
      {deviceInfo.penalty_status === "NO_PENALTY" ? "NO PENALTY" : deviceInfo.penalty_status}
      {!disabled && <Icon icon={isMenuOpen ? faCaretRight : faCaretDown} color="secondary" size="S" />}
    </Layout>
  );

  const getStatusName = () => {
    let color;

    switch (deviceInfo?.penalty_status) {
      case "MISSING":
        color = "red";
        break;
      case "REQUESTED":
        color = "blue";
        break;
      case "NO_PENALTY":
        color = "green";
        break;
      case "PAID":
        color = "green";
        break;
      default:
        color = "grey";
        break;
    }

    const handleClick = (status) => {
      if (status === "NO PENALTY") status = "NO_PENALTY";
      setDeviceInfo({ ...deviceInfo, penalty_status: status });
      setIsMenuOpen(!isMenuOpen);
    };

    return (
      <Layout direction="column" className={styles.statusContainer}>
        <Tag variant="filled" label={label} backgroundColor={color} />
        {isMenuOpen && (
          <Layout direction="column" className={styles.statusList}>
            <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>
              MISSING
            </Text>
            <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>
              REQUESTED
            </Text>
            <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>
              NO PENALTY
            </Text>
            <Text className={styles.status} onClick={(e) => handleClick(e.target.outerText)}>
              PAID
            </Text>
          </Layout>
        )}
      </Layout>
    );
  };

  return getStatusName();
};

export default DeviceReturnStatusTag;
