import React, { useCallback, useContext, useState } from "react";
import { Button, Dialog, Icon, IconButton, Layout, Link, Text, TextField } from "@fleet.co/tarmac";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import styles from "./ContractEndingCSM.module.scss";
import DeviceReturnStatusTag from "./DeviceReturnStatusTag";
import UserContext from "../../../tools/UserContext";
import DeviceEndOfLifeStatus from "./DeviceEndOfLifeStatus";
import DeviceRenewalStatus from "./DeviceRenewalStatus";

const DeviceReturnRow = (props) => {
  const { device, reloadOrders } = props;
  const { user: adminUser } = useContext(UserContext);
  const parcel = device?.activeReturn;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [renewalTypeModal, setRenewalTypeModal] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(device);
  const [isLoading, setIsLoading] = useState(false);

  const cancelPickup = useCallback(async () => {
    await adminUser.api.modifyDevice(device.id, { cancelPickup: true });
  }, [adminUser.api, device.id]);

  const onSubmitModalOpen = useCallback(async () => {
    await cancelPickup();
    setIsConfirmModalOpen(false);
    reloadOrders();
  }, [cancelPickup, reloadOrders]);

  const showLabel = useCallback(async () => {
    window.open(parcel.label_url, "_blank");
  }, [parcel?.label_url]);

  const isLate = new Date(parcel?.pickup_date) < new Date() && parcel?.status === "SCHEDULED";

  const handleSubmit = async () => {
    setIsLoading(true);
    await adminUser.api.modifyDevice(device.id, deviceInfo);
    setIsEditable(false);
    setRenewalTypeModal(false);
    setIsLoading(false);
    reloadOrders();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    setDeviceInfo({
      ...deviceInfo,
      activeReturn: {
        ...deviceInfo.activeReturn,
        tracking_number: value,
      },
    });
  };

  const ActionConfirmComponent = (
    <>
      <Button color="primary" label="Yes" onClick={onSubmitModalOpen} />
      <Button color="secondary" label="No" onClick={() => setIsConfirmModalOpen(false)} />
    </>
  );

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setRenewalTypeModal(false)} />
      <Button color="primary" label={isLoading ? "Loading" : "Confirmation"} onClick={handleSubmit} />
    </>
  );

  return (
    device && (
      <>
        <Layout direction="row" fullWidth key={device.id} className={styles.deviceRow}>
          <div className={styles.bigCell}>
            {device.name}
            <br /> {device.serial_number || "-"}
          </div>
          <Layout direction="row" spacing={1}>
            <DeviceRenewalStatus
              device={device}
              deviceInfo={deviceInfo}
              setDeviceInfo={setDeviceInfo}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              reloadOrders={reloadOrders}
            />
          </Layout>
          <Layout direction="row" spacing={1}>
            <DeviceEndOfLifeStatus
              device={device}
              deviceInfo={deviceInfo}
              setDeviceInfo={setDeviceInfo}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              reloadOrders={reloadOrders}
            />
          </Layout>
          <div>
            <Text variant={isLate && "danger"}>
              {parcel && parcel?.pickup_date ? new Date(parcel.pickup_date).toLocaleDateString("fr") : "-"}
            </Text>
          </div>
          <div>
            {parcel && parcel?.reception_date ? new Date(parcel?.reception_date).toLocaleDateString("fr") : "-"}
          </div>
          <div>{parcel?.carrier || "-"}</div>
          <Layout direction="row" spacing={1}>
            <Layout direction="column">
              {parcel ? (
                <TextField
                  name="tracking_number"
                  onChange={handleInputChange}
                  value={deviceInfo.activeReturn?.tracking_number}
                  disabled={!isEditable}
                  className={styles.inputTracking}
                />
              ) : (
                "-"
              )}

              {parcel?.tracking_number && (
                <a
                  href={`https://fleet.aftership.com/${parcel.tracking_number}?page-name=tracking-page`}
                  target="blank"
                >
                  Track parcel
                </a>
              )}
            </Layout>
          </Layout>
          <Layout direction="row" spacing={1}>
            <DeviceReturnStatusTag
              parcel={parcel}
              isEditable={isEditable}
              setDeviceInfo={setDeviceInfo}
              deviceInfo={deviceInfo}
              reloadOrders={reloadOrders}
            />
          </Layout>
          <div>{parcel?.recipient || "-"}</div>
          <Layout direction="row" spacing={2}>
            {parcel?.label_url && <Link label="Show" onClick={showLabel} />}
            {parcel?.status === "SCHEDULED" && <Link label="Delete" onClick={() => setIsConfirmModalOpen(true)} />}
            {!isEditable && (
              <IconButton color="info" icon={<Icon icon={faPen} size="S" />} onClick={() => setIsEditable(true)} />
            )}
            {isEditable && (
              <Layout direction="row" spacing={1}>
                <Button small variant="text" color="secondary" label="Cancel" onClick={() => setIsEditable(false)} />
                <Button small variant="text" color="secondary" label="Submit" onClick={() => handleSubmit(device)} />
              </Layout>
            )}
          </Layout>
        </Layout>

        <Dialog
          title="Cancel label confirmation"
          onClose={() => setIsConfirmModalOpen(false)}
          open={isConfirmModalOpen}
          Actions={ActionConfirmComponent}
        >
          You are about to cancel the label of the {device.name}. Do you want to proceed ?
        </Dialog>

        <Dialog
          title="Warning"
          onClose={() => setRenewalTypeModal(false)}
          open={renewalTypeModal}
          Actions={ActionComponent}
        >
          <Text variant="body1">You are about to change the renewal type of a device</Text>
          <br />
          <br />
          <Layout direction="row" spacing={2}>
            <Layout direction="column" spacing={2}>
              <Text variant="body1">Device :</Text>
              <Text variant="body1">S/N :</Text>
              <Text variant="body1">New renewal type :</Text>
            </Layout>
            <Layout direction="column" spacing={2} alignItems="center">
              <Text variant="body1">{deviceInfo.name}</Text>
              <Text variant="body1">{deviceInfo.serial_number}</Text>
              <Text variant="danger">{deviceInfo.renewal_type}</Text>
            </Layout>
          </Layout>
        </Dialog>
      </>
    )
  );
};

export default DeviceReturnRow;
