import React from "react";
import { Tag } from "@fleet.co/tarmac";

const SerialNumberCell = ({ row: device }) => {
  if (device.status === "WAITING")
    return <Tag variant="filled" backgroundColor="blue" size="small" label="NOT PURCHASED" />;

  if (!device.serial_number) return <Tag variant="filled" backgroundColor="red" size="small" label="MISSING" />;

  return device.serial_number;
};

export default SerialNumberCell;
