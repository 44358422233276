import React from "react";

import { Button, Checkbox, Layout, Popover, Select } from "@fleet.co/tarmac";

const EnveloppeFilterMenu = (props) => {
  const handleLeaserChange = (value) => {
    props.setFilters({
      ...props.filters,
      leasers: value,
    });
  };

  const handleStatusChange = (value) => {
    props.setFilters({
      ...props.filters,
      statuses: value,
    });
  };

  const handle15KToggle = () => {
    props.setFilters({
      ...props.filters,
      lessThan15K: !props.filters.lessThan15K,
    });
  };

  const handle1monthToggle = () => {
    props.setFilters({
      ...props.filters,
      endingIn1Month: !props.filters.endingIn1Month,
    });
  };

  if (typeof props.leasers === "undefined" || typeof props.filters === "undefined") {
    return null;
  }

  return (
    <Popover
      AnchorComponent={Button}
      anchorProps={{
        variant: "outlined",
        color: "secondary",
        label: "Filter",
      }}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
    >
      <Layout direction="column" spacing={2}>
        <Select
          label="Filter by Leaser"
          multiple
          value={props.filters.leasers}
          options={props.leasers}
          onChange={(value) => handleLeaserChange(value)}
        />

        <Select
          label="Filter by status"
          multiple
          value={props.filters.statuses}
          options={props.statuses}
          onChange={(value) => handleStatusChange(value)}
        />
        <Layout>
          <Layout>
            <Checkbox
              label="Envelopes less than 15k"
              checked={props.filters.lessThan15K === true}
              onChange={handle15KToggle}
            />
          </Layout>
          <Layout>
            <Checkbox
              label="Envelopes ending in 1 month"
              checked={props.filters.endingIn1Month === true}
              onChange={handle1monthToggle}
            />
          </Layout>
        </Layout>
        <Layout direction="row" spacing={1}>
          <Button variant="contained" color="primary" onClick={props.resetFilters} label="Reset all filters" />
        </Layout>
      </Layout>
    </Popover>
  );
};

export default EnveloppeFilterMenu;
