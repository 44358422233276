import React from "react";
import styles from "./EnvelopeCountdown.module.scss";

import { getDaysLeft, getMonthDifference } from "../../tools/DateUtils";

const EnvelopeCountdown = (props) => {
  const { envelope } = props;

  const days_left = getDaysLeft(new Date(), new Date(envelope.end_date));
  const months_left = getMonthDifference(new Date(), new Date(envelope.end_date));

  let time_left_format = "";

  if (days_left <= 0) {
    time_left_format = <p className={styles.red}>Expired</p>;
  } else if (days_left <= 30) {
    time_left_format = (
      <p className={styles.red}>
        {days_left} {days_left === 1 ? "day" : "days"}
      </p>
    );
  } else if (months_left < 2) {
    time_left_format = <p className={styles.red}>1 month</p>;
  } else if (months_left < 3) {
    time_left_format = <p className={styles.red}>2 months</p>;
  } else if (months_left < 4) {
    time_left_format = <p className={styles.orange}>3 months</p>;
  } else {
    time_left_format = <p className={styles.green}>{months_left} months</p>;
  }

  return time_left_format;
};

export default EnvelopeCountdown;
