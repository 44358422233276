import React, { useContext, useEffect, useState } from "react";

import { Button, Heading, Layout } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import UnpaidRow from "../tables/unpaid/UnpaidRow";
import LoadingSpinner from "../common/LoadingSpinner";
import UpdateUnpaidModal from "../blocks/UpdateUnpaidModal";

const Unpaid = () => {
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allUnpaid, setAllUnpaid] = useState([]);
  const [isUpdateUnpaidModalOpen, setIsUpdateUnpaidModalOpen] = useState(false);

  const fetchUnpaid = async () => {
    setIsLoading(true);
    const fetchedUnpaid = await adminUser.api.getUnpaid();

    setAllUnpaid(fetchedUnpaid.data);
    setIsLoading(false);
  };

  const reload = () => {
    fetchUnpaid();
  };

  useEffect(() => {
    fetchUnpaid();
  }, []);

  if (isLoading) {
    return <LoadingSpinner isFullScreen={false} />;
  }

  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="row" justifyContent="space-between">
        <Heading variant="h2">Unpaid</Heading>
        <Button
          label="Update Unpaid"
          color="primary"
          variant="contained"
          onClick={() => setIsUpdateUnpaidModalOpen(true)}
        />
      </Layout>
      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "18%" }}>Company Name</td>
            <td style={{ width: "18%" }}>Leaser</td>
            <td style={{ width: "18%" }}>Order Number</td>
            <td style={{ width: "15%" }}>Status</td>
            <td style={{ width: "15%" }}>Last Update</td>
            <td style={{ width: "15%" }}>Resolved Date</td>
          </tr>
        </thead>
        <tbody>
          {allUnpaid.map((unpaid) => (
            <UnpaidRow unpaid={unpaid} key={unpaid.id} reload={reload} allUnpaid={allUnpaid} />
          ))}
        </tbody>
      </table>

      <UpdateUnpaidModal
        open={isUpdateUnpaidModalOpen}
        closeAction={() => setIsUpdateUnpaidModalOpen(false)}
        reload={reload}
        currentUnpaid={allUnpaid}
      />
    </Layout>
  );
};

export default Unpaid;
