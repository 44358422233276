import axios from "axios";

let base_url = "";

if (import.meta.env.REACT_APP_PR_NUMBER) {
  base_url = `https://fleet-co-pr-${import.meta.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
} else {
  base_url = import.meta.env.REACT_APP_APIURL;
}

export default axios.create({
  baseURL: `${base_url || "/v1"}`,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
  },
});
