import React from "react";

const StyledFlags = (props) => {
  let { color } = props;
  let { text } = props;
  const type = props.type.toString() || "";

  switch (type.toLowerCase()) {
    case "signatory":
      color = "orange";
      text = "Signatory";
      break;
    case "admin":
      color = "green";
      text = "Admin";
      break;
    case "fleet_admin":
      color = "red";
      text = "Super Admin";
      break;
    case "employee":
      color = "blue";
      text = "Employee";
      break;
    case "finance":
      color = "yellow";
      text = "Finance";
      break;
    case "support":
      color = "green";
      text = "Support";
      break;
    case "order":
      color = "blue";
      text = "Order";
      break;
    case "marketing":
      color = "pink";
      text = "Marketing";
      break;
    case "file":
      color = "grey";
      break;
    default:
      color = "yellow";
      text = type;
  }

  return (
    <span {...props} className={`flag ${color} ${props.onClick && "clickable"}`}>
      <span className={`dot ${color}`} />
      {text}
    </span>
  );
};

export default StyledFlags;
