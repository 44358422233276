import RoundedPictureLoader from "src/components/common/RoundedPictureLoader";
import { useParams } from "react-router";
import type { Company } from "src/types/Company.type";
import { useCompanyImageMutation, useDeleteCompanyImageMutation } from "./queries/companyQueries";

const createCompanyInitials = (company: Company) =>
  company.name
    ?.split(" ")
    .map((word) => word[0])
    .join("");

export function CompanyPicture({ company }: { company: Company }) {
  const { id } = useParams<{ id: string }>();
  const { mutate: updateCompanyImage } = useCompanyImageMutation(Number(id));
  const { mutate: deleteCompanyImage } = useDeleteCompanyImageMutation(Number(id));

  const handleImageChange = async (file: File | null | undefined) => {
    if (!company.id) {
      return;
    }

    if (file) {
      const formData = new FormData();

      formData.append("company_image", file);

      updateCompanyImage(formData);
    } else {
      deleteCompanyImage();
    }
  };

  return (
    <RoundedPictureLoader
      avatarInitials={createCompanyInitials(company)}
      onChange={(file) => handleImageChange(file)}
      picture={company?.img_url}
      size="s"
      isChanging
      buttonsPlacement="left"
    />
  );
}
