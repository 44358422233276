import type { AxiosError } from "axios";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "../common/LoadingSpinner";

function fallbackRender({ error }: { error: Error | AxiosError }) {
  if ("response" in error) {
    const status = error.response?.status;
    if (status && [401, 403].includes(status)) {
      return <LoadingSpinner />;
    }
  }

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

export default function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return <ReactErrorBoundary fallbackRender={fallbackRender}>{children}</ReactErrorBoundary>;
}
