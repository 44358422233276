import React, { useCallback, useContext, useEffect, useState } from "react";
import { faCheckDouble, faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Button, Dialog, Icon, Layout, Text, TextField } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import styles from "./DevicePenalty.module.scss";
import DevicePenaltyRow from "./DevicePenaltyRow";
import PenaltyFilterMenu from "../../blocks/PenaltyFilterMenu";
import usePersistedState from "../../../hooks/usePersistedState";

// create two const, one for minDate one year ago and one for maxDate one year from now
const minDate = new Date();

minDate.setMonth(minDate.getMonth() - 3);

const maxDate = new Date();

maxDate.setMonth(maxDate.getMonth() + 3);

const initialFilters = {
  minDate,
  maxDate,
  searchCompanySerialNumber: "",
  status: "",
};

const DevicePenaltyCSM = (props) => {
  const { isMassSelectEnabled } = props;
  const { user: adminUser } = useContext(UserContext);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [excludeNoPenalty, setExcludeNoPenalty] = useState(true);
  const [dateSorting, setDateSorting] = useState("ASC");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filters, setFilters] = usePersistedState("renewal_penalty_filters", { ...initialFilters });

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const handleSort = useCallback(() => {
    if (dateSorting === "ASC") {
      setDateSorting("DESC");
    } else {
      setDateSorting("ASC");
    }
  }, [dateSorting]);

  const fetchDevices = useCallback(async () => {
    setIsLoading(true);
    const params = {};

    params.filter = "contract_end_date";
    params.renewal_type = ["BROKEN", "RENEW", "STOP"];

    // Filters
    if (excludeNoPenalty) params.exclude_no_penalty = true;

    if (filters.minDate !== null) {
      params.contractEndMinDate = new Date(filters.minDate);
    }

    if (filters.maxDate !== null) {
      params.contractEndMaxDate = new Date(filters.maxDate);
    }

    if (dateSorting === "ASC") {
      params.filterDirection = dateSorting;
    }

    if (filters.searchCompanyNameSerialNumber !== "") {
      params.search_company_name_serial_number = filters.searchCompanyNameSerialNumber;
    }

    if (filters.status !== "") {
      params.status_filter = filters.status;
    }

    const fetchedDevices = await adminUser.api.getDevices({ params });

    setDevices(fetchedDevices.data);
    setIsLoading(false);
  }, [adminUser.api, dateSorting, excludeNoPenalty, filters]);

  // MASS SELECT
  const selectForMassEdit = useCallback(
    (d) => {
      const selectedDevicesCopy = [...selectedDevices];
      const idx = selectedDevicesCopy.indexOf(d);

      if (idx === -1) {
        selectedDevicesCopy.push(d);
      } else {
        selectedDevicesCopy.splice(idx, 1);
      }
      setSelectedDevices(selectedDevicesCopy);
    },
    [selectedDevices],
  );

  const handleMassEdit = useCallback(async () => {
    setIsLoading(true);
    for (const device of selectedDevices) {
      const deviceData = {
        penalty_status: "NO_PENALTY",
      };

      await adminUser.api.updateDevicePenalty(device.id, deviceData);
    }

    setSelectedDevices([]);
    setIsModalOpen(false);
    fetchDevices();
    setIsLoading(false);
  }, [adminUser.api, fetchDevices, selectedDevices]);

  useEffect(() => {
    if (!isMassSelectEnabled) {
      setSelectedDevices([]);
    }
  }, [isMassSelectEnabled]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices, filters, dateSorting, excludeNoPenalty]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setIsModalOpen(false)} />
      <Button color="primary" label={isLoading ? "Loading" : "Confirmation"} onClick={handleMassEdit} />
    </>
  );

  return (
    <>
      <Layout direction="column" spacing={2}>
        {isMassSelectEnabled && (
          <Layout direction="row" spacing={1}>
            <Button
              variant="text"
              color="secondary"
              label="Apply no penalty"
              startIcon={faCheckDouble}
              onClick={() => setIsModalOpen(true)}
              disabled={selectedDevices.length === 0}
            />
          </Layout>
        )}
        {!isMassSelectEnabled && (
          <Layout direction="row" spacing={1} justifyContent="flex-end">
            <TextField
              type="search"
              label="Company, Serial Number..."
              value={filters.searchCompanyNameSerialNumber}
              onChange={(e) => setFilters({ ...filters, searchCompanyNameSerialNumber: e.target.value })}
            />
            <PenaltyFilterMenu
              resetFilters={resetFilters}
              filters={filters}
              applyFilters={setFilters}
              excludeNoPenalty={excludeNoPenalty}
              setExcludeNoPenalty={setExcludeNoPenalty}
            />
          </Layout>
        )}
        <Layout direction="row" className={styles.tableHead}>
          <div className={styles.littleCell} />
          <div>Contract ID</div>
          <div>Device</div>
          <Layout direction="row" spacing={1} onClick={() => handleSort()}>
            <div>End Date</div>
            <Icon icon={dateSorting === "DESC" ? faChevronDown : faChevronUp} color="secondary" size="S" />
          </Layout>
          <div>Company</div>
          <div>Amount Due</div>
          <div>Reason</div>
          <div>Status</div>
          <div className={styles.littleCell} />
        </Layout>
        <Layout direction="column">
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            devices.map((d) => (
              <DevicePenaltyRow
                device={d}
                key={d.id}
                reload={fetchDevices}
                selectForMassEdit={() => isMassSelectEnabled && selectForMassEdit(d)}
                isMassSelectEnabled={isMassSelectEnabled}
                isSelected={selectedDevices.includes(d)}
                filters={filters}
              />
            ))}
        </Layout>
      </Layout>
      <Dialog title="Warning" onClose={() => setIsModalOpen(false)} Actions={ActionComponent} open={isModalOpen}>
        <Text variant="body1">No penalty will be applied to the selected devices</Text>
      </Dialog>
    </>
  );
};

export default DevicePenaltyCSM;
