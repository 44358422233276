import React from "react";
import styles from "./BreadcrumbAmount.module.scss";

const BreadcrumbAmount = (props) => {
  const { total_amount, current_amount } = props;

  const amount_left = total_amount - current_amount;
  const widthPercentage = (amount_left * 100) / total_amount;

  const ENVELOPE_LOW = 30000;
  const ENVELOPE_VERY_LOW = 15000;

  const getEnvelopeClass = () => {
    if (current_amount <= ENVELOPE_VERY_LOW) {
      return styles.envelopeVeryLow;
    }

    if (current_amount <= ENVELOPE_LOW) {
      return styles.envelopeLow;
    }

    return styles.envelopeNormal;
  };

  return (
    <div className={`${styles.breadcrumb} ${getEnvelopeClass()}`}>
      <div className={styles.breadcrumb__step} style={{ width: `${widthPercentage}%` }} />
    </div>
  );
};

export default BreadcrumbAmount;
