import React, { useEffect, useRef, useState } from "react";
import { faClose, faPen } from "@fortawesome/pro-regular-svg-icons";
import { Avatar, theme, Layout, Icon } from "@fleet.co/tarmac";
import styles from "./RoundedPictureLoader.module.scss";

const RoundedPictureLoader = ({
  avatarInitials,
  onChange,
  picture,
  isChanging,
  size = "m",
  buttonsPlacement = "right",
}: {
  avatarInitials?: string;
  onChange: (picture: File | null) => void;
  picture: string | null;
  isChanging: boolean;
  size: "s" | "m";
  buttonsPlacement: "right" | "left";
}) => {
  const imageToBlob = new Blob([picture ?? ""], { type: "text/plain" });
  const [imgPreview, setImgPreview] = useState(picture ? URL.createObjectURL(imageToBlob) : "");
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const onLoadButtonClick = () => {
    hiddenFileInput.current?.click();
  };

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const photo = e.target.files?.[0];

    setImgPreview(photo ? URL.createObjectURL(photo) : "");
    onChange(photo ?? null);
  };

  const onRemove = () => {
    setImgPreview("");
    onChange(null);
  };

  useEffect(() => {
    // it's an object when the user changes it, and it's handled by the function onFileUpload above
    if (typeof picture === "string") {
      setImgPreview(picture);
    }

    if (!picture) {
      setImgPreview("");
    }
  }, [picture]);

  let avatarSize = 56;
  let buttonSpacing = 2;
  switch (size) {
    case "s":
      avatarSize = 32;
      buttonSpacing = 1;
      break;
    case "m":
      avatarSize = 56;
      buttonSpacing = 2;
      break;
    default:
      avatarSize = 56;
      buttonSpacing = 2;
      break;
  }
  return (
    <Layout direction="column" spacing={2}>
      <Layout
        direction={buttonsPlacement === "right" ? "row" : "row-reverse"}
        spacing={2}
        alignItems="center"
        flexWrap="wrap"
      >
        <Avatar
          size={avatarSize}
          letters={avatarInitials?.toUpperCase()}
          src={imgPreview}
          color={theme.palette.purple[300]}
          lettersVariant="body2"
        />
        {isChanging && (
          <Layout direction="column" spacing={buttonSpacing}>
            <Layout
              direction="row"
              spacing={2}
              onClick={onLoadButtonClick}
              alignItems="center"
              className={styles.action}
            >
              <Icon size="S" icon={faPen} />
            </Layout>
            <Layout direction="row" spacing={2} onClick={onRemove} alignItems="center" className={styles.action}>
              <Icon size="S" icon={faClose} />
            </Layout>
          </Layout>
        )}
        <input type="file" ref={hiddenFileInput} onChange={onFileUpload} className={styles.notDisplay} />
      </Layout>
    </Layout>
  );
};

export default RoundedPictureLoader;
