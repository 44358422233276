import React, { useContext, useEffect, useState } from "react";
import { FileDropzone, Layout, Link, Text, TextField } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import FileOrderSection from "./FileOrderSection";

const CompanyInfoSection = ({ company, isEditable, refresh }) => {
  const { user: adminUser } = useContext(UserContext);
  const [paymentMethod, setPaymentMethod] = useState([]);

  const companyIbanFile = company.files.find((f) => f.company_id === company.id && f.type === "IBAN");

  const paymentMethodsData = async () => {
    const allPaymentsMethods = await adminUser.api.getPaymentMethods(company.id);

    return allPaymentsMethods.data.find((pm) => pm.is_active === true);
  };

  useEffect(() => {
    const fetchDefaultPayments = async () => {
      try {
        const paymentMethods = await paymentMethodsData();

        setPaymentMethod(paymentMethods);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDefaultPayments();
  }, []);

  const uploadFile = async (files, type) => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "ACCEPTED");
    formData.append("company_id", company.id);
    formData.append("file_contents", files[0]);
    await adminUser.api.addFile(formData);
    refresh();
  };

  const handleFileUpload = (type) => (files) => {
    uploadFile(files, type);
  };

  const filesUrl = `/companies/${company.id}/files`;

  return (
    <Layout direction="column" spacing={3}>
      <Layout direction="column" spacing={1}>
        <Text variant="body2">SEPA Mandates {!paymentMethod && ": NONE"}</Text>
        {paymentMethod && (
          <Layout direction="row" spacing={1} alignItems="flex-end">
            <TextField disabled value={paymentMethod.is_active ? `${paymentMethod.name} *` : paymentMethod.name} />
            <TextField disabled value={`**** **** **** **** **** ${paymentMethod.last4}`} />
          </Layout>
        )}
        {company?.stripe_id && (
          <Link
            label="View in Stripe"
            href={`https://dashboard.stripe.com/customers/${company.stripe_id}`}
            targetBlank
          />
        )}
      </Layout>

      <Layout direction="column" spacing={2}>
        <Text variant="body2">RIB: {companyIbanFile ? companyIbanFile.status : "NONE"}</Text>
        {companyIbanFile ? (
          <FileOrderSection file={companyIbanFile} refresh={refresh} isEditable={isEditable} />
        ) : (
          <FileDropzone onFilesAdded={handleFileUpload("IBAN")} helperText="Drag and drop or select" />
        )}
      </Layout>

      <Link label="👉 Go to company files" href={filesUrl} color="info" />
    </Layout>
  );
};

export default CompanyInfoSection;
