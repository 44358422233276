import React from "react";
import FormatDate from "../../../tools/FormatDate";
import styles from "./UnpaidRow.module.scss";
import CopyButton from "../../../tools/CopyButton";
import { getLeaserLabel } from "../../../tools/OrderTools";

const UnpaidRow = ({ unpaid }) => (
  <tr key={unpaid.id}>
    <td className={styles.unpaidRow_table}>{unpaid.order.company.name}</td>
    <td className={styles.unpaidRow_table}>{getLeaserLabel(unpaid.order.leaser)}</td>
    <td className={styles.unpaidRow_table}>
      <p className={styles.unpaidRow_code}>
        {unpaid.order.contract_number}
        <CopyButton value={unpaid.order.contract_number} />
      </p>
    </td>
    <td className={styles.unpaidRow_table}>{unpaid.status}</td>
    <td className={styles.unpaidRow_table}>
      <FormatDate date={unpaid.last_sync_date} />
    </td>
    <td className={styles.unpaidRow_table}>
      {unpaid.resolution_date ? <FormatDate date={unpaid.resolution_date} /> : "-"}
    </td>
  </tr>
);

export default UnpaidRow;
