import React, { useContext, useMemo, useState } from "react";
import { faCheck, faEuroSign, faPen, faRemove } from "@fortawesome/pro-regular-svg-icons";
import { Button, Checkbox, Dialog, Icon, Layout, Select, Text, TextField } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import styles from "./DevicePenalty.module.scss";
import DevicePenaltyStatusTag from "./DevicePenaltyStatusTag";

const DevicePenaltyRow = (props) => {
  const { device, isMassSelectEnabled, selectForMassEdit, isSelected } = props;
  const { user: adminUser } = useContext(UserContext);
  const [deviceInfo, setDeviceInfo] = useState(device);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reasonOptions = useMemo(
    () => [
      {
        label: "Broken screen",
        value: "BROKEN SCREEN",
      },
      {
        label: "Broken keyboard",
        value: "BROKEN KEYBOARD",
      },
      {
        label: "Broken touchpad",
        value: "BROKEN TOUCHPAD",
      },
      {
        label: "Broken camera",
        value: "BROKEN CAMERA",
      },
      {
        label: "Broken mic/speakers",
        value: "BROKEN MIC/SPEAKERS",
      },
      {
        label: "Broken battery",
        value: "BROKEN BATTERY",
      },
      {
        label: "Broken/lost charger",
        value: "BROKEN CHARGER",
      },
      {
        label: "Blocked computer (icloud/password...)",
        value: "BLOCKED COMPUTER",
      },
      {
        label: "Computer not working",
        value: "BROKEN COMPUTER",
      },
      {
        label: "GPU out of service",
        value: "BROKEN GPU",
      },
      {
        label: "Motherboard out of service",
        value: "BROKEN MOTHERBOARD",
      },
    ],
    [deviceInfo],
  );

  const getDate = (date) => {
    const dateStandardFormat = new Date(date).toLocaleDateString("fr");

    if (dateStandardFormat === "01/01/1970") return "-";

    return dateStandardFormat;
  };

  const onSave = async () => {
    setIsLoading(true);
    const { penalty_status, penalty_amount_due, penalty_reasons } = deviceInfo;

    await adminUser.api.updateDevicePenalty(device.id, { penalty_status, penalty_amount_due, penalty_reasons });
    setIsEditable(!isEditable);
    setIsModalOpen(false);
    setIsLoading(false);
    props.reload();
  };

  const handleCancel = () => {
    setIsEditable(!isEditable);
    setDeviceInfo(device);
  };

  const handleSave = () => {
    if (deviceInfo.penalty_amount_due || deviceInfo.penalty_status === "NO_PENALTY") {
      setIsModalOpen(true);
    } else {
      onSave();
    }
  };

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setIsModalOpen(false)} />
      <Button color="primary" loading={isLoading} label="Confirm" onClick={onSave} />
    </>
  );

  return (
    device && (
      <Layout className={styles.container} onClick={selectForMassEdit}>
        <Layout direction="row" fullWidth key={device.id} className={styles.contractRow}>
          <div className={styles.littleCell}>
            {isMassSelectEnabled && <Checkbox checked={isSelected} onChange={selectForMassEdit} />}
          </div>
          <div className={styles.mediumCell}>{device.order.contract_number}</div>
          <div className={styles.nameCell}>
            <Text className={styles.nameText}>{device.name}</Text>
            <Text className={styles.nameText}>{device.serial_number}</Text>
          </div>
          <div>{getDate(device.order.contract_end_date)}</div>
          <div>{device.order.company.name}</div>
          <div>
            <TextField
              name="amount_due"
              className={styles.inputAmountDue}
              type="number"
              onChange={(e) => setDeviceInfo({ ...deviceInfo, penalty_amount_due: e.target.value })}
              value={deviceInfo?.penalty_amount_due || ""}
              disabled={!isEditable}
              icon={faEuroSign}
              onKeyDown={(e) => ["e", "E", ","].includes(e.key) && e.preventDefault()}
            />
          </div>
          <div className={styles.reasonCell}>
            <Select
              multiple
              options={reasonOptions}
              onChange={(value) => setDeviceInfo({ ...deviceInfo, penalty_reasons: value })}
              value={deviceInfo.penalty_reasons}
              disabled={!isEditable}
            />
          </div>
          <div>
            <DevicePenaltyStatusTag disabled={!isEditable} deviceInfo={deviceInfo} setDeviceInfo={setDeviceInfo} />
          </div>
          <div className={styles.littleCell}>
            {!isEditable ? (
              <Icon
                className={styles.modifyIcon}
                icon={faPen}
                color="secondary"
                size="S"
                onClick={() => setIsEditable(!isEditable)}
              />
            ) : (
              <Layout direction="row" spacing={1}>
                <Icon className={styles.modifyIcon} icon={faRemove} color="secondary" size="S" onClick={handleCancel} />
                <Icon className={styles.modifyIcon} icon={faCheck} color="secondary" size="S" onClick={handleSave} />
              </Layout>
            )}
          </div>
        </Layout>

        <Dialog title="Warning" onClose={() => setIsModalOpen(false)} Actions={ActionComponent} open={isModalOpen}>
          <Text variant="body1">You are going to edit this device's status.</Text>
          <br />
          {deviceInfo.penalty_amount_due && deviceInfo.penalty_status !== "NO_PENALTY" && (
            <Text variant="body1">The following penalty will be deducted</Text>
          )}
          {deviceInfo.penalty_status === "NO_PENALTY" && <Text variant="body1">No penalty will be deducted</Text>}
          {deviceInfo.penalty_amount_due && (
            <>
              <br />
              <Text variant={deviceInfo.penalty_status !== "NO_PENALTY" ? "danger" : "accent"}>
                Penalty :{deviceInfo.penalty_amount_due}€
              </Text>
            </>
          )}
          <br />
          <Text variant="body1">Client's name :{deviceInfo.order.company.name}</Text>
          <Text variant="body1">Device's name :{deviceInfo.name}</Text>
          <Text variant="body1">S/N :{deviceInfo.serial_number}</Text>
        </Dialog>
      </Layout>
    )
  );
};

export default DevicePenaltyRow;
