import { useMemo } from "react";
import { Button, Icon, Layout, Text, Tooltip } from "@fleet.co/tarmac";
import { Link as ReactLink } from "react-router";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import CopyButton from "../../../../tools/CopyButton";

const CompanyNameCell = ({ row: device }) => {
  const { nbIdenticalItems, itemPosition } = device.packageInfo;

  const getPackageInfo = useMemo(() => {
    if (nbIdenticalItems > 1) {
      return `${itemPosition}/${nbIdenticalItems}`;
    }

    return null;
  }, [nbIdenticalItems, itemPosition]);

  return (
    <Layout direction="column">
      <Button
        label={
          <Text variant="caption" bold>
            {device.order.company.name}
          </Text>
        }
        variant="text"
        size="small"
        color="secondary"
        component={ReactLink}
        to={`orders/${device.order.id}/devices`}
      />
      <Layout direction="row" align="center" gap={2} sx={{ marginLeft: "12px" }}>
        {getPackageInfo && <Text variant="caption">{getPackageInfo}</Text>}
        {device.order.comment && device.order.comment !== "" && (
          <Tooltip
            title={
              <CopyButton value={device.order.comment} color="white">
                {device.order.comment}
              </CopyButton>
            }
          >
            <Icon icon={faComment} color="info" size="S" />
          </Tooltip>
        )}
      </Layout>
    </Layout>
  );
};

export default CompanyNameCell;
