import { Navigate, useLocation } from "react-router";

const AuthRoute = ({ children, isSuperAdmin }) => {
  const location = useLocation();
  if (!isSuperAdmin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthRoute;
