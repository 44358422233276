import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Badge, Button, Icon, Layout, TabItem, Tabs, Text } from "@fleet.co/tarmac";
import {
  faBuildings,
  faEnvelope,
  faFiles,
  faLaptop,
  faMapLocationDot,
  faShoppingCart,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyInfoTabs = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext);

  const [currentTab, setCurrentTab] = useState(0);

  const displaySideButton = () => {
    if (location.pathname.startsWith(`/companies/${company.id}/users`)) {
      return (
        <Button
          disabled={props.currentAction === "add_user"}
          variant="contained"
          color="primary"
          size="small"
          label="Add New User"
          onClick={() => props.triggerAction("add_user")}
        />
      );
    }

    if (location.pathname.startsWith(`/companies/${company.id}/funding`)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          label="Add New Funding"
          onClick={() => props.setModalOpened({ type: "ADD_NEW_FUNDING" })}
        />
      );
    }

    if (location.pathname.startsWith(`/companies/${company.id}/files`)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          label="Add New File"
          onClick={() => props.setShowFileUpload(true)}
        />
      );
    }

    return null;
  };

  const generalLink = `/companies/${company.id}`;

  const tabLinks = useMemo(
    () => [
      {
        label: "Company Info",
        value: generalLink,
        icon: faBuildings,
      },
      {
        label: "Addresses",
        value: `${generalLink}/addresses`,
        icon: faMapLocationDot,
      },
      {
        label: "Orders",
        value: `${generalLink}/orders`,
        icon: faShoppingCart,
        badgeValue: company?.orders.length,
      },
      {
        label: "Funding",
        value: `${generalLink}/funding`,
        icon: faEnvelope,
        badgeValue: company?.funding_envelopes.length,
      },
      {
        label: "Devices",
        value: `${generalLink}/devices`,
        icon: faLaptop,
        badgeValue: company?.devices.length,
      },
      {
        label: "Users",
        value: `${generalLink}/users`,
        icon: faUsers,
        badgeValue: company?.employees.length,
      },
      {
        label: "Files",
        value: `${generalLink}/files`,
        icon: faFiles,
      },
    ],
    [generalLink],
  );

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(tabLinks[newValue].value);
  };

  useEffect(() => {
    props.triggerAction("");
    setCurrentTab(tabLinks.findIndex((tab) => tab.value === location.pathname));
  }, [location.pathname, props.triggerAction, tabLinks, navigate]);

  return (
    <Layout direction="row" justifyContent="space-between">
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabLinks.map((tab) => (
          <TabItem
            key={tab.value}
            label={
              <Layout direction="row" spacing={1}>
                <Text variant="body2">{tab.label}</Text>
                {tab.badgeValue ? (
                  <Badge color="green" badgeContent={tab.badgeValue}>
                    <Icon icon={tab.icon} />
                  </Badge>
                ) : (
                  <Icon icon={tab.icon} />
                )}
              </Layout>
            }
          />
        ))}
      </Tabs>
      {displaySideButton()}
    </Layout>
  );
};

export default CompanyInfoTabs;
