import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Icon, Layout } from "@fleet.co/tarmac";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import styles from "./PictureLoader.module.scss";

const PictureLoader = (props) => {
  const { small, onChange, image, onDelete } = props;
  const [imgPreview, setImgPreview] = useState(null);
  const hiddenFileInput = useRef(null);

  const onLoadButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const onFileUpload = (e) => {
    const photo = e.target.files[0];

    setImgPreview(URL.createObjectURL(photo));
    onChange(photo);
  };

  useEffect(() => {
    // it's an object when the user changes it, and it's handled by the function onFileUpload above
    if (typeof image === "string") {
      setImgPreview(image);
    }
  }, [image]);

  const imageUploaderStyles = {
    width: small ? 126 : 255,
    height: small ? 124 : 241,
  };

  const handleDelete = useCallback(() => {
    setImgPreview(null);
    onDelete();
  }, [onDelete]);

  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="row" spacing={2} alignItems="center" flexWrap="wrap" style={{ position: "relative" }}>
        {typeof onDelete === "function" && imgPreview && (
          <Icon icon={faTimes} color="secondary" size="S" onClick={handleDelete} className={styles.deleteIcon} />
        )}
        <Card clickable className={styles.imageUploader} onClick={onLoadButtonClick} style={imageUploaderStyles}>
          {imgPreview && <img src={imgPreview} alt="" width={219} />}
          {!imgPreview && <Icon icon={faPlus} color="secondary" size="S" />}
        </Card>
        <input type="file" value="" ref={hiddenFileInput} onChange={onFileUpload} className={styles.imageInput} />
      </Layout>
    </Layout>
  );
};

export default PictureLoader;
