import React, { useContext, useEffect, useState } from "react";
import { Select } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";

const UsersSelect = (props) => {
  const { companyId, onChange } = props;
  const { user: adminUser } = useContext(UserContext);
  const [usersToSelect, setUsersToSelect] = useState([]);

  const userSortingFunc = (a, b) => {
    const isAAdmin = a.roles.some((role) => role.role === "ADMIN");
    const isBAdmin = b.roles.some((role) => role.role === "ADMIN");

    if (isAAdmin && !isBAdmin) {
      return -1;
    }

    if (!isAAdmin && isBAdmin) {
      return 1;
    }

    return 0;
  };

  // Get all employee
  const loadUsers = async () => {
    const users = [];
    const fetchedUsers = await adminUser.api.getUsers(`?company_id=${companyId}`);

    for (const user of fetchedUsers.data.sort(userSortingFunc)) {
      const isAdmin = user.roles.some((role) => role.role === "ADMIN");

      users.push({
        label: `${user.firstName} ${user.lastName} ${isAdmin ? "(Admin)" : ""}`,
        value: user.id,
      });
    }

    setUsersToSelect(users);
  };

  useEffect(() => {
    if (companyId === "" || companyId === undefined) return;
    loadUsers();
  }, [companyId]);

  return <Select label="Select a user" options={usersToSelect} onChange={(value) => onChange(value)} />;
};

export default UsersSelect;
