import React, { useContext } from "react";
import { Link as ReactLink } from "react-router";
import { Button, Icon, IconButton, Layout, Menu, MenuItem } from "@fleet.co/tarmac";
import { faArrowRight, faEllipsisVertical, faFile, faFilePdf, faLaptop } from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../../../tools/UserContext";
import { useToastContext } from "../../../../contexts/ToastContext";
import { downloadFileBlob } from "../../../../helpers/FilesHelpers";

const ActionsCell = ({ row: order }) => {
  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();

  const generateOrderForm = async (order) => {
    addToast("Generating order form...", "info");
    try {
      const response = await adminUser.api.orderForm(order.id);

      downloadFileBlob(response.data, `order_form_contract_${order.contract_number}.pdf`);
      addToast("Order form generated", "success");
    } catch (error) {
      addToast("Error generating order form", "error");
    }
  };

  return (
    <Layout direction="row" gap={2}>
      <Button
        label="View Order"
        startIcon={faArrowRight}
        component={ReactLink}
        to={`/orders/${order.id}`}
        variant="outlined"
        color="secondary"
        size="small"
      />
      <Menu
        AnchorComponent={IconButton}
        anchorProps={{ color: "secondary", size: "M", icon: <Icon icon={faEllipsisVertical} /> }}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <MenuItem
          label="View Devices"
          component={ReactLink}
          to={`/orders/${order.id}/devices`}
          icon={<Icon icon={faLaptop} />}
        />
        <MenuItem
          label="View Files"
          component={ReactLink}
          to={`/orders/${order.id}/files`}
          icon={<Icon icon={faFile} />}
        />
        <MenuItem
          label="Generate Order File"
          onClick={() => generateOrderForm(order)}
          icon={<Icon icon={faFilePdf} />}
        />
      </Menu>
    </Layout>
  );
};

export default ActionsCell;
