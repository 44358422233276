import { useCallback, useContext, useEffect, useState } from "react";

import { Button, Heading, Layout, Select } from "@fleet.co/tarmac";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { CURRENCIES } from "src/common/i18n-consts";
import styles from "./Products.module.scss";
import UserContext from "../../tools/UserContext";
import usePersistedState from "../../hooks/usePersistedState";

import ProductGroupTable from "../tables/productGroups/ProductGroupTable";
import ProductGroupModal from "../tables/productGroups/ProductGroupModal";
import ProductModal from "../tables/productGroups/ProductModal";
import BannerModal from "../tables/productGroups/BannerModal";
import { brands, categories } from "../../data/productSpecs";

const currencyOptions = [
  {
    label: "€ Euros",
    value: CURRENCIES.EUR,
  },
  {
    label: "£ British Pounds",
    value: CURRENCIES.GBP,
  },
  {
    label: "$ United States Dollars",
    value: CURRENCIES.USD,
  },
];

const Products = () => {
  const { user: adminUser } = useContext(UserContext);
  const [allProductGroups, setAllProductGroups] = useState([]);
  const [banner, setBanner] = useState(null);
  const [displayedProductGroups, setDisplayedProductGroups] = useState([]);

  const [brandFilter, setBrandFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("COMPUTER");

  const [productGroupModalOpen, setProductGroupModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [currentEditingProductGroup, setCurrentEditingProductGroup] = useState(null);
  const [currentEditingProduct, setCurrentEditingProduct] = useState(null);

  const [bannerModalOpen, setBannerModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [currentCurrency, setCurrentCurrency] = usePersistedState("catalog_currency", "EUR");

  const fetchProductGroups = useCallback(async () => {
    setIsLoading(true);
    const product_groups = await adminUser.api.getProductGroups();

    setAllProductGroups(product_groups.data);
    setIsLoading(false);
  }, [adminUser.api]);

  const getBanner = useCallback(async () => {
    setIsLoading(true);
    const bannerResponse = await adminUser.api.getBanner();

    setBanner(bannerResponse.data);
    setIsLoading(false);
  }, [adminUser.api]);

  const onSaveProductGroupModal = async () => {
    fetchProductGroups();
    setProductGroupModalOpen(false);
  };

  const onSaveProductModal = async () => {
    await fetchProductGroups();
    setProductModalOpen(false);
  };

  const onSaveBannerModal = () => {
    getBanner();
    setBannerModalOpen(false);
  };

  const changeBrandFilter = (value) => {
    setBrandFilter(value);
  };

  const openProductGroupModal = (product_group) => {
    setCurrentEditingProductGroup(product_group);
    setProductGroupModalOpen(true);
  };

  const openProductModal = (product_group, product) => {
    setCurrentEditingProductGroup(product_group);
    setCurrentEditingProduct(product);
    setProductModalOpen(true);
  };

  useEffect(() => {
    fetchProductGroups();
    getBanner();
  }, [fetchProductGroups, getBanner]);

  useEffect(() => {
    let matchingProductGroups = allProductGroups;

    if (brandFilter) {
      matchingProductGroups = matchingProductGroups.filter((pg) => pg.brand === brandFilter);
    }

    if (categoryFilter) {
      matchingProductGroups = matchingProductGroups.filter((pg) => pg.category === categoryFilter);
    }

    setDisplayedProductGroups(matchingProductGroups);
  }, [brandFilter, categoryFilter, allProductGroups]);

  const brandOptions = [
    { label: "-", value: "" },
    ...brands.map((b) => ({ label: b.nicename, value: b.slug, selected: b.slub === brandFilter })),
  ];

  return (
    !isLoading && (
      <>
        <Layout direction="column" spacing={2}>
          <Layout direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Select
              label="Currency"
              options={currencyOptions}
              onChange={(value) => setCurrentCurrency(value)}
              value={currentCurrency}
            />

            <Heading variant="h2">Fleet Catalog</Heading>

            <Layout direction="row" spacing={1}>
              <Button variant="contained" color="primary" label="Banner" onClick={() => setBannerModalOpen(true)} />

              <Button variant="contained" color="primary" label="Export" endIcon={faDownload} />

              <Button
                variant="contained"
                color="primary"
                label="Add Group"
                onClick={() => openProductGroupModal(null)}
              />
            </Layout>
          </Layout>

          <section className={styles.categoryfilter}>
            <div className={styles.categoryfilterBox}>
              <Select value={brandFilter} options={brandOptions} onChange={changeBrandFilter} />
            </div>
            <div
              className={styles.categoryfilterBox}
              onClick={() => setCategoryFilter(null)}
              style={
                !categoryFilter
                  ? { border: "1px solid #0F0096", color: "#0F0096", textAlign: "center" }
                  : { textAlign: "center" }
              }
            >
              Tous les appareils
            </div>

            {categories.map((cat, index) => (
              <div
                className={styles.categoryfilterBox}
                key={index}
                onClick={() => setCategoryFilter(cat)}
                style={categoryFilter === cat ? { border: "1px solid #0F0096", color: "#0F0096" } : {}}
              >
                <p style={{ textAlign: "center" }}>{cat === "SCREEN" ? "SCREEN & TV" : cat}</p>
              </div>
            ))}
          </section>

          {displayedProductGroups.map(
            (pg) =>
              pg.source === "FLEET" && (
                <ProductGroupTable
                  product_group={pg}
                  openProductGroupModal={() => openProductGroupModal(pg)}
                  openProductModal={openProductModal}
                  all_product_groups={displayedProductGroups}
                  getProductGroups={fetchProductGroups}
                  key={pg.id}
                  currentCurrency={currentCurrency}
                />
              ),
          )}
        </Layout>

        <ProductGroupModal
          product_group={currentEditingProductGroup}
          open={productGroupModalOpen}
          onCloseModal={() => setProductGroupModalOpen(false)}
          onSaveModal={onSaveProductGroupModal}
          fetchProductGroups={fetchProductGroups}
        />

        <ProductModal
          product_group={currentEditingProductGroup}
          allProductGroups={allProductGroups}
          product={currentEditingProduct}
          open={productModalOpen}
          onCloseModal={() => setProductModalOpen(false)}
          onSaveModal={onSaveProductModal}
          reloadPage={fetchProductGroups}
        />

        <BannerModal
          banner={banner}
          open={bannerModalOpen}
          onCloseModal={() => setBannerModalOpen(false)}
          onSaveModal={onSaveBannerModal}
        />
      </>
    )
  );
};

export default Products;
