import React from "react";
import { Button, Checkbox, Popover, Select, Text } from "@fleet.co/tarmac";
import { COUNTRIES_LIST } from "src/common/i18n-consts";

import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { orderStatus } from "../../data/orderStatuses";

const OrderFilterMenu = (props) => {
  const { applyFilters, filters, resetFilters, filterCount } = props;

  const toggleCountry = (country) => {
    if (filters.company_country.includes(country)) {
      setFilter(
        "company_country",
        filters.company_country.filter((c) => c !== country),
      );
    } else {
      setFilter("company_country", [...filters.company_country, country]);
    }
  };

  const setFilter = (filter_name, filter_value) => {
    const current_filters = { ...filters };

    current_filters[filter_name] = filter_value;
    applyFilters(current_filters);
  };

  return (
    <Popover
      AnchorComponent={Button}
      anchorProps={{
        variant: "outlined",
        label: `Filters (${filterCount})`,
        icon: faFilter,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div>
        <div>
          <Text variant="body2">Country</Text>

          {COUNTRIES_LIST.map((country) => (
            <Checkbox
              key={country}
              label={country}
              checked={(filters.company_country || []).includes(country)}
              onChange={() => toggleCountry(country)}
            />
          ))}
        </div>

        <Select
          multiple
          label="Filter by status"
          value={filters.status}
          options={orderStatus}
          onChange={(value) => setFilter("status", value)}
        />
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={resetFilters}
          label="Reset all filters"
          sx={{ visibility: filterCount > 0 ? "visible" : "hidden" }}
        />
      </div>
    </Popover>
  );
};

export default OrderFilterMenu;
