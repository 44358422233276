import { useState } from "react";
import { Button, Heading, Layout, TabItem, Tabs } from "@fleet.co/tarmac";
import ContractDecision from "../tables/renewal/ContractDecision";
import ContractReturnCSM from "../tables/renewal/ContractReturnCSM";
import DevicePenaltyCSM from "../tables/renewalFinance/DevicePenaltyCSM";
import RenewalImportModal from "../blocks/RenewalImportModal";

const tabs = ["Contract Decision", "Return", "Penalty"];

const Renewal = () => {
  const [selectedTab, setSelectedTab] = useState("Contract Decision");
  const [isMassSelectEnabled, setIsMassSelectEnabled] = useState(false);
  const [importModal, setImportModal] = useState(false);

  return (
    <Layout direction="column">
      <RenewalImportModal open={importModal} onClose={() => setImportModal(false)} />
      <Layout direction="column" spacing={2}>
        <Layout direction="column" spacing={2}>
          <Layout direction="row" spacing={2} justifyContent="space-between">
            <Heading variant="h2">End of life</Heading>
            <Layout direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                label="Mass Import"
                onClick={() => setImportModal(!importModal)}
              />

              {selectedTab !== "Return" && (
                <Button
                  variant="outlined"
                  color="secondary"
                  label="Mass Select"
                  onClick={() => setIsMassSelectEnabled(!isMassSelectEnabled)}
                />
              )}
            </Layout>
          </Layout>
          <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
            {tabs.map((option) => (
              <TabItem key={option} label={option} value={option} />
            ))}
          </Tabs>
        </Layout>
        {selectedTab === "Contract Decision" && <ContractDecision isMassSelectEnabled={isMassSelectEnabled} />}
        {selectedTab === "Return" && <ContractReturnCSM />}
        {selectedTab === "Penalty" && <DevicePenaltyCSM isMassSelectEnabled={isMassSelectEnabled} />}
      </Layout>
    </Layout>
  );
};

export default Renewal;
