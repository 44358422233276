import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../tools/UserContext";
import CompanyFileRow from "../../tables/company/CompanyFileRow";
import FileUploadModal from "../../blocks/FileUploadModal";
import { downloadFileBlob } from "../../../helpers/FilesHelpers";
import CompanyContext from "../../../contexts/CompanyContext";

const CompanyFiles = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { showFileUpload, closeUpload } = props;

  const [files, setFiles] = useState([]);

  const num_files = files.length > 1 ? `${files.length} files found` : `${files.length} file found`;

  const companyDocTypes = [
    "BALANCE_SHEET",
    "KBIS",
    "SIGNATORY_ID",
    "SIGNATORY_ID_VERSO",
    "IBAN",
    "SIGNING_AUTHORITY",
    "INSURANCE",
  ];

  const uploadFileTypes = [
    {
      label: "Balance Sheet",
      value: "BALANCE_SHEET",
    },
    {
      label: "KBIS",
      value: "KBIS",
    },
    {
      label: "Signatory ID",
      value: "SIGNATORY_ID",
    },
    {
      label: "Signatory ID Verso",
      value: "SIGNATORY_ID_VERSO",
    },
    {
      label: "IBAN",
      value: "IBAN",
    },
    {
      label: "Signing Authority",
      value: "SIGNING_AUTHORITY",
    },
    {
      label: "Insurance",
      value: "INSURANCE",
    },
  ];

  const loadFiles = async () => {
    const fetchedFiles = await adminUser.api.getFiles(`?company_id=${company.id}`);
    const filesToDisplay = fetchedFiles.data.filter((f) => companyDocTypes.includes(f.type));

    setFiles(filesToDisplay);
  };

  const removeFile = async (file_id) => {
    await adminUser.api.deleteFile(file_id);
    loadFiles();
  };

  const openFile = async (file) => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, file.original_name || file.url);
  };

  const onSaveModal = async (fileData) => {
    fileData.company_id = company.id;
    const formData = new FormData();

    for (const [key, value] of Object.entries(fileData)) {
      if (value !== null) {
        formData.append(key, value);
      }
    }
    await adminUser.api.addFile(formData);
    closeUpload();
    loadFiles();
  };

  const onChangeUser = async (fileId, userId) => {
    await adminUser.api.modifyFile(fileId, { user_id: userId });
    loadFiles();
  };

  useEffect(() => {
    if (company.id === "") return;
    loadFiles();
  }, [company]);

  return (
    <>
      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "15%" }}>File Type</td>
            <td style={{ width: "35%" }}>File Name</td>
            <td style={{ width: "10%" }}>Status</td>
            <td style={{ width: "25%" }}>User</td>
            <td style={{ width: "15%" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {files.map((f) => (
            <CompanyFileRow
              file={f}
              key={`${company.id}-${f.id}`}
              deleteAction={() => removeFile(f.id)}
              openFile={() => openFile(f)}
              onChangeUser={onChangeUser}
              reload={loadFiles}
            />
          ))}
        </tbody>
      </table>
      <p className="table-count"> {num_files}</p>

      <FileUploadModal
        onClose={closeUpload}
        onSave={onSaveModal}
        companyId={company.id}
        uploadFileTypes={uploadFileTypes}
        canAssignUser
        open={showFileUpload}
      />
    </>
  );
};

export default CompanyFiles;
