import React, { useCallback, useContext, useEffect, useState } from "react";
import { faChevronDown, faChevronUp, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { Button, Checkbox, Icon, Layout, TextField } from "@fleet.co/tarmac";
import qs from "qs";
import styles from "./ContractEndingCSM.module.scss";
import usePersistedState from "../../../hooks/usePersistedState";
import UserContext from "../../../tools/UserContext";
import ContractDecisionRow from "./ContractDecisionRow";
import RenewalReminderConfirmation from "./RenewalReminderConfirmation";
import LoadingSpinner from "../../common/LoadingSpinner";
import ContractDecisionFilterMenu from "../../blocks/ContractDecisionFilterMenu";

const initialFilters = {
  status: "ALL",
  minDate: null,
  maxDate: null,
  searchCompanyNameSerialNumber: "",
  deviceRenewalType: [],
};

const ContractDecision = (props) => {
  const { isMassSelectEnabled } = props;
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [dateSorting, setDateSorting] = useState("asc");
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [filters, setFilters] = usePersistedState("renewal_contract_decisions", { ...initialFilters });
  const [isSending, setIsSending] = useState(false);

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    let endpointQuery = "";
    const params = {};

    params.order_dir = dateSorting;
    params.order_by = "contract_start_date";
    params.orderStatus = ["CLOSED"];
    params.number_exclude = 0;

    if (filters.status !== "ALL") {
      params.renewalDecision = filters.status === "DONE";
    }

    if (filters.status === "EXPIRE_SOON") {
      const today = new Date();
      const in3Monhts = today.setMonth(today.getMonth() + 3);

      params.expireBefore = new Date(in3Monhts).toISOString();
    }

    if (filters.status === "EXTENDED") {
      params.expired = true;
    }

    if (filters.minDate) {
      params.contractEndMinDate = new Date(filters.minDate);
    }

    if (filters.maxDate) {
      params.contractEndMaxDate = new Date(filters.maxDate);
    }

    if (filters.searchCompanyNameSerialNumber !== "") {
      params.search_company_name_serial_number = filters.searchCompanyNameSerialNumber;
    }

    if (filters.deviceRenewalType.length > 0) {
      params.devicesRenewalType = filters.deviceRenewalType;
    }

    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery}?${qs.stringify(params)}`;
    }
    const fetchedOrders = await adminUser.api.getOrders(endpointQuery);

    setOrders(fetchedOrders.data);
    setIsLoading(false);
  }, [adminUser.api, dateSorting, filters]);

  const handleSort = useCallback(() => {
    if (dateSorting === "asc") {
      setDateSorting("desc");
    } else {
      setDateSorting("asc");
    }
  }, [dateSorting]);

  const selectForMassEdit = useCallback(
    (d) => {
      const selectedOrdersCopy = [...selectedOrders];
      const idx = selectedOrdersCopy.indexOf(d);

      if (idx === -1) {
        selectedOrdersCopy.push(d);
      } else {
        selectedOrdersCopy.splice(idx, 1);
      }
      setSelectedOrders(selectedOrdersCopy);
    },
    [selectedOrders],
  );

  const handleSelectAll = useCallback(() => {
    if (selectedOrders.length > 0) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders);
    }
  }, [orders, selectedOrders.length]);

  const sendReminders = useCallback(async () => {
    setIsSending(true);
    for (const order of selectedOrders) {
      await adminUser.api.sendRenewalReminder(order.id);
    }
    setSelectedOrders([]);
    setIsSending(false);
    setIsConfirmModalOpen(false);
    fetchOrders();
  }, [adminUser.api, fetchOrders, selectedOrders]);

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  useEffect(() => {
    if (!isMassSelectEnabled) {
      setSelectedOrders([]);
    }
  }, [isMassSelectEnabled]);

  useEffect(() => {
    fetchOrders();
  }, [dateSorting, fetchOrders, filters]);

  return (
    <>
      <Layout direction="column" spacing={2}>
        {isMassSelectEnabled && (
          <Layout direction="row" spacing={1} className={styles.massActionsRow}>
            <Checkbox label="Select all" checked={selectedOrders.length === orders.length} onChange={handleSelectAll} />
            <Button
              variant="text"
              color="secondary"
              label="Send reminder"
              startIcon={faEnvelope}
              onClick={() => setIsConfirmModalOpen(true)}
              disabled={selectedOrders.length === 0}
            />
          </Layout>
        )}
        {!isMassSelectEnabled && (
          <Layout direction="row" spacing={1} justifyContent="flex-end">
            <TextField
              type="search"
              label="Company, Serial Number..."
              value={filters.searchCompanyNameSerialNumber}
              onChange={(e) => setFilters({ ...filters, searchCompanyNameSerialNumber: e.target.value })}
            />
            <ContractDecisionFilterMenu setFilters={setFilters} resetFilters={resetFilters} filters={filters} />
          </Layout>
        )}

        <Layout direction="row" className={styles.tableHead}>
          <div className={styles.littleCell} />
          <div>Contract ID</div>
          <div>Start Date</div>
          <div>End Date</div>
          <Layout direction="row" className={styles.pointer} onClick={handleSort} spacing={1}>
            <div> Time left</div>{" "}
            <Icon icon={dateSorting === "desc" ? faChevronDown : faChevronUp} color="secondary" size="S" />
          </Layout>
          <div>Company</div>
          <div>Contract Status</div>
          <div>Decision date</div>
          <div />
        </Layout>

        <Layout direction="column">
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            orders.map((o) => (
              <ContractDecisionRow
                order={o}
                key={o.id}
                reloadOrders={fetchOrders}
                selectForMassEdit={() => isMassSelectEnabled && selectForMassEdit(o)}
                isMassSelectEnabled={isMassSelectEnabled}
                isSelected={selectedOrders.includes(o)}
              />
            ))}
        </Layout>
      </Layout>
      <RenewalReminderConfirmation
        closeModal={() => setIsConfirmModalOpen(false)}
        numberOfMail={selectedOrders.length}
        isLoading={isSending}
        confirmAction={sendReminders}
        open={isConfirmModalOpen}
      />
    </>
  );
};

export default ContractDecision;
