import React from "react";
import { Layout, Text } from "@fleet.co/tarmac";
import FormatDate from "../../../../tools/FormatDate";
import { dateDiff } from "../../../../tools/DateUtils";

const DeliveryDateCell = ({ row: device }) => (
  <Layout direction="column">
    {!device.delivery_date && device.expected_delivery_date && <>ETA </>}
    <FormatDate date={device.delivery_date ? device.delivery_date : device.expected_delivery_date} />
    <DaysLeft device={device} />
  </Layout>
);

export default DeliveryDateCell;

const DaysLeft = ({ device }) => {
  if (device.delivery_date && (device.expected_delivery_date || device.promised_delivery_date)) {
    const numDays = dateDiff(device.expected_delivery_date || device.promised_delivery_date, device.delivery_date);
    const numDaysText = numDays + (Math.abs(numDays) > 1 ? " days" : " day");

    if (numDays <= 0) {
      return (
        <Text variant="body2" color="error">
          {numDaysText}
        </Text>
      );
    }

    return <Text variant="body2">{numDaysText}</Text>;
  }

  if (!device.delivery_date && (device.expected_delivery_date || device.promised_delivery_date)) {
    const numDays = dateDiff(device.expected_delivery_date || device.promised_delivery_date);
    const numDaysText = numDays + (Math.abs(numDays) > 1 ? " days" : " day");

    if (numDays <= 0) {
      return (
        <Text variant="body2" color="error">
          {numDaysText}
        </Text>
      );
    }

    return <Text variant="body2">{numDaysText}</Text>;
  }

  return null;
};
