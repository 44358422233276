import { Button, Dialog, Layout, Separator, Text, theme } from "@fleet.co/tarmac";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSuspenseQuery, useQueryClient } from "@tanstack/react-query";
import { COUNTRY_CURRENCY_MAPPING, CURRENCIES } from "src/common/i18n-consts";
import UserContext from "../../../tools/UserContext";

import styles from "./CompanyDiscount.module.scss";

import { useToastContext } from "../../../contexts/ToastContext";
import DiscountTableMdmRow from "./DiscountTable/DiscountTableMdmRow";
import DiscountTableRow from "./DiscountTable/DiscountTableRow";
import { companyQueries } from "./queries/companyQueries";
import { EditButton } from "./utils/EditButton";

const productDiscountOptions = [
  { level: 1, min: "0", max: "19.90" },
  { level: 2, min: "19.91", max: "49.90" },
  { level: 3, min: "49.91", max: "79.90" },
  { level: 4, min: "79.91", max: "99.90" },
  { level: 5, min: "99.91", max: "119.90" },
  { level: 6, min: "119.91", max: "129.90" },
  { level: 7, min: "129.91", max: "149.90" },
  { level: 8, min: "149.91", max: "199.90" },
  { level: 9, min: "199.91", max: null },
];

const mdmDiscountOptions = [
  { title: "Free trial", sub: "default: 1 month", type: "number" },
  { title: "Price/month/device", sub: "default: 6.90€/£/$" },
];

const CompanyDiscountInfo = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const companyQuery = companyQueries.detail(Number(id));
  const { data: company } = useSuspenseQuery(companyQuery);
  const { user: adminUser } = useContext(UserContext);
  const [isLeasingEditable, setIsLeasingEditable] = useState(false);
  const [isMdmEditable, setIsMdmEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mdmChangeDiscount, setMdmChangeDiscount] = useState({
    mdm_trial_offer: null,
    mdm_unit_amount: null,
  });
  const { addToast } = useToastContext();

  useEffect(() => {
    if (company) {
      setMdmChangeDiscount({
        mdm_trial_offer: company.mdm_trial_offer,
        mdm_unit_amount: company.mdm_unit_amount,
      });
    }
  }, [company]);

  const handleChange = (level, discountInfo) => {
    const chosenLevelInfos = productDiscountOptions.find((pdo) => pdo.level === level);
    const updatingDiscount = discounts.find((d) => d.min_price === chosenLevelInfos.min);

    if (updatingDiscount) {
      const updatedDiscounts = discounts.map((d) => {
        if (d.min_price === updatingDiscount.min_price) {
          return {
            ...d,
            offer_type: discountInfo.offer_type,
            offer_value: discountInfo.offer_value,
          };
        }

        return d;
      });

      setDiscounts(updatedDiscounts);
    } else {
      const newDiscount = {
        min_price: chosenLevelInfos.min,
        max_price: chosenLevelInfos.max,
        offer_type: discountInfo.offer_type,
        offer_value: discountInfo.offer_value,
      };

      setDiscounts([...discounts, newDiscount]);
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    setIsModalOpen(true);
  };

  const handleLeasingDiscountSave = async () => {
    if (!isLoading) {
      setIsLoading(true);

      const discountsToCreate = discounts.filter((discount) => !discount.id);
      const discountsToModify = discounts.filter((discount) =>
        company.product_discounts.find((d) => {
          const hasSameId = d.id === discount.id;
          const hasNotSameOfferType = d.offer_type !== discount.offer_type;
          const hasNotSameOfferValue = d.offer_value !== discount.offer_value;

          return hasSameId && (hasNotSameOfferType || hasNotSameOfferValue);
        }),
      );

      for (const discount of discountsToModify) {
        await adminUser.api.modifyCompanyDiscount(company.id, discount);
      }

      for (const discount of discountsToCreate) {
        await adminUser.api.addCompanyDiscount(company.id, discount);
      }
    }
    setIsLeasingEditable(false);
    queryClient.invalidateQueries(companyQuery);
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const handleMdmDiscountSave = async () => {
    if (mdmChangeDiscount.mdm_unit_amount < 490 || mdmChangeDiscount.mdm_unit_amount > 1290) {
      addToast("The price can only be between: 4.90 and 12.90€/£/$(HT)");

      return;
    }

    setIsLoading(true);
    await adminUser.api.modifyCompany(company.id, mdmChangeDiscount);
    setIsMdmEditable(false);
    queryClient.invalidateQueries(companyQuery);
    setIsLoading(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    // deep copy because we copy array of objects that is a key value of an object
    const newDiscounts = company.product_discounts.map((d) => ({ ...d }));

    setDiscounts(newDiscounts);
  }, [company]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setIsModalOpen(false)} />
      <Button
        color="primary"
        label={isLoading ? "Loading" : "Save"}
        onClick={isMdmEditable ? handleMdmDiscountSave : handleLeasingDiscountSave}
      />
    </>
  );

  const discountCurrency = COUNTRY_CURRENCY_MAPPING[company?.country] ?? CURRENCIES.EUR;

  return (
    <Layout
      sx={{
        marginTop: 3,
        borderRadius: 1,
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.grey[50],
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
      }}
      divider={<Separator orientation="horizontal" />}
    >
      <Layout sx={{ padding: 3 }}>
        <Text variant="h5" bold>
          Discount Management
        </Text>
      </Layout>
      <Layout
        direction="row"
        justifyContent="space-around"
        divider={<Separator orientation="vertical" />}
        sx={{ padding: 3 }}
        spacing={2}
      >
        <form onSubmit={handleSubmit} style={{ flex: 1 }}>
          <Layout direction="column" spacing={2}>
            <Layout direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="h5">Leasing discount</Text>
              <EditButton
                onEdit={() => {
                  const newDiscounts = company.product_discounts.map((d) => ({ ...d }));

                  setDiscounts(newDiscounts);
                  setIsLeasingEditable(true);
                }}
                onCancel={() => setIsLeasingEditable(false)}
                isEditing={isLeasingEditable}
              />
            </Layout>

            <Layout direction="column">
              {productDiscountOptions.map((productDiscountInfo, index) => (
                <DiscountTableRow
                  key={index}
                  discountInfos={productDiscountInfo}
                  discount={discounts?.find((d) => d.min_price === productDiscountInfo.min)}
                  isEditable={isLeasingEditable}
                  handleChange={handleChange}
                  discountCurrency={discountCurrency}
                />
              ))}
            </Layout>
          </Layout>
        </form>

        <form onSubmit={handleSubmit} style={{ flex: 1 }}>
          <Layout direction="column" spacing={2}>
            <Layout direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="h5">MDM Discount</Text>
              <EditButton
                onEdit={() => setIsMdmEditable(true)}
                onCancel={() => setIsMdmEditable(false)}
                isEditing={isMdmEditable}
              />
            </Layout>

            <Layout direction="column">
              <Text variant="body2" bold>
                No subscription
              </Text>
              <Text variant="body2">Price can be between: 4.90 and 12.90€/£/$(Excluding taxes)</Text>
            </Layout>
            <Layout direction="row" fullWidth justifyContent="space-between" alignItems="center">
              <Text className={styles.cellHeader}>Discount type</Text>
              <Text variant="body1">Discount</Text>
            </Layout>

            <Layout direction="column">
              {mdmDiscountOptions.map((mdmDiscountInfo) => (
                <DiscountTableMdmRow
                  key={mdmDiscountInfo.title}
                  discountInfos={mdmDiscountInfo}
                  isEditable={isMdmEditable}
                  mdmChangeDiscount={mdmChangeDiscount}
                  setMdmChangeDiscount={setMdmChangeDiscount}
                />
              ))}
            </Layout>
          </Layout>
        </form>
      </Layout>
      <Dialog
        title={isMdmEditable ? "MDM Discount modifications" : "Discount modifications"}
        onClose={() => setIsModalOpen(false)}
        Actions={ActionComponent}
        open={isModalOpen}
      >
        <Text variant="body1">The previous given discounts will be modified and deleted after pressing save.</Text>
      </Dialog>
    </Layout>
  );
};

export default CompanyDiscountInfo;
