import { TextField } from "@fleet.co/tarmac";
import { Label } from "./Label";

export function EditField({
  isEditing,
  value,
  onChange,
  name,
  copyable = false,
}: {
  isEditing: boolean;
  name: string;
  value: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  copyable?: boolean;
}) {
  return (
    <>
      <TextField name={name} value={value ?? ""} onChange={onChange} sx={{ display: isEditing ? "block" : "none" }} />
      <Label value={value} copyable={copyable} sx={{ display: isEditing ? "none" : "flex" }} />
    </>
  );
}
