import { Layout } from "@fleet.co/tarmac";
import LogisticsTable from "../tables/logistics/LogisticsTable";
import { LogisticsProvider } from "../../contexts/LogisticsContext";
import LogisticsHeader from "../sections/logistics/LogisticsHeader";

const Logistics = () => (
  <LogisticsProvider>
    <Layout direction="column" gap={2}>
      <LogisticsHeader />
      <LogisticsTable />
    </Layout>
  </LogisticsProvider>
);

export default Logistics;
