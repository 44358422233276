import React from "react";
import { Link } from "react-router";
import { Link as FleetLink, Layout, Text } from "@fleet.co/tarmac";

// takes only a fields props, this props is a list of {name, link}
// the breadcrumb is clickable if it posses a link field in the object
const Breadcrumb = ({ nameAndLinkArray }) => (
  <Layout direction="row" spacing={0.5}>
    {nameAndLinkArray.map((e, idx) => {
      const isNotLast = idx !== nameAndLinkArray.length - 1;

      return (
        <React.Fragment key={idx}>
          {e.link ? (
            <Link to={e.link}>
              <FleetLink variant="body2" label={e.name} color="info" />
            </Link>
          ) : (
            <Text variant="body2">{e.name}</Text>
          )}
          {isNotLast && <Text>/</Text>}
        </React.Fragment>
      );
    })}
  </Layout>
);

export default Breadcrumb;
