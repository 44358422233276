import React, { useMemo } from "react";
import { Layout, Text, Tooltip } from "@fleet.co/tarmac";
import { keyboardLayouts } from "../../../../data/keyboardLayouts";

const KeyboardLayoutCell = ({ row: device }) => {
  const keyboardLogo = useMemo(() => {
    let keyboardLanguage = keyboardLayouts.find((kb) => device.keyboard_layout === kb.label);

    keyboardLanguage = keyboardLanguage || keyboardLayouts[1];

    if (keyboardLanguage.emoji) {
      return <Text>{keyboardLanguage.emoji}</Text>;
    }

    if (keyboardLanguage.imgUrl) {
      return <img src={`/images/flags/${keyboardLanguage.imgUrl}`} alt={keyboardLanguage.shortName} />;
    }

    return keyboardLanguage.label;
  }, [device.keyboard_layout]);

  return (
    <Layout direction="column">
      {["COMPUTER", "KEYBOARD"].includes(device.category) ? (
        <Tooltip title={device.keyboard_layout}>
          <div>{keyboardLogo}</div>
        </Tooltip>
      ) : (
        "-"
      )}
    </Layout>
  );
};

export default KeyboardLayoutCell;
