const getMonthDifference = (dateFrom, dateTo) =>
  dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());

const getDaysLeft = (day1, day2) => {
  const millisecondsPerDays = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(day1.getFullYear(), day1.getMonth(), day1.getDate());
  const utc2 = Date.UTC(day2.getFullYear(), day2.getMonth(), day2.getDate());

  return Math.floor((utc2 - utc1) / millisecondsPerDays);
};

const dateDiff = (end, current = new Date()) => {
  const current_date = new Date(current);
  const end_date = new Date(end);

  current_date.setUTCHours(0, 0, 0, 0);
  end_date.setUTCHours(0, 0, 0, 0);

  const numberOfMilisecondsInADay = 24 * 60 * 60 * 1000;
  const timeDiff = end_date.getTime() - current_date.getTime();

  return (timeDiff / numberOfMilisecondsInADay + 1).toFixed(0);
};

export { getDaysLeft, getMonthDifference, dateDiff };
