import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { useToastContext } from "src/contexts/ToastContext";
import qs from "qs";
import { Badge, Button, Icon, Layout, TabItem, Tabs, Text } from "@fleet.co/tarmac";
import {
  faFileAlt,
  faFileInvoiceDollar,
  faHandshake,
  faLaptop,
  faShoppingCart,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../tools/UserContext";
import OrderGeneralInfo from "../sections/order/OrderGeneralInfo";
import { sortLeaserScoringOrder } from "../../tools/OrderTools";
import Breadcrumb from "../common/Breadcrumb";
import DeviceTable from "../tables/devices/DeviceTable";
import AddDevice from "../sections/order/devices/AddDevice";
import OrderFiles from "../sections/order/OrderFiles";
import OrderBilling from "../sections/order/OrderBilling";
import OrderLeaser from "../sections/order/OrderLeaser";
import NewReferenceModal from "./NewReferenceModal";

const OrderInfo = () => {
  const { id } = useParams();
  const { user: adminUser } = useContext(UserContext);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [order, setOrder] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToastContext();

  const navigate = useNavigate();
  const uploadFileTypes = [
    "CONTRACT",
    "FLEET_INSURANCE",
    "FLEET_INSURANCE_INVOICE",
    "DELIVERY_RECEIPT",
    "SHIPPING_NOTICE",
    "ORDER_FORM_SIGNED",
    "SEPA_MANDATE",
  ];

  const handleClose = () => {
    setIsOpen(false);
    navigate(location.pathname);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "net_revenue") {
      // also update Leaser Rate
      const new_leaser_rate = ((100 * order.rent) / parseFloat(value)).toFixed(6);

      setOrder((prevInfo) => ({ ...prevInfo, leaser_rate: new_leaser_rate }));
    }
    setOrder((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const loadOrder = useCallback(async () => {
    let endpointQuery = "";
    const params = { leaserScoring: true, kycScoring: true };

    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery + id}?${qs.stringify(params)}`;
    }
    const fetchedOrder = await adminUser.api.getOrder(endpointQuery);

    fetchedOrder.data = sortLeaserScoringOrder(fetchedOrder.data);
    setOrder(fetchedOrder.data);

    return fetchedOrder.data;
  }, [adminUser.api, id]);

  const updateOrder = (data) => {
    setOrder(data);
  };

  const saveOrder = async () => {
    // Make a copy
    const order_flat = { ...order };

    // Remove all objects to onley keep key/val props
    Object.keys(order_flat).map((k) => {
      if (order_flat[k] instanceof Object || order_flat[k] instanceof Array) {
        delete order_flat[k];
      }

      return null;
    });
    await adminUser.api.modifyOrder(order.id, order_flat);
    await loadOrder();
    setMessage("Order sucessfully updated");
  };

  const closeDeleteError = () => {
    setErrors([]);
  };

  const addNewReference = () => {
    if (order.status !== "UNQUALIFIED_LEAD") {
      addToast("New references cannot be added at this order stage", "warning");
      return ;
    }
      
    setIsOpen(true);
  };
  const displaySideButton = () => {
    if (location.pathname === `/orders/${order.id}`) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          label="View Hubspot Page"
          href={`https://app.hubspot.com/contacts/${import.meta.env.REACT_APP_HUBSPOT_PORTALID}/deal/${order.hubspot_id}`}
          targetBlank
        />
      );
    }

    if (location.pathname.startsWith(`/orders/${order.id}/devices`)) {
      return (
        <Layout direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            label="Add New Reference"
            onClick={() => addNewReference()}
          />

          <Button
            variant="contained"
            color="primary"
            size="small"
            label="Add New Device"
            href={`/orders/${order.id}/device_add`}
          />
        </Layout>
      );
    }

    if (location.pathname.startsWith(`/orders/${order.id}/files`)) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          label="Add New File"
          disabled={showFileUpload}
          onClick={() => setShowFileUpload(true)}
        />
      );
    }

    return null;
  };

  const displayButtons = () => {
    if (location.pathname.startsWith(`/orders/${order.id}/devices/`)) {
      return <li className="isActive">{location.state ? location.state.deviceName : ""}</li>;
    }

    if (location.pathname.startsWith(`/orders/${order.id}/device_add`)) {
      return <li className="isActive">New Device</li>;
    }

    return null;
  };

  // returns a list of {name, link} objects, that are used in the Breadcrumb component
  const generateBC = () => {
    const companyDetailsUrl = `/companies/${order.company.id}`;

    const breadcrumb_elements = [{ name: "Companies" }, { name: order.company.name, link: companyDetailsUrl }];

    if (location.pathname.endsWith(`/orders/${order.id}`)) {
      breadcrumb_elements.push({ name: `Order ${order.order_number || "-"}` });

      return breadcrumb_elements;
    }

    breadcrumb_elements.push({
      name: `Order ${order.order_number || "-"}`,
      link: `/orders/${order.id}`,
    });

    // Not main order page
    if (location.pathname.endsWith("devices")) {
      breadcrumb_elements.push({ name: "Devices" });
    } else if (location.pathname.endsWith("files")) {
      breadcrumb_elements.push({ name: "Files" });
    } else if (location.pathname.endsWith("leaser")) {
      breadcrumb_elements.push({ name: "Leaser" });
    } else if (location.pathname.endsWith("billing")) {
      breadcrumb_elements.push({ name: "Invocing" });
    }

    return breadcrumb_elements;
  };

  const generalLink = `/orders/${id}`;

  const tabLinks = useMemo(
    () => [
      {
        label: "Order Details",
        value: generalLink,
        icon: faShoppingCart,
      },
      {
        label: "Devices",
        value: `${generalLink}/devices`,
        icon: faLaptop,
        badgeValue: order?.devices.length,
      },
      {
        label: "Files",
        value: `${generalLink}/files`,
        icon: faFileAlt,
        badgeValue: order?.files.filter((file) => uploadFileTypes.includes(file?.file_type)).length,
      },
      {
        label: "Leaser",
        value: `${generalLink}/leaser`,
        icon: faHandshake,
      },
      {
        label: "Invocing",
        value: `${generalLink}/billing`,
        icon: faFileInvoiceDollar,
      },
    ],
    [generalLink, order?.devices.length, order?.files, uploadFileTypes],
  );

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(tabLinks[newValue].value);
  };

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    loadOrder();
    setCurrentTab(tabLinks.findIndex((tab) => tab.value === location.pathname));
  }, [location]);

  return (
    order && (
      <Layout direction="column" spacing={2}>
        <Breadcrumb nameAndLinkArray={generateBC()}>{displayButtons()}</Breadcrumb>
        <Layout direction="row" justifyContent="space-between">
          <Tabs value={currentTab} onChange={handleTabChange}>
            {tabLinks.map((tab) => (
              <TabItem
                key={tab.value}
                label={
                  <Layout direction="row" spacing={1}>
                    <Text variant="body2">{tab.label}</Text>
                    {tab.badgeValue ? (
                      <Badge color="green" badgeContent={tab.badgeValue}>
                        <Icon icon={tab.icon} />
                      </Badge>
                    ) : (
                      <Icon icon={tab.icon} />
                    )}
                  </Layout>
                }
              />
            ))}
          </Tabs>
          {displaySideButton()}
          <NewReferenceModal isOpen={isOpen} handleClose={handleClose} order={order} />
        </Layout>
        <Routes>
          <Route
            path="/"
            element={
              <OrderGeneralInfo
                order={order}
                updateOrder={updateOrder}
                loadOrder={loadOrder}
                saveOrder={saveOrder}
                handleChange={handleChange}
                message={message}
                errors={errors}
                clear={closeDeleteError}
                setErrors={setErrors}
              />
            }
          />
          <Route
            path="/devices"
            element={<DeviceTable isOrderInfo={1} devices={order.devices.map((e) => ({ ...e, order }))} reloadPage={loadOrder} />}
          />
          <Route path="/device_add" element={<AddDevice order={order} reloadOrder={loadOrder} />} />
          <Route
            path="/files"
            element={
              <OrderFiles
                order={order}
                reloadOrder={loadOrder}
                showFileUpload={showFileUpload}
                closeUpload={() => setShowFileUpload(false)}
              />
            }
          />
          <Route path="/billing" element={<OrderBilling order={order} reload={loadOrder} />} />
          <Route path="/leaser" element={<OrderLeaser order={order} reload={loadOrder} />} />
        </Routes>
      </Layout>
    )
  );
};

export default OrderInfo;
