// Make sure carrier doesn't require account number
// https://www.aftership.com/docs/tracking/enum/additional-tracking-fields
export const carriers = [
  { value: "chronopost-france", label: "Chronopost France" },
  { value: "colis-prive", label: "Colis Privé" },
  { value: "colissimo", label: "Colissimo" },
  { value: "dhl", label: "DHL Express" },
  { value: "dpd", label: "DPD Global" },
  { value: "dpd", label: "DPD France" },
  { value: "exapaq", label: "DPD France (formerly exapaq)" },
  { value: "fedex", label: "FedEx®" },
  { value: "gls-spain", label: "GLS Spain" },
  { value: "la-poste-colissimo", label: "La Poste (Colissimo)" },
  { value: "mondialrelay", label: "Mondial Relay" },
  { value: "nacex-spain", label: "Nacex SPAIN" },
  { value: "relaiscolis", label: "Relais Colis" }, // Must connect (?)
  { value: "spanish-seur", label: "Spanish Seur" },
  { value: "tnt-fr", label: "TNT France" },
  { value: "usps", label: "USPS" },
  { value: "ups", label: "UPS" },
  { value: "zeleris", label: "Zeleris" },
];
