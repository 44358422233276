import React from "react";
import { Select } from "@fleet.co/tarmac";

const StatusCustomFilter = ({ onChange, value }) => {
  const options = [
    { label: "Default", value: null },
    { label: "Only Validated", value: "validated" },
    { label: "Only Qualified Leads", value: "qualified_lead" },
    { label: "Show Unsubmited Carts", value: "carts" },
    { label: "Only Failed", value: "failed" },
  ];

  return <Select options={options} onChange={onChange} value={value} />;
};

export default StatusCustomFilter;
