import { Layout, Link, Separator, Tag, Text, theme } from "@fleet.co/tarmac";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import { formatMoney } from "src/helpers/formatHelpers";
import type { Company } from "src/types/Company.type";
import moment from "moment";
import type { Order } from "src/types/Order.type";
import type { Device } from "src/types/Device.type";
import { companyQueries, useCompanyMutation } from "./queries/companyQueries";
import { EditButton } from "./utils/EditButton";
import { EditField } from "./utils/EditField";
import { Label } from "./utils/Label";

const getCompanyNumber = (company: Company) => {
  switch (company.country) {
    case "France":
      return company.siren;
    case "Spain":
      return company.nif;
    case "Germany":
      return company.hr;
    case "United Kingdom":
      return company.crn;
    case "United States":
      return company.ein;
    default:
      return company.registration_id;
  }
};

const getHasPersonalInsurance = (orders: Order[]) => {
  const lastOrder = orders.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))[0];
  if (!lastOrder) return false;
  return lastOrder.insurance_type === "PERSONAL_INSURANCE";
};

const getOrderHasRunningFleetInsurance = (order: Order, devices: Device[]) => {
  const correspondingDevices = devices.filter((device) => device.order_id === order.id);
  if (!correspondingDevices || correspondingDevices.length === 0) return false;
  return (
    correspondingDevices.every((device) => device.insurance_offer?.status === "VALIDATED") &&
    order.insurance_type === "FLEET_INSURANCE"
  );
};

export function CompanyId() {
  const { id } = useParams<{ id: string }>();
  const { data: company } = useSuspenseQuery(companyQueries.detail(Number(id)));
  const modifyCompany = useCompanyMutation(Number(id));
  const [isCockpitOverviewEditing, setIsCockpitOverviewEditing] = useState(false);

  const hqAddress = company.addresses?.find((a) => a.is_hq);

  const companyNumber = getCompanyNumber(company);

  const initialValues = {
    name: company.name,
    legal_name: company.legal_name,
    country: company.country,
    company_number: companyNumber,
    vat_number: company.tva_number,
    bnp_number: company.bnp_number,
  };

  const [values, setValues] = useState(initialValues);

  const saveCompanyId = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setIsCockpitOverviewEditing(false);
    const payload: Partial<Company> = { ...values };
    switch (company?.country) {
      case "France":
        payload.siren = values.company_number ?? "";
        break;
      case "Spain":
        payload.nif = values.company_number ?? "";
        break;
      case "Germany":
        payload.hr = values.company_number ?? "";
        break;
      case "United Kingdom":
        payload.crn = values.company_number ?? "";
        break;
      case "United States":
        payload.ein = values.company_number ?? "";
        break;
      default:
        payload.registration_id = values.company_number ?? "";
        break;
    }
    modifyCompany.mutate(payload);
  };

  const handleEdit = () => {
    setIsCockpitOverviewEditing(true);
  };

  const handleCancel = () => {
    setIsCockpitOverviewEditing(false);
    setValues(initialValues);
  };

  const realOrders = company.orders?.filter((order) => order.status !== "UNQUALIFIED_LEAD") ?? [];

  const hasPersonalInsurance = getHasPersonalInsurance(realOrders);

  const fleetCareInsuredOrders =
    realOrders.filter((order) => getOrderHasRunningFleetInsurance(order, company?.devices ?? [])) ?? [];

  const fleetCareARR =
    (company.devices?.reduce((acc, device) => {
      const offer = device.insurance_offer;
      if (offer && offer.status === "VALIDATED") {
        acc += offer.amount / 100;
      }
      return acc;
    }, 0) ?? 0) * 12;

  return (
    <form onSubmit={saveCompanyId} style={{ flex: 1 }}>
      <Layout
        direction="column"
        flex={1}
        sx={{
          borderRadius: 1,
          border: `1px solid ${theme.palette.border.secondary}`,
          backgroundColor: theme.palette.grey[50],
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        }}
        divider={<Separator orientation="horizontal" />}
        fullHeight
      >
        <Layout
          sx={{
            paddingX: 3,
            paddingY: 2,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="h5" bold>
            Company ID
          </Text>
          <EditButton onEdit={handleEdit} onCancel={handleCancel} isEditing={isCockpitOverviewEditing} />
        </Layout>

        <Layout
          direction="column"
          spacing={2}
          sx={{
            paddingX: 3,
            paddingY: 2,
          }}
        >
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text color="primary">Name</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="name"
                value={values.name}
                onChange={(ev) => setValues({ ...values, name: ev.target.value })}
              />
            </Layout>
            <Layout flex={1}>
              <Text color="primary">Legal Name</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="legal_name"
                value={values.legal_name}
                onChange={(ev) => setValues({ ...values, legal_name: ev.target.value })}
              />
            </Layout>
          </Layout>

          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text color="primary">HQ address</Text>
              {hqAddress ? (
                <Label value={`${hqAddress.address1} ${hqAddress.zip} ${hqAddress.city}`} copyable />
              ) : (
                <Text>No HQ address</Text>
              )}
            </Layout>
            <Layout flex={1}>
              <Text color="primary">Country</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="country"
                value={values.country}
                onChange={(ev) => setValues({ ...values, country: ev.target.value })}
              />
            </Layout>
          </Layout>
        </Layout>

        <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text color="primary">Company number</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="company_number"
                value={values.company_number}
                onChange={(ev) => setValues({ ...values, company_number: ev.target.value })}
                copyable
              />
            </Layout>
          </Layout>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text color="primary">VAT Number</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="vat_number"
                value={values.vat_number}
                onChange={(ev) => setValues({ ...values, vat_number: ev.target.value })}
                copyable
              />
            </Layout>
            <Layout flex={1}>
              <Text color="primary">BNP Client number</Text>
              <EditField
                isEditing={isCockpitOverviewEditing}
                name="bnp_number"
                value={values.bnp_number}
                onChange={(ev) => setValues({ ...values, bnp_number: ev.target.value })}
                copyable
              />
            </Layout>
          </Layout>
        </Layout>

        <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text>Fleet care contracts</Text>
              <Layout direction="row" spacing={1}>
                {hasPersonalInsurance ? (
                  <Tag backgroundColor="orange" label="Personal insurance" variant="filled" size="small" />
                ) : (
                  <Text bold>
                    {fleetCareInsuredOrders?.length}/{realOrders?.length}
                  </Text>
                )}
              </Layout>
            </Layout>
            <Layout flex={1}>
              <Text>Fleet care ARR</Text>
              <Layout direction="row" spacing={1} divider={<Separator orientation="vertical" />}>
                <Text bold>{formatMoney(fleetCareARR, "EUR")}</Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Link
                href={`https://dashboard.stripe.com/${import.meta.env.DEV ? "test/" : ""}customers/${company.stripe_id}`}
                color="darkGreen"
                label="View in Stripe"
                icon={faArrowUpRightFromSquare}
                iconRight
                bold
                target="_blank"
                rel="noopener noreferrer"
              />
            </Layout>
            <Layout flex={1}>
              <Link
                href={`https://app.hubspot.com/contacts/${import.meta.env.REACT_APP_HUBSPOT_PORTALID}/company/${
                  company.hubspot_id
                }`}
                color="darkGreen"
                label="View Hubspot Page"
                icon={faArrowUpRightFromSquare}
                iconRight
                bold
                target="_blank"
                rel="noopener noreferrer"
              />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </form>
  );
}
