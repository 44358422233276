import { Layout, Text } from "@fleet.co/tarmac";
import { useParams } from "react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import { CompanyCockpitOverview } from "./CompanyCockpitOverview";
import CompanyDiscountInfo from "./CompanyDiscountInfo";
import { CompanyId } from "./CompanyId";
import { CompanyMdmManagement } from "./CompanyMdmManagement";
import { companyQueries } from "./queries/companyQueries";
import { CompanyPicture } from "./CompanyPicture";

export function CompanyGeneralInfo() {
  const { id } = useParams<{ id: string }>();
  const { data: company } = useSuspenseQuery(companyQueries.detail(Number(id)));

  return (
    <Layout>
      <Layout direction="row" sx={{ paddingTop: 1 }} alignItems="center" justifyContent="space-between">
        <Text color="darkGreen" bold>
          {company.name}
        </Text>
        <CompanyPicture company={company} />
      </Layout>

      <Layout direction="row" spacing={3} sx={{ marginTop: 2 }}>
        {/* Left Section - Cockpit Overview */}
        <CompanyCockpitOverview />

        {/* Right Section - Company ID - The 'key' prop is used to rerender the component when a company change instead of using 'useEffect' */}
        <CompanyId key={company.id} />
      </Layout>

      {/* New MDM Management Section */}
      <CompanyMdmManagement />

      {/* Discount Section */}
      <CompanyDiscountInfo />
    </Layout>
  );
}
