import React, { useContext } from "react";
import { Button, Layout, Text, TextField } from "@fleet.co/tarmac";

import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import styles from "../CompanyDiscount.module.scss";
import CompanyContext from "../../../../contexts/CompanyContext";

const DiscountTableMdmRow = (props) => {
  const { isEditable, discountInfos, mdmChangeDiscount, setMdmChangeDiscount } = props;
  const { company } = useContext(CompanyContext);

  const clickMinus = () => {
    if (mdmChangeDiscount.mdm_trial_offer <= 1) {
      return;
    }
    setMdmChangeDiscount((prevValue) => ({ ...prevValue, mdm_trial_offer: prevValue.mdm_trial_offer - 1 }));
  };

  const clickPlus = () => {
    if (mdmChangeDiscount.mdm_trial_offer < 3) {
      setMdmChangeDiscount((prevValue) => ({ ...prevValue, mdm_trial_offer: prevValue.mdm_trial_offer + 1 }));
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d.,]/g, "");
    const valueForState = inputValue.replace(/[.,]/g, "");

    setMdmChangeDiscount((prevValue) => ({
      ...prevValue,
      mdm_unit_amount: parseInt(valueForState) || 0,
    }));
  };
  const formatNumberForDisplay = (number) => {
    const adjustedNumber = number / 100;
    const formattedNumber = adjustedNumber.toFixed(2);

    return formattedNumber;
  };

  const subscriptionMdmAlreadyExist = company.company_mdm?.status === "ACTIVE";

  return (
    <Layout direction="row" fullWidth justifyContent="space-between" alignItems="center" className={styles.tableRow}>
      <Layout direction="column" className={styles.cellRow}>
        <Layout direction="column">
          <Text variant="body1" bold>
            {discountInfos.title}
          </Text>
          <Text variant="body2">{discountInfos.sub}</Text>
        </Layout>
      </Layout>

      {discountInfos.type === "number" ? (
        <Layout direction="row" className={styles.productActionsContainer}>
          <Button
            startIcon={faMinus}
            size="medium"
            onClick={clickMinus}
            color="secondary"
            data-cy="decrementQuantity"
            sx={{ paddingRight: "4px" }}
            disabled={subscriptionMdmAlreadyExist || !isEditable}
            className={styles.leftButton}
          />
          <Layout
            direction="row"
            fullWidth
            className={styles.devicesCount}
            justifyContent="center"
            alignContent="center"
          >
            <Text textAlign="center">{mdmChangeDiscount.mdm_trial_offer}</Text>
          </Layout>
          <Button
            startIcon={faPlus}
            size="medium"
            onClick={clickPlus}
            color="secondary"
            data-cy="incrementQuantity"
            sx={{ paddingRight: "4px" }}
            disabled={subscriptionMdmAlreadyExist || !isEditable}
            className={styles.rigthButton}
          />
        </Layout>
      ) : (
        <TextField
          name="mdm_unit_amount"
          type="text"
          onChange={handleInputChange}
          value={formatNumberForDisplay(mdmChangeDiscount.mdm_unit_amount)}
          disabled={!isEditable}
        />
      )}
    </Layout>
  );
};

export default DiscountTableMdmRow;
