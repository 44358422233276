import { CURRENCIES_LANGUAGE_VARIATIONS_MAPPING } from "src/common/i18n-consts";

export const formatMoney = (amount, currency) => {
  if (!amount) return "-";
  const sum = parseFloat(amount);

  const price = sum.toLocaleString(CURRENCIES_LANGUAGE_VARIATIONS_MAPPING[currency], {
    style: "currency",
    currency,
    narrowSymbol: true,
  });

  return price;
};
