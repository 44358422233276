import React from "react";

const FormatDate = (props) => {
  if (props.date === null) {
    return <>-</>;
  }

  const date_obj = new Date(props.date);
  // const options = { year: 'numeric', month: 'short', day: 'numeric' };
  let date_formatted = date_obj.toLocaleDateString("fr-FR");

  if (props.withTime) {
    date_formatted += ` à ${date_obj.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  return date_formatted;
};

export default FormatDate;
