import React, { useContext, useEffect, useMemo, useState } from "react";
import { Accordion, Button, Checkbox, Dialog, Drawer, Layout, Select, Switch, Text, TextField } from "@fleet.co/tarmac";
import { COUNTRIES_LIST, CURRENCIES, CURRENCIES_LIST } from "src/common/i18n-consts";
import UserContext from "../../../tools/UserContext";
import { useToastContext } from "../../../contexts/ToastContext";

import styles from "./ProductModal.module.scss";
import { productSpecs } from "../../../data/productSpecs";

const initialProduct = {
  product_group_id: "",
  name: "",
  img_url: "",
  visibility: "DRAFT",
  available_countries: COUNTRIES_LIST,
  compliance: [],
  connection_type: [],
  screen_stand: [],
  weight: "",
  length: "",
  width: "",
  height: "",
  co2_saved: "",
  usage: "",
  min_shipping: "",
  max_shipping: "",
  prices: [],
  primarySku: {
    sku_code: "",
  },
  // Take every spec from productSpecs and create a key with the spec name and an empty value
  ...productSpecs
    .filter((spec) => spec.applyTo.includes("PRODUCT"))
    .reduce((acc, spec) => ({ ...acc, [spec.name]: spec.multiple ? [] : "" }), {}),
};

const ProductModal = (props) => {
  const {
    open,
    onCloseModal,
    onSaveModal,
    product: productFromProps,
    product_group,
    allProductGroups,
    reloadPage,
  } = props;

  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();

  const [product, setProduct] = useState(initialProduct);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [expandAvailability, setExpandAvailability] = useState(false);
  const [expandUsage, setExpandUsage] = useState(false);
  const [expandPrice, setExpandPrice] = useState(false);
  const [expandSpecs, setExpandSpecs] = useState(false);
  const countries = COUNTRIES_LIST;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "weight") {
      setProduct((prevInfo) => ({ ...prevInfo, [name]: value.replace(/[.,]/g, "") }));
    } else if (
      name === "compliance" ||
      name === "connection_type" ||
      name === "available_countries" ||
      name === "screen_stand"
    ) {
      const optionsSelected = product[name] ? [...product[name]] : [];
      const optionIndex = optionsSelected.findIndex((v) => v === value);

      if (optionIndex >= 0) {
        optionsSelected.splice(optionIndex, 1);
      } else {
        optionsSelected.push(value);
      }

      setProduct({ ...product, [name]: optionsSelected });
    } else if (name === "product_group_id") {
      const selectedGroup = allProductGroups.find((group) => group.id === parseInt(value));

      setProduct((prevInfo) => ({
        ...prevInfo,
        name: selectedGroup.name,
        img_url: selectedGroup.img_url,
      }));
    } else if (name === "sku") {
      setProduct((prevInfo) => ({ ...prevInfo, primarySku: { ...prevInfo.primarySku, sku_code: value } }));
    } else {
      setProduct((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  const handlePriceChange = (value, priceCurrency) => {
    value = value !== "" ? parseFloat(value) : null;

    setProduct((prevInfo) => {
      const updatedPrice = { currency: priceCurrency, amount: value };
      let updatedPriceList = [];
      const updatedPriceExists = prevInfo.prices.some((price) => price.currency === updatedPrice.currency);

      if (updatedPriceExists) {
        // Price in list : replace it in array, keep other prices as-is
        updatedPriceList = prevInfo.prices.map((price) => (price.currency === priceCurrency ? updatedPrice : price));
      } else {
        // Price not in list : append it at the end of array
        updatedPriceList = [...prevInfo.prices, updatedPrice];
      }

      return { ...prevInfo, prices: updatedPriceList };
    });
  };

  const handleSelectGroup = (value) => {
    setProduct((prevInfo) => ({ ...prevInfo, product_group_id: value }));
  };

  const handleSelectUsage = (value) => {
    setProduct((prevInfo) => ({ ...prevInfo, usage: value }));
  };

  const handleSubmit = async () => {
    // replace all "" by null values

    Object.keys(product).forEach((key) => {
      if (typeof product[key] === "string") {
        product[key] = product[key].trim();
      }

      if (product[key] === "") {
        product[key] = null;
      }

      if ([CURRENCIES.EUR, CURRENCIES.GBP, CURRENCIES.USD, "length", "width", "height"].includes(key) && product[key]) {
        product[key] = product[key].replace(",", ".");
      }
    });

    try {
      if (productFromProps) {
        await adminUser.api.modifyProduct(product.id, product);
      } else {
        await adminUser.api.addProduct(product);
      }
      onSaveModal();
    } catch (err) {
      addToast(err.response?.data?.message || "Couldn't proceed.");
    }
  };

  const deleteProduct = async () => {
    try {
      await adminUser.api.deleteProduct(product.id);
      setConfirmModalOpen(false);
      onCloseModal();
      reloadPage();
    } catch (err) {
      console.log(err);
    }
  };

  const cancelDelete = () => {
    setConfirmModalOpen(false);
    onCloseModal();
  };

  const visibilityOptions = [
    { value: "DRAFT", label: "Draft" },
    { value: "AVAILABLE", label: "Available" },
    { value: "UNAVAILABLE", label: "Unavailable" },
    { value: "ARCHIVED", label: "Archived" },
  ];

  const handleSelectSpec = (spec, value) => {
    setProduct((prevInfo) => ({ ...prevInfo, [spec]: value }));
  };

  useEffect(() => {
    if (productFromProps) {
      // Remove every null values from object so it's overide by initialProduct https://stackoverflow.com/a/38340730
      const productFromPropsWithoutNull = Object.fromEntries(
        Object.entries(productFromProps).filter(([, v]) => v != null),
      );

      setProduct({ ...initialProduct, ...productFromPropsWithoutNull });
    } else if (product_group) {
      setProduct({
        ...initialProduct,
        product_group_id: product_group.id,
        name: product_group.name,
        img_url: product_group.img_url,
      });
    } else {
      setProduct(initialProduct);
    }
  }, [productFromProps, product_group]);

  const selectOptions = useMemo(
    () => [{ label: "-", value: "" }, ...allProductGroups.map((pg) => ({ label: pg.name, value: pg.id }))],
    [allProductGroups],
  );

  const usageOptions = [
    { label: "-", value: "" },
    { label: "BASIC", value: "BASIC" },
    { label: "ADVANCED", value: "ADVANCED" },
    { label: "EXPERT", value: "EXPERT" },
  ];

  const getValuePrice = (currencyPrice) => {
    const currency = product.prices.find((price) => price.currency === currencyPrice);

    return currency ? currency.amount : "";
  };

  const DeleteActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={cancelDelete} />
      <Button color="error" variant="contained" label="Yes" onClick={deleteProduct} />
    </>
  );

  const DrawerActions = (
    <>
      <Button variant="contained" color="primary" label="Save" onClick={handleSubmit} />
      {productFromProps && (
        <Button variant="outlined" color="secondary" label="🗑 Delete" onClick={() => setConfirmModalOpen(true)} />
      )}
    </>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={onCloseModal}
        title={!productFromProps ? "Create a Product" : "Edit a Product"}
        Actions={DrawerActions}
      >
        <Layout direction="column" isScrollable className={styles.productModal} fullHeight>
          <form onSubmit={handleSubmit}>
            <Layout direction="column" spacing={1}>
              <Accordion title="Characteristics" expanded={expandSpecs} onChange={() => setExpandSpecs(!expandSpecs)}>
                <Layout direction="column" spacing={2}>
                  <Select
                    label="Product Group"
                    options={selectOptions}
                    value={product.product_group_id}
                    onChange={handleSelectGroup}
                  />

                  <Layout direction="row" fullWidth flexWrap="wrap" spacing={2}>
                    {product.product_group_id &&
                      productSpecs
                        .filter(
                          (spec) =>
                            spec.categories.includes(
                              allProductGroups.find((pg) => pg.id === product.product_group_id)?.category,
                            ) &&
                            spec.specSection === "characteristics" &&
                            spec.applyTo.includes("PRODUCT"),
                        )
                        .map((spec) => {
                          const { category } = allProductGroups.find((pg) => pg.id === product.product_group_id);

                          const specOptions = [...spec.options(category)];

                          return (
                            <Layout direction="column" flexGrow={1} flexBasis="33%" key={spec.name}>
                              {!spec.multiple ? (
                                <Select
                                  label={`${spec.label} *`}
                                  options={specOptions}
                                  onChange={(option) => handleSelectSpec(spec.name, option)}
                                  value={product[spec.name] ?? ""}
                                />
                              ) : (
                                <>
                                  <Text variant="body2">{spec.label}</Text>
                                  {spec.options(category).map((o) => (
                                    <Checkbox
                                      key={o.value}
                                      label={o.label}
                                      name={spec.name}
                                      checked={product[spec.name]?.includes(o.value) || false}
                                      onChange={() => handleChange({ target: { name: spec.name, value: o.value } })}
                                    />
                                  ))}
                                </>
                              )}
                            </Layout>
                          );
                        })}
                  </Layout>

                  <Layout direction="row" spacing={2}>
                    <TextField
                      label="Weight (g)"
                      required
                      name="weight"
                      type="number"
                      value={product.weight}
                      onChange={handleChange}
                    />
                    <TextField label="Width" name="width" type="number" value={product.width} onChange={handleChange} />
                  </Layout>

                  <Layout direction="row" spacing={2}>
                    <TextField
                      label="Height"
                      name="height"
                      type="number"
                      value={product.height}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Length"
                      name="length"
                      type="number"
                      value={product.length}
                      onChange={handleChange}
                    />
                  </Layout>

                  <TextField
                    label="CO2 Saved (For Refurbished)"
                    name="co2_saved"
                    type="text"
                    value={product.co2_saved}
                    onChange={handleChange}
                    disabled={product.quality === "new"}
                  />
                  <Layout direction="row" spacing={2}>
                    <TextField
                      label="SKU"
                      name="sku"
                      type="text"
                      value={product.primarySku?.sku_code}
                      onChange={handleChange}
                    />
                  </Layout>
                </Layout>
              </Accordion>

              <Accordion title="Prices" expanded={expandPrice} onChange={() => setExpandPrice(!expandPrice)}>
                <Layout direction="row" spacing={2}>
                  {CURRENCIES_LIST.map((currency) => (
                    <TextField
                      key={currency}
                      label={currency}
                      required
                      name={`${currency.toLowerCase()}_price`}
                      type="number"
                      value={getValuePrice(currency)}
                      onChange={(e) => handlePriceChange(e.target.value, currency)}
                    />
                  ))}
                </Layout>
              </Accordion>

              <Accordion title="Usages" expanded={expandUsage} onChange={() => setExpandUsage(!expandUsage)}>
                <Select label="Usage*" options={usageOptions} onChange={handleSelectUsage} value={product.usage} />
              </Accordion>

              <Accordion
                title="Availabilities"
                expanded={expandAvailability}
                onChange={() => setExpandAvailability(!expandAvailability)}
              >
                <Layout direction="column" spacing={2}>
                  <Layout direction="column" spacing={2}>
                    <Layout direction="column" spacing={1}>
                      <Text variant="body2">Available countries</Text>
                      <Layout direction="column" spacing={1}>
                        {countries.map((country) => (
                          <Switch
                            key={country}
                            label={country}
                            checked={product.available_countries?.includes(country) || false}
                            onChange={() => handleChange({ target: { name: "available_countries", value: country } })}
                          />
                        ))}
                      </Layout>
                    </Layout>

                    <Select
                      label="Visibility"
                      value={product.visibility}
                      options={visibilityOptions}
                      onChange={(option) => handleSelectSpec("visibility", option)}
                    />
                  </Layout>

                  <Layout direction="row" spacing={2}>
                    <TextField
                      label="Min Shipping"
                      name="min_shipping"
                      type="number"
                      value={product.min_shipping}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Max Shipping"
                      name="max_shipping"
                      type="number"
                      value={product.max_shipping}
                      onChange={handleChange}
                    />
                  </Layout>
                </Layout>
              </Accordion>
            </Layout>
          </form>
        </Layout>
      </Drawer>

      <Dialog
        onClose={() => setConfirmModalOpen(false)}
        title="Delete a Product"
        Actions={DeleteActionComponent}
        open={confirmModalOpen}
      >
        This action will delete the selected product.
        <br />
        Continue ?
      </Dialog>
    </>
  );
};

export default ProductModal;
