import React, { useMemo } from "react";
import { Layout, Link } from "@fleet.co/tarmac";
import CopyButton from "../../../../tools/CopyButton";

const TrackingCell = ({ row: device }) => {
  const buttonText = useMemo(() => {
    if (device.status === "SHIPPED") return "Shipment in progress";

    if (device.status === "DELIVERED") return "Device is delivered";

    return "Follow the delivery";
  }, [device.status]);

  return (
    <Layout direction="column">
      {device.tracking_number ? (
        <>
          <CopyButton value={device.tracking_number}>{device.tracking_number}</CopyButton>
          <Link
            href={`https://fleet.aftership.com/${device.tracking_number}?page-name=tracking-page`}
            targetBlank
            label={buttonText}
            color="info"
            iconRight
          />
        </>
      ) : (
        <span>-</span>
      )}
    </Layout>
  );
};

export default TrackingCell;
