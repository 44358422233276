import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Select, TextField } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import styles from "./EnvelopeModal.module.scss";
import { leasersList } from "../../data/leasersList";

const emptyEnvelope = {
  company_id: "",
  status: "TODO",
  leaser: "BNP",
  total_amount: null,
  current_amount: null,
  start_date: new Date(),
  end_date: new Date(),
  request_id: null,
};

const EnvelopeModal = (props) => {
  const { closeAction, closeEnvelope, open, envelope, companyId, statuses, onSaveChanges } = props;
  const { user: adminUser } = useContext(UserContext);

  const [envelopeInfos, setEnvelopeInfos] = useState(emptyEnvelope);

  const [companySearchValue, setCompanySearchValue] = useState("");
  const [foundCompanies, setFoundCompanies] = useState([]);

  const [error, setError] = useState("");
  const [errorCreatingEnvelope, setErrorOfCreatingEnvelope] = useState(null);

  const envelopeStatuses = !envelope ? statuses : statuses.filter((s) => s.value !== "CLOSED");

  const changeStartDate = (date) => {
    setEnvelopeInfos({ ...envelopeInfos, start_date: date });
  };
  const changeEndDate = (date) => {
    setEnvelopeInfos({ ...envelopeInfos, end_date: date });
  };

  const handleAmountInputChange = (e) => {
    setEnvelopeInfos({
      ...envelopeInfos,
      total_amount: Number(e.target.value),
      current_amount: Number(e.target.value),
    });
  };
  const handleRequestIdInputChange = (e) => {
    setEnvelopeInfos({ ...envelopeInfos, request_id: e.target.value });
  };

  const handleSelectChange = (name, value) => {
    setEnvelopeInfos({ ...envelopeInfos, [name]: value });
  };

  const checkValidData = () => {
    // If the status selected is ACCEPTED, all fields are required
    if (envelopeInfos.status === "ACCEPTED") {
      if (envelopeInfos.company_id === "") {
        setError("Missing value in company field");

        return false;
      }

      if (envelopeInfos.leaser === "") {
        setError("Missing leaser information");

        return false;
      }

      if (envelopeInfos.status === "") {
        setError("Missing  status information!");

        return false;
        // Put an amount bigger than 0, if the "total amount" is 0 the envelope closes automatically
      }

      if (envelopeInfos.total_amount === null) {
        setError("Missing value in amount field");

        return false;
      }

      if (envelopeInfos.start_date === "") {
        setError("Missing start date");

        return false;
        // Put at least 3 months of time left, if the "month left" is 0 the envelope closes automatically as "Expired"
      }

      if (envelopeInfos.end_date === envelopeInfos.start_date) {
        setError("Missing ending date, put at least 3 month");

        return false;
      }

      if (envelopeInfos.request_id === null) {
        setError("Missing value in request id field");

        return false;
      }
      setError("");

      return true;
    }

    // If the status is in progress, only companyID and status is required
    if (envelopeInfos.company_id === "") {
      setError("Missing value in company field");

      return false;
      // Put an amount bigger than 0, if the "total amount" is 0 the envelope closes automatically
    }

    if (envelopeInfos.total_amount === null) {
      setError("Missing value in amount field");

      return false;
      // Put at least 3 months of time left, if the "month left" is 0 the envelope closes automatically as "Expired"
    }

    if (envelopeInfos.end_date === envelopeInfos.start_date) {
      setError("Missing ending date, put at least 3 month");

      return false;
    }
    setError("");

    return true;
  };

  const saveChanges = async () => {
    const is_data_ok = checkValidData();

    if (!is_data_ok) {
      return;
    }

    if (!envelope) {
      if (!error.length) {
        try {
          await adminUser.api.addEnvelope(envelopeInfos);
          handleClose();
        } catch (e) {
          setErrorOfCreatingEnvelope(e.response?.data?.message || "The envelope creation failed");
        }
      }
    } else {
      try {
        await adminUser.api.modifyEnvelope(envelope.id, envelopeInfos);
        handleClose();
      } catch (e) {
        setError(e.response?.data?.message || "Error during the update");
      }
    }
    onSaveChanges();
  };

  const searchCompanies = async (name) => {
    if (name.split("").length > 2) {
      const fetchedCompanies = await adminUser.api.getCompanies(`?name=${name}`);

      setFoundCompanies(fetchedCompanies.data);
    }
  };

  const handleCompanyOnChange = (value) => {
    setCompanySearchValue("");
    setEnvelopeInfos({
      ...envelopeInfos,
      company_id: value,
    });
  };

  const selectedCompanyOption =
    companySearchValue === "" && envelopeInfos.company_id !== undefined
      ? envelopeInfos.company_id.name
      : companySearchValue;

  const allCompanyOptions = foundCompanies.map((c) => {
    const label = `${c.name} | SIREN: ${c.siren || "not specified"} | Total orders: ${c.orders.length}`;

    return { value: c.id, label };
  });

  const current_status = envelopeStatuses.find((s) => s.value === envelopeInfos.status);
  const leasers = leasersList.map((l) => ({ value: l.name, label: l.label }));
  const currentleaser = leasers.find((l) => l.value === envelopeInfos.leaser);

  const modalTitle = !envelope ? "Add new funding" : "Edit funding";

  const handleClose = () => {
    setEnvelopeInfos({ ...emptyEnvelope });
    closeAction();
  };

  useEffect(() => {
    // check if modal is opened from company view => the company id will be the current company and this is a Create action
    if (envelope) {
      setEnvelopeInfos({ ...envelopeInfos, ...envelope });
    } else if (companyId) {
      setEnvelopeInfos({ ...envelopeInfos, company_id: companyId });
    }
  }, [envelope]);

  useEffect(() => {
    if (companySearchValue === "") {
      setFoundCompanies([]);
    }

    if (companySearchValue.length > 3) {
      searchCompanies(companySearchValue);
    }
  }, [companySearchValue]);

  const Actions = (
    <>
      <Button variant="contained" color="primary" label="Save" onClick={saveChanges} />

      <Button variant="outlined" color="secondary" label="Close envelope" onClick={closeEnvelope} />
    </>
  );

  return (
    <Drawer open={open} onClose={handleClose} title={modalTitle} Actions={Actions}>
      <div className={styles.container}>
        {error !== "" ? <p style={{ color: "red" }}>{error}</p> : ""}
        {!envelope && !companyId ? (
          <Select
            label="Company"
            value={selectedCompanyOption}
            onInputChange={(_, value) => setCompanySearchValue(value)}
            options={allCompanyOptions}
            onChange={handleCompanyOnChange}
          />
        ) : null}
        <div className={styles.el_wrapper}>
          <Select
            label="Status"
            value={current_status}
            options={envelopeStatuses}
            onChange={(value) => handleSelectChange("status", value)}
          />
        </div>
        <div className={styles.el_wrapper}>
          <Select
            label="Leaser"
            value={currentleaser}
            options={leasers}
            onChange={(value) => handleSelectChange("leaser", value)}
          />
        </div>
        {errorCreatingEnvelope !== "" ? <p style={{ color: "red" }}>{errorCreatingEnvelope}</p> : ""}
        <div className={styles.el_wrapper}>
          <TextField
            label="Amount"
            type="number"
            placeholder="0 €"
            value={envelopeInfos.total_amount || ""}
            onChange={handleAmountInputChange}
          />
        </div>

        <div className={styles.dates_wrapper}>
          <div className={styles.el_wrapper}>
            <p>Starting Date</p>
            <DatePicker
              label="Starting Date"
              name="startingDate"
              value={envelopeInfos.start_date}
              onChange={(date) => changeStartDate(date)}
            />
          </div>
          <div className={styles.el_wrapper}>
            <p>Ending Date</p>
            <DatePicker
              label="Ending Date"
              name="order_date"
              value={envelopeInfos.end_date}
              onChange={(date) => changeEndDate(date)}
            />
          </div>
        </div>
        <div className={styles.el_wrapper}>
          <TextField
            label="Request ID"
            type="text"
            placeholder="XXXXXXX"
            value={envelopeInfos.request_id || ""}
            onChange={handleRequestIdInputChange}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default EnvelopeModal;
