import React, { useEffect, useState } from "react";
import { Layout, SearchBar } from "@fleet.co/tarmac";

import PlaceService from "../../tools/PlaceService";

const placeService = new PlaceService();

const CityOfBirthAutocomplete = ({ value = {}, onChange, isEditable, user }) => {
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(value.city_of_birth || "");

  const onSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const suggestionsFromApi = await placeService.getPlaceIdFromText(query, ["(cities)"]);

        setOptions(suggestionsFromApi);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const onSelect = async (_, selectedOption) => {
    try {
      const { city, countryISO } = await placeService.getAddressForPlaceId(selectedOption.place_id);

      onChange({ target: { name: "city_of_birth", value: city } });
      onChange({ target: { name: "country_of_birth", value: countryISO } });

      setSearchQuery(city);
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  useEffect(() => {
    setSearchQuery(value.city_of_birth || "");
  }, [value]);

  return (
    <Layout direction="column" spacing={1}>
      <SearchBar
        placeholder={user?.city_of_birth || "City of birth"}
        options={options}
        onInputChange={(_, a) => onSearch(a)}
        getOptionLabel={(option) => option.description}
        noOptionsText={searchQuery?.length > 2 && "No result"}
        onChange={onSelect}
        renderOption={(option) => option.description && <Layout direction="row">{option.description}</Layout>}
        disabled={!isEditable}
      />
    </Layout>
  );
};

export default CityOfBirthAutocomplete;
