import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

const Context = createContext({});

export const useToastContext = () => useContext(Context);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const removeToast = (uid) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.uid !== uid));
  };

  const addToast = useCallback(
    (message, variant = "error", duration = 5000) => {
      const toast = {
        message,
        variant,
        uid: Date.now(),
        duration,
      };

      // Add toast
      setToasts([...toasts, toast]);
    },
    [toasts],
  );

  const values = useMemo(
    () => ({
      toasts,
      removeToast,
      addToast,
    }),
    [toasts, addToast],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
