import React from "react";
import { Tag } from "@fleet.co/tarmac";

const OrderStatusNiceName = (props) => {
  const { status } = props;

  if (!status) {
    return null;
  }

  const nice_name = status.replaceAll("_", " ");
  let color = "grey";

  const statusColors = {
    UNQUALIFIED_LEAD: "grey",
    LEAD_AGREEMENT: "orange",
    LEASER_REQUESTED: "yellow",
    LEASER_MORE_DOCUMENTS: "yellow",
    LEASER_AGREEMENT: "orange",
    LEAD_SIGNING: "orange",
    READY_FOR_PURCHASE: "blue",
    PURCHASED: "blue",
    SHIPPED: "blue",
    DELIVERED: "blue",
    BDL_SENT: "blue",
    RECEIVED: "green",
    FINALIZED: "green",
    CLOSED: "green",
    INACTIVE: "grey",
    FAILED: "red",
  };

  if (Object.keys(statusColors).includes(status)) {
    color = statusColors[status];
  }

  return <Tag variant="filled" label={nice_name} backgroundColor={color || "grey"} size="small" />;
};

export default OrderStatusNiceName;
