import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Button, DatePicker, Drawer, Heading, Icon, Layout, Select, TextField } from "@fleet.co/tarmac";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { deviceStatus } from "../../data/deviceStatus";
import UserContext from "../../tools/UserContext";
import { carriers } from "../../data/carriers";
import styles from "./MassEditModal.module.scss";

const MassEditModal = (props) => {
  const { devices, onClose, open, reloadPage } = props;

  const { user: adminUser } = useContext(UserContext);
  const areAllDevicesFieldsIdentical = useCallback(
    (fieldName) => devices.length && devices?.every((d) => d[fieldName] && d[fieldName] === devices[0][fieldName]),
    [devices],
  );

  const initialState = {
    status: areAllDevicesFieldsIdentical("status") ? devices[0]?.status : "mixed",
    provider: areAllDevicesFieldsIdentical("provider") ? devices[0]?.provider : "mixed",
    provider_country_id: areAllDevicesFieldsIdentical("provider_country_id") ? devices[0]?.provider_country_id : null,
    carrier: areAllDevicesFieldsIdentical("carrier") ? devices[0]?.carrier : "",
    acquisition_price: areAllDevicesFieldsIdentical("acquisition_price") ? devices[0]?.acquisition_price : "",
    sku: {
      sku_code: areAllDevicesFieldsIdentical("sku_id") ? devices[0]?.sku.sku_code : null,
    },
    provider_request_number: areAllDevicesFieldsIdentical("provider_request_number")
      ? devices[0]?.provider_request_number
      : "",
    tracking_number: areAllDevicesFieldsIdentical("tracking_number") ? devices[0]?.tracking_number : "",
    serial_numbers: devices.map((device) => device.serial_number).join(", "),
  };

  const [devicesInfo, setDevicesInfo] = useState(initialState);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    serial_error: null,
    config_error: null,
  });

  const carriersOptions = [{ label: "-", value: "" }, ...carriers];

  const saveData = async () => {
    if (!errors.serial_error && !errors.config_error) {
      setIsLoading(true);

      const parsedSerialNumbers = checkSerialInput(devicesInfo.serial_numbers);

      if (parsedSerialNumbers.length !== devices.length) {
        setErrors({ ...errors, serial_error: "The number of serial numbers does not match the number of devices" });
        setIsLoading(false);

        return;
      }

      for (const [index, device] of devices.entries()) {
        const serial_number = parsedSerialNumbers[index];

        const devicePatch = {
          status: devicesInfo.status,
          provider: devicesInfo.provider,
          carrier: devicesInfo.carrier,
          acquisition_price: devicesInfo.acquisition_price,
          provider_request_number: devicesInfo.provider_request_number,
          provider_country_id: devicesInfo.provider_country_id,
          tracking_number: devicesInfo.tracking_number,
          expected_delivery_date: devicesInfo.expected_delivery_date,
          serial_number,
          sku: {
            sku_code: devicesInfo.sku?.sku_code,
          },
        };

        // Remove empty fields
        const cleanedDevicePatch = Object.fromEntries(
          Object.entries(devicePatch).filter(([, v]) => v !== "" && v !== "mixed"),
        );

        try {
          await adminUser.api.modifyDevice(device.id, cleanedDevicePatch);
        } catch (error) {
          console.error(error);
        }
      }
      setIsLoading(false);
    }
    onClose();
    reloadPage();
    setIsLoading(false);
  };

  const isSameConfig = useMemo(() => {
    const firstOne = devices[0];

    if (!firstOne) {
      return false;
    }

    const differentOne = devices?.find(
      (device) =>
        device.name !== firstOne.name ||
        device.ram !== firstOne.ram ||
        device.screen_size !== firstOne.screen_size ||
        device.storage !== firstOne.storage,
    );

    if (differentOne) {
      return false;
    }

    return true;
  }, [devices]);

  const checkSerialInput = (serials) => {
    const serial_array = serials.split(",").map((serial) => serial.trim());

    if (serial_array.length !== devices?.length && serials) {
      setErrors({ ...errors, serial_error: "Le nombre de numéros de série ne correspond pas au nombre d'appareils" });
    } else {
      setErrors({ ...errors, serial_error: null });
    }

    return serial_array;
  };

  const handleInputChange = (event) => {
    if (event.target.name === "serial_numbers") {
      checkSerialInput(event.target.value);
    }

    if (event.target.name === "sku") {
      setDevicesInfo({
        ...devicesInfo,
        sku: {
          ...devicesInfo.sku,
          sku_code: event.target.value,
        },
      });

      return;
    }

    setDevicesInfo({ ...devicesInfo, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (field, value) => {
    setDevicesInfo({ ...devicesInfo, [field]: value });
  };

  const selectStatusOptions = useMemo(() => {
    const options = [...deviceStatus];

    if (!areAllDevicesFieldsIdentical("status")) {
      options.push({
        label: "Mixed",
        value: "mixed",
      });
    }

    return options;
  }, [areAllDevicesFieldsIdentical]);

  const selectProviderOptions = useMemo(() => {
    const options = [...providersOptions];

    if (!areAllDevicesFieldsIdentical("provider")) {
      options.push({
        label: "Mixed",
        value: "mixed",
      });
    }

    return options;
  }, [areAllDevicesFieldsIdentical, providersOptions]);

  const cleanError = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => {
    setDevicesInfo({
      status: areAllDevicesFieldsIdentical("status") ? devices[0]?.status : "mixed",
      provider: areAllDevicesFieldsIdentical("provider") ? devices[0]?.provider : "mixed",
      acquisition_price: areAllDevicesFieldsIdentical("acquisition_price") ? devices[0]?.acquisition_price : "",
      provider_request_number: areAllDevicesFieldsIdentical("provider_request_number")
        ? devices[0]?.provider_request_number
        : "",
      tracking_number: areAllDevicesFieldsIdentical("tracking_number") ? devices[0]?.tracking_number : "",
      serial_numbers: devices.map((device) => device.serial_number).join(", "),
    });

    setErrors({
      serial_error: null,
      config_error: null,
    });
  }, [devices, areAllDevicesFieldsIdentical]);

  useEffect(() => {
    const fetchProviders = async () => {
      const { data: providers } = await adminUser.api.getProviders();

      const providersOptions = providers.map((provider) => ({
        label: provider.name,
        value: provider.name,
        countries: provider.countries,
      }));

      setProvidersOptions(providersOptions);
    };

    fetchProviders()
      .then()
      .catch((error) => {
        console.error(error);
        setErrorMessage("Something went wrong while fetching the providers, you can save the other fields");
      });
  }, [adminUser.api]);

  const selectedProvider = providersOptions.find((provider) => provider.value === devicesInfo.provider);
  const providerCountriesOption = selectedProvider?.countries.map((country) => ({
    label: country.name,
    value: country.id,
  }));

  return (
    <Drawer
      onClose={onClose}
      Actions={
        <Button
          variant="contained"
          color="primary"
          label="Save"
          onClick={saveData}
          loading={isLoading}
          disabled={errors.serial_error || errors.config_error || isLoading}
        />
      }
      title="Mass edit"
      open={open}
    >
      <Layout direction="column" spacing={2}>
        <Heading variant="h3" className={styles.flexContainer}>
          This action will be applied to <span className={styles.fleetGreen}>{` ${devices?.length} `} devices</span>{" "}
        </Heading>

        <Select
          label="Status"
          value={devicesInfo.status}
          options={selectStatusOptions}
          onChange={(value) => handleSelectChange("status", value)}
        />

        <TextField label="Acquisition price" name="acquisition_price" onChange={handleInputChange} placeholder="0000" />

        {errorMessage && (
          <Alert severity="error" onClose={cleanError}>
            {errorMessage}
          </Alert>
        )}

        <Select
          label="Provider"
          key={devicesInfo.provider}
          value={devicesInfo.provider}
          options={selectProviderOptions}
          onChange={(value) => handleSelectChange("provider", value)}
        />
        {Boolean(providerCountriesOption?.length) && (
          <Select
            label="Supplier country"
            key={devicesInfo.provider_country_id}
            value={devicesInfo.provider_country_id}
            options={providerCountriesOption}
            onChange={(value) => handleSelectChange("provider_country_id", value)}
          />
        )}
        <TextField
          label="Provider request number"
          name="provider_request_number"
          onChange={handleInputChange}
          placeholder="21-J38392-3938"
        />

        {devicesInfo.provider === "Ingram" && devicesInfo.provider_request_number !== "" && (
          <TextField label="SKU" name="sku" onChange={handleInputChange} value={devicesInfo.sku?.sku_code} />
        )}
        <TextField
          label="Tracking number"
          name="tracking_number"
          onChange={handleInputChange}
          placeholder="037838389923"
        />

        <Layout direction="column" spacing={2}>
          <Select
            label="Carrier"
            value={devicesInfo.carrier}
            options={carriersOptions}
            onChange={(value) => handleSelectChange("carrier", value)}
          />
          <Layout direction="row" spacing={2}>
            <TextField label="Carrier slug" name="carrier" onChange={handleInputChange} />
            <Icon
              icon={faQuestionCircle}
              color="secondary"
              size="S"
              onClick={() => window.open("https://www.aftership.com/docs/tracking/others/supported-couriers", "_blank")}
            />
          </Layout>
        </Layout>
        <DatePicker
          label="Estimated Delivery Date"
          name="expected_delivery_date"
          onChange={(date) => setDevicesInfo({ ...devicesInfo, expected_delivery_date: date })}
          value={devicesInfo.expected_delivery_date}
        />
        <TextField
          label="Serial number(s)"
          name="serial_numbers"
          multiline
          disabled={!isSameConfig}
          value={devicesInfo.serial_numbers}
          rows={4}
          placeholder="Please separate serials by commas : 122HKHDS,329783474HJ,197823HDJS "
          onChange={handleInputChange}
        />
        {(errors.config_error || errors.serial_error) && (
          <ul>
            {Object.values(errors).map((error, index) => (
              <li key={index} className={styles.errorText}>
                {error}
              </li>
            ))}
          </ul>
        )}
      </Layout>
    </Drawer>
  );
};

export default MassEditModal;
