import { Layout, Separator, Tag, Text, theme } from "@fleet.co/tarmac";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import CImage from "src/components/common/CImage";
import type { Order } from "src/types/Order.type";
import { formatMoney } from "src/helpers/formatHelpers";
import { getAppsARR, getMdmARR } from "src/helpers/getARR";
import { companyQueries, useCompanyMutation } from "./queries/companyQueries";
import { EditButton } from "./utils/EditButton";
import { EditField } from "./utils/EditField";

const getIsFirstOrderOptIn = (orders: Order[]) => {
  const firstOrder = orders.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)))[0];
  if (!firstOrder) return false;
  return firstOrder.devices
    .filter((device) => device.category === "COMPUTER")
    .every((device) => device.order_mdm_activation_consent);
};

const translateMdmReason = (frenchReason: string): string => {
  switch (frenchReason) {
    case "Mon entreprise utilise une autre solution MDM":
      return "My company uses another MDM solution";
    case "Pour des raisons budgétaires":
      return "For budgetary reasons";
    case "Il n'y a pas assez de fonctionnalités intéressantes":
      return "There are not enough interesting features";
    case "Je sécuriserai mes appareils plus tard":
      return "I will secure my devices later";
    case "Mon entreprise ne compte pas assez d'employés":
      return "My company does not have enough employees";
    default:
      return frenchReason;
  }
};

export function CompanyMdmManagement() {
  const { id } = useParams<{ id: string }>();
  const { data: company } = useSuspenseQuery(companyQueries.detail(Number(id)));
  const modifyCompany = useCompanyMutation(Number(id));
  const [isMdmManagementEditing, setIsMdmManagementEditing] = useState(false);

  const initialFormValues = {
    abm_id: company.company_mdm?.abm_id ?? "",
    mdm_employee_password: company.mdm_employee_password,
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const realOrders = company.orders?.filter((o) => o.status !== "UNQUALIFIED_LEAD") ?? [];
  const isFirstOrderOptIn = getIsFirstOrderOptIn(realOrders);

  const apps = company.company_mdm?.apps?.filter((app) => app.price > 0) ?? [];

  const { current: mdmARR, prediction: mdmPrediction } = getMdmARR(company);
  const { current: appsARR, prediction: appsPrediction } = getAppsARR(company);

  const saveCompanyMdm = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setIsMdmManagementEditing(false);
    modifyCompany.mutate(formValues);
  };

  const handleEdit = () => {
    setIsMdmManagementEditing(true);
  };

  const handleCancel = () => {
    setIsMdmManagementEditing(false);
    setFormValues(initialFormValues);
  };

  return (
    <form onSubmit={saveCompanyMdm}>
      <Layout
        direction="column"
        sx={{
          marginTop: 3,
          borderRadius: 1,
          border: `1px solid ${theme.palette.border.secondary}`,
          backgroundColor: theme.palette.grey[100],
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        }}
        divider={<Separator orientation="horizontal" />}
      >
        <Layout
          sx={{
            paddingX: 3,
            paddingY: 2,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="h5" bold>
            MDM Management
          </Text>
          <EditButton onEdit={handleEdit} onCancel={handleCancel} isEditing={isMdmManagementEditing} />
        </Layout>

        <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text>
                MDM ARR&nbsp;
                <span style={{ color: theme.palette.grey[500] }}>(and potential)</span>
              </Text>
              <Layout direction="row" spacing={1} divider={<Separator orientation="vertical" />}>
                <Text bold>{formatMoney(mdmARR, "EUR")}</Text>
                <Text bold color="secondary">
                  {formatMoney(mdmPrediction, "EUR")}
                </Text>
              </Layout>
            </Layout>
            <Layout flex={1}>
              <Text>First order opt-in</Text>
              <Layout direction="row" spacing={1}>
                {isFirstOrderOptIn ? (
                  <Tag backgroundColor="green" label="Yes" variant="filled" size="small" />
                ) : (
                  <Tag backgroundColor="orange" label="No" variant="filled" size="small" />
                )}
              </Layout>
            </Layout>
            {company.no_mdm_reason && (
              <Layout flex={1}>
                <Text>
                  If no, reason:&nbsp;
                  <span style={{ color: theme.palette.grey[500] }}>{translateMdmReason(company.no_mdm_reason)}</span>
                </Text>
                {company.no_mdm_commented_reason && (
                  <Text
                    sx={{
                      backgroundColor: "white",
                      paddingX: 1.5,
                      paddingY: 1,
                      borderRadius: 1,
                      marginTop: 1,
                      border: `1px solid ${theme.palette.border.secondary}`,
                    }}
                  >
                    {company.no_mdm_commented_reason}
                  </Text>
                )}
              </Layout>
            )}
          </Layout>
        </Layout>

        <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
          <Layout direction="row" spacing={2}>
            <Layout flex={1}>
              <Text>
                Services ARR&nbsp;
                <span style={{ color: theme.palette.grey[500] }}>(and potential)</span>
              </Text>
              <Layout direction="row" spacing={1} divider={<Separator orientation="vertical" />}>
                <Text bold>{formatMoney(appsARR, "EUR")}</Text>
                <Text bold color="secondary">
                  {formatMoney(appsPrediction, "EUR")}
                </Text>
              </Layout>
            </Layout>
            <Layout flex={1}>
              <Text>Has services: (app)</Text>
              <Layout direction="row" flexWrap="wrap" spacing={1} divider={<Separator orientation="vertical" />}>
                {apps.map((app) => (
                  <Layout direction="row" spacing={0.5} key={app.name}>
                    <Text bold>{app.name}</Text>
                    <CImage fetchUrl={app.logo_url} alt={app.name} width={24} height={24} />
                  </Layout>
                ))}
              </Layout>
            </Layout>
            <Layout flex={1}>
              <Layout direction="row" spacing={1}>
                <Layout flex={1}>
                  <Text>MDM password</Text>
                  <EditField
                    isEditing={isMdmManagementEditing}
                    name="mdm_employee_password"
                    value={formValues.mdm_employee_password}
                    onChange={(ev) => setFormValues({ ...formValues, mdm_employee_password: ev.target.value })}
                  />
                </Layout>
                <Layout flex={1}>
                  <Text>ABM ID</Text>
                  <EditField
                    isEditing={isMdmManagementEditing}
                    name="abm_id"
                    value={formValues.abm_id}
                    onChange={(ev) => setFormValues({ ...formValues, abm_id: ev.target.value })}
                  />
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </form>
  );
}
