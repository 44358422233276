import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

export default function CImage({
  label,
  width,
  height,
  fetchUrl,
  alt,
  ...rest
}: {
  label?: string;
  width: number;
  height?: number;
  fetchUrl?: string;
  alt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any;
}) {
  const cld = new Cloudinary({ cloud: { cloudName: import.meta.env.REACT_APP_CLOUDINARY_CLOUDNAME } });

  // Instantiate and configure a CloudinaryImage object.
  let img;

  if (fetchUrl) {
    img = cld.image(fetchUrl).setDeliveryType("fetch");
  } else {
    img = cld.image(label);
  }

  return <AdvancedImage cldImg={img} width={width} height={height} alt={alt || ""} {...rest} />;
}
