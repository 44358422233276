import React, { useContext, useEffect, useState } from "react";
import { categories } from "../../../../data/productSpecs";
import UserContext from "../../../../tools/UserContext";
import styles from "./AddDevice.module.scss";

import AddDeviceProductGroupTable from "./AddDeviceProductGroupTable";
import AddDeviceModal from "./AddDeviceModal";

const AddDevice = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const [allProductGroups, setAllProductGroups] = useState([]);
  const [displayedProductGroups, setDisplayedProductGroups] = useState([]);

  const [openGroupList, setOpenGroupList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAddedDeviceProduct, setCurrentAddedDeviceProduct] = useState(null);
  const [currentAddedDeviceProductGroup, setCurrentAddedDeviceProductGroup] = useState(null);

  const [categoryFilter, setCategoryFilter] = useState(null);

  const getProductGroups = async () => {
    const product_groups = await adminUser.api.getProductGroups();

    // remove the non visible products
    const visible_products = [];

    product_groups.data.forEach((pg) => {
      const products = pg.products.filter((p) => p.visibility);

      pg.products = products;
      visible_products.push(pg);
    });

    // remove the non empty product_groups
    const no_empty_product_group = visible_products.filter((pg) => pg.products.length > 0);

    setAllProductGroups(no_empty_product_group);
  };

  const applyFilter = () => {
    if (categoryFilter) {
      const matchingProductGroups = allProductGroups.filter((pg) => pg.category === categoryFilter);

      setDisplayedProductGroups(matchingProductGroups);
    } else {
      setDisplayedProductGroups(allProductGroups);
    }
  };

  const handleGroupOpen = (product_group_id) => {
    const index = openGroupList.findIndex((pg) => pg === product_group_id);

    if (index === -1) {
      setOpenGroupList((prevArray) => [...prevArray, product_group_id]);
    } else {
      setOpenGroupList((prevArray) => prevArray.filter((pg, i) => i !== index));
    }
  };

  const openModal = (productGroup, product) => {
    setCurrentAddedDeviceProduct(product);
    setCurrentAddedDeviceProductGroup(productGroup);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getProductGroups();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [categoryFilter, allProductGroups]);

  return (
    <div>
      <section className={styles.categoryfilter}>
        <div
          className={styles.categoryfilterBox}
          onClick={() => setCategoryFilter(null)}
          style={!categoryFilter ? { border: "1px solid #0F0096", color: "#0F0096" } : {}}
        >
          Tous les appareils
        </div>

        {categories.map((cat, index) => (
          <div
            className={styles.categoryfilterBox}
            key={index}
            onClick={() => setCategoryFilter(cat)}
            style={categoryFilter === cat ? { border: "1px solid #0F0096", color: "#0F0096" } : {}}
          >
            {cat}
          </div>
        ))}
      </section>

      {displayedProductGroups.map((pg) => (
        <AddDeviceProductGroupTable
          reloadOrder={props.reloadOrder}
          order={props.order}
          product_group={pg}
          all_product_groups={displayedProductGroups}
          getProductGroups={getProductGroups}
          handleGroupOpen={handleGroupOpen}
          isOpen={openGroupList.includes(pg.id)}
          key={pg.id}
          openModal={openModal}
        />
      ))}

      <AddDeviceModal
        reloadOrder={props.reloadOrder}
        order={props.order}
        open={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        product={currentAddedDeviceProduct}
        product_group={currentAddedDeviceProductGroup}
      />
    </div>
  );
};

export default AddDevice;
