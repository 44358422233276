export const orderStatus = [
  {
    label: "BDL Sent",
    value: "BDL_SENT",
  },
  {
    label: "Closed",
    value: "CLOSED",
  },
  {
    label: "Delivered",
    value: "DELIVERED",
  },
  {
    label: "Failed",
    value: "FAILED",
  },
  {
    label: "Finalized",
    value: "FINALIZED",
  },
  {
    label: "Inactive",
    value: "INACTIVE",
  },
  {
    label: "Lead agreement",
    value: "LEAD_AGREEMENT",
  },
  {
    label: "Lead signing",
    value: "LEAD_SIGNING",
  },
  {
    label: "Leaser disagreement",
    value: "LEASER_DISAGREEMENT",
  },
  {
    label: "Leaser more documents",
    value: "LEASER_MORE_DOCUMENTS",
  },
  {
    label: "Leaser requested",
    value: "LEASER_REQUESTED",
  },
  {
    label: "Purchased",
    value: "PURCHASED",
  },
  {
    label: "Received",
    value: "RECEIVED",
  },
  {
    label: "Ready for purchase",
    value: "READY_FOR_PURCHASE",
  },
  {
    label: "Shipped",
    value: "SHIPPED",
  },
  {
    label: "Unqualified lead",
    value: "UNQUALIFIED_LEAD",
  },
];

export const inactiveReason = [
  {
    label: "-",
    value: "",
  },
  {
    label: "End of Life",
    value: "END_OF_LIFE",
  },
  {
    label: "Upgrade",
    value: "UPGRADE",
  },
  {
    label: "Early Stop",
    value: "EARLY_STOP",
  },
  {
    label: "Manual Resiliation",
    value: "MANUAL_RESILIATION",
  },
  {
    label: "Incident",
    value: "INCIDENT",
  },
];
