import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Heading, Layout, Select, Text } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";

import styles from "./PunctualOffers.module.scss";

import PunctualOffersRow from "../tables/punctualOffers/PunctualOffersRow";
import LoadingSpinner from "../common/LoadingSpinner";
import PunctualOfferModal from "../blocks/punctualOffers/PunctualOfferModal";

const linkLanguageOptions = [
  { label: "🇫🇷 France", value: "" },
  { label: "🇪🇸 Spain", value: "es/" },
  { label: "🇩🇪 Deutsch", value: "de/" },
  { label: "🇬🇧 English", value: "en/" },
];

const PunctualOffers = () => {
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [currentUpdatingOffer, setCurrentUpdatingOffer] = useState(null);
  const [linkLanguage, setLinkLanguage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchOffers = useCallback(async () => {
    setIsLoading(true);
    const fetchedOffers = await adminUser.api.getOffers();

    setOffers(fetchedOffers.data);
    setIsLoading(false);
  }, [adminUser.api]);

  const handleOpenModal = (offer) => {
    setCurrentUpdatingOffer(offer);
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <Layout direction="column">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Layout direction="column" spacing={2}>
          <Layout direction="row" justifyContent="space-between">
            <Heading variant="h2">Punctual Offers</Heading>
            <Layout direction="row" justifyContent="flex-end" spacing={2}>
              <Select
                label="Links language"
                value={linkLanguage}
                onChange={(value) => setLinkLanguage(value)}
                options={linkLanguageOptions}
              />

              <Button variant="contained" color="primary" label="Add Promo" onClick={() => handleOpenModal(null)} />
            </Layout>
          </Layout>
          <Layout direction="row" textAlign="center" alignItems="center" className={styles.tableHead}>
            <Text style={{ width: "12%" }} textAlign="center">
              Name
            </Text>
            <Text style={{ width: "10%" }} textAlign="center">
              Starting date
            </Text>
            <Text style={{ width: "10%" }} textAlign="center">
              Ending date
            </Text>
            <Text style={{ width: "10%" }} textAlign="center">
              Type
            </Text>
            <Text style={{ width: "35%" }} textAlign="center">
              Promo link
            </Text>
            <Text style={{ width: "8%" }} textAlign="center">
              Offers activated
            </Text>
            <Text style={{ width: "8%" }} textAlign="center">
              Offers completed
            </Text>
            <Text style={{ width: "8%" }} textAlign="center">
              View offer
            </Text>
          </Layout>
          <Layout direction="column">
            {offers.map((offer) => (
              <PunctualOffersRow offer={offer} key={offer.id} linkLanguage={linkLanguage} openModal={handleOpenModal} />
            ))}
          </Layout>
          <PunctualOfferModal
            open={isModalOpen}
            closeAction={() => setIsModalOpen(false)}
            reload={fetchOffers}
            offer={currentUpdatingOffer}
          />
        </Layout>
      )}
    </Layout>
  );
};

export default PunctualOffers;
