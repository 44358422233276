import { queryOptions, useMutation } from "@tanstack/react-query";
import type { Company } from "src/types/Company.type";
import ApiService from "../../../../tools/ApiService";

export const companyQueries = {
  all: () => ["companyInfo"],
  detail: (id: number) =>
    queryOptions({
      queryKey: [...companyQueries.all(), id],
      queryFn: async () => {
        const apiService = ApiService.getInstance();
        const company = await apiService.getCompany(id);
        return company.data as Company;
      },
      staleTime: 5000,
    }),
};

export const useCompanyMutation = (id: number) => {
  const companyQuery = companyQueries.detail(id);
  return useMutation({
    mutationFn: async (payload: Partial<Company>) => {
      const apiService = ApiService.getInstance();
      await apiService.modifyCompany(id, payload);
    },
    mutationKey: companyQuery.queryKey,
  });
};

export const useCompanyImageMutation = (id: number) => {
  const companyQuery = companyQueries.detail(id);
  return useMutation({
    mutationFn: async (payload: FormData) => {
      const apiService = ApiService.getInstance();
      await apiService.modifyCompanyLogo(id, payload);
    },
    mutationKey: companyQuery.queryKey,
  });
};

export const useDeleteCompanyImageMutation = (id: number) => {
  const companyQuery = companyQueries.detail(id);
  return useMutation({
    mutationFn: async () => {
      const apiService = ApiService.getInstance();
      await apiService.deleteCompanyLogo(id);
    },
    mutationKey: companyQuery.queryKey,
  });
};
