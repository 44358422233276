import React, { useContext, useState } from "react";
import { Button, Layout, Tag } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

const CompanyAddressRow = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleDelete = async () => {
    await adminUser.api.deleteAddress(props.address.id);
    setDeleteConfirmation(false);
    props.getAddresses();
  };

  const makeDefault = async () => {
    await adminUser.api.makeAddressDefault(props.address.id, { company_id: props.address.company_id });
    props.getAddresses();
  };
  const makeHq = async () => {
    await adminUser.api.makeAddressHq(props.address.id, { company_id: props.address.company_id });
    props.getAddresses();
  };

  const defaultAddressAction = () => {
    if (props.address.is_archived) {
      return <Tag label="ARCHIVED" backgroundColor="black" variant="outline" />;
    }

    if (props.address.is_default) {
      return <Tag label="DEFAULT ADDRESS" backgroundColor="green" variant="outlined" />;
    }

    return <Button variant="outlined" color="secondary" label="Choose As Default" size="small" onClick={makeDefault} />;
  };

  const isHqAddressAction = () => {
    if (props.address.is_archived) {
      return <Tag label="ARCHIVED" backgroundColor="black" variant="outline" />;
    }

    if (props.address.is_hq) {
      return <Tag label="HQ ADDRESS" backgroundColor="green" variant="outlined" />;
    }

    return <Button variant="outlined" color="secondary" label="Choose As HQ" size="small" onClick={makeHq} />;
  };

  const actionButtons = () => {
    if (deleteConfirmation) {
      return (
        <>
          <p className="delete-confirmation">Are you sure?</p>
          <div className="flex-row">
            <Button variant="contained" color="error" label="Yes" size="small" onClick={handleDelete} />

            <Button
              variant="outlined"
              color="secondary"
              label="No"
              size="small"
              onClick={() => setDeleteConfirmation(false)}
            />
          </div>
        </>
      );
    }

    return (
      <Layout direction="row" spacing={1}>
        <Button variant="contained" color="primary" label="Edit" size="small" onClick={() => props.openModal()} />
        {props.address.is_default || props.address.is_hq ? (
          ""
        ) : (
          <Button
            variant="contained"
            color="error"
            label="Archive"
            size="small"
            onClick={() => setDeleteConfirmation(true)}
          />
        )}
      </Layout>
    );
  };

  return (
    <tr key={props.address.id}>
      <td style={{ paddingLeft: "10px" }}>{defaultAddressAction()}</td>
      <td style={{ paddingLeft: "10px" }}> {isHqAddressAction()} </td>
      <td> {props.address.label} </td>
      <td>
        <p>{props.address.firstName}</p>
        <p>{props.address.lastName}</p>
        <p>{props.address.phone}</p>
      </td>
      <td>
        <p>{props.address.address1}</p>
        <p>{props.address.address2}</p>
      </td>
      <td>{props.address.zip}</td>
      <td>{props.address.city}</td>
      <td>{props.address.country}</td>
      <td>{actionButtons()}</td>
    </tr>
  );
};

export default CompanyAddressRow;
