import { useState } from "react";
import { Layout, Text, IconButton, Icon } from "@fleet.co/tarmac";
import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import type { SxProps } from "@mui/system";

export function Label({ value, copyable, sx }: { value: string; copyable: boolean; sx?: SxProps }) {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopy = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <Layout direction="row" spacing={1} alignItems="center" sx={sx}>
      <Text bold>{value || "-"}</Text>
      {copyable && (
        <IconButton
          icon={<Icon icon={linkCopied ? faCheck : faCopy} size="S" />}
          onClick={handleCopy}
          variant="ghost"
        />
      )}
    </Layout>
  );
}
