import { Icon, Layout, Separator, Tag, Text, theme } from "@fleet.co/tarmac";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { useContext } from "react";
import { Link } from "react-router";
import CImage from "src/components/common/CImage";
import CompanyContext from "src/contexts/CompanyContext";
import { formatMoney } from "src/helpers/formatHelpers";
import { getAppsARR, getMdmARR } from "src/helpers/getARR";
import { calculateGrossSales } from "src/tools/OrderTools";
import type { Company } from "src/types/Company.type";

const getMdmStatus = (company: Company) => {
  const devicesMdm = company.devices?.filter((device) => device.device_mdm && !device.device_mdm.deleted);

  if (
    company.subscriptions?.find((s) => s.type === "MDM" && s.status === "RUNNING") &&
    company.company_mdm?.status === "ACTIVE" &&
    devicesMdm &&
    devicesMdm.length > 0
  ) {
    return "YES";
  }
  if (
    (company.subscriptions &&
      company.subscriptions.length > 0 &&
      company.subscriptions.every((s) => s.type === "MDM" && s.status === "CANCELED")) ||
    company.company_mdm?.status === "INACTIVE" ||
    (company.company_mdm?.status === "ACTIVE" && devicesMdm && devicesMdm.length === 0)
  ) {
    return "CHURN";
  }
  if (!company.subscriptions?.find((s) => s.type === "MDM") && !company.company_mdm) {
    return "NO";
  }
  return "NO";
};

const getSirhIntegration = (company: Company) => {
  const sirhIntegration = company.integrations?.filter(
    (integration) => integration.type === "HRMS" && integration.status === "ACTIVE",
  )[0];

  if (!sirhIntegration) {
    return {
      provider: "NO",
      logo: null,
    };
  }

  let logo = "";

  if (sirhIntegration.provider === "PAYFIT") logo = "payfit_logo_ppuldx";

  if (sirhIntegration.provider === "PERSONIO") logo = "personio_round";

  if (sirhIntegration.provider === "FACTORIAL") logo = "factorial_round";

  if (sirhIntegration.provider === "LUCCA") logo = "lucca_round";

  if (sirhIntegration.provider === "GOOGLE") logo = "google_white_round";

  return {
    provider: sirhIntegration.provider,
    logo,
  };
};

export function CompanyCockpitOverview() {
  const { company } = useContext(CompanyContext);
  if (!company) return null;

  const ordersSigned = company.orders?.filter((order) =>
    ["READY_FOR_PURCHASE", "PURCHASED", "SHIPPED", "DELIVERED", "BDL_SENT", "RECEIVED", "FINILIZED", "CLOSED"].includes(
      order.status,
    ),
  ).length;

  const sirhIntegration = getSirhIntegration(company);

  const lastOrder = company.orders
    ?.filter((order) => order.status !== "UNQUALIFIED_LEAD")
    .sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime())[0];
  const lastOrderRent = lastOrder?.rent ? parseFloat(lastOrder.rent) : 0;

  const upcomingRenewals = company.orders?.filter((order) => {
    const endDate = new Date(order.contract_end_date);
    const today = new Date();
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(today.getMonth() + 6);
    return order.contract_end_date && endDate >= today && endDate <= sixMonthsFromNow;
  });

  let totalGrossSales = 0;
  const validOrders = company.orders?.filter((order) => order.status !== "FAILED" && order.status !== "UNQUALIFIED_LEAD");
  const validDevices = validOrders?.flatMap((order) => order.devices).filter((device) => device.deleted === false);

  if (validOrders) {
    for (const order of validOrders) {
      totalGrossSales += parseFloat(calculateGrossSales(order));
    }
  }

  const mdmStatus = getMdmStatus(company);

  const soldLicences = company.devices?.filter(
    (device) => device.deleted === false && device.device_mdm && !device.device_mdm.deleted,
  ).length;
  const enrolledDevices = company.devices?.filter(
    (device) => device.deleted === false && device.device_mdm && device.device_mdm.status === "ENROLLED",
  ).length;

  const { current: mdmARR, prediction: mdmPrediction } = getMdmARR(company);
  const { current: appsARR, prediction: appsPrediction } = getAppsARR(company);
  const currentTotalARR = mdmARR + appsARR;
  const predictionTotalARR = mdmPrediction + appsPrediction;

  return (
    <Layout
      direction="column"
      flex={1}
      sx={{
        borderRadius: 1,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
      }}
      divider={<Separator orientation="horizontal" />}
    >
      <Layout
        sx={{
          paddingX: 3,
          paddingY: 2,
        }}
      >
        <Text variant="h5" bold sx={{ height: 32, display: "flex", alignItems: "center" }}>
          Cockpit Overview
        </Text>
      </Layout>
      <Layout
        direction="column"
        spacing={2}
        sx={{
          paddingX: 3,
          paddingY: 2,
        }}
      >
        <Layout direction="row" spacing={2}>
          <Layout flex={1}>
            <Text color="primary">Total GB</Text>
            <Text bold>{formatMoney(totalGrossSales, "EUR")}</Text>
          </Layout>
          <Layout flex={1}>
            <Text color="primary">Total orders signed</Text>
            <Text bold>{ordersSigned}</Text>
          </Layout>
        </Layout>

        <Layout direction="row" spacing={2}>
          <Layout flex={1} alignItems="start" justifyContent="start">
            <Text color="primary">Last order</Text>
            <Layout
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ backgroundColor: theme.palette.grey[300], borderRadius: 1, padding: 0.5, width: "100%" }}
            >
              <Text bold>{moment(lastOrder?.purchase_date).format("DD/MM/YYYY")}</Text>
              <Text bold>{formatMoney(lastOrderRent, "EUR")}</Text>

              <Link
                to={`/orders/${lastOrder?.id}`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.green[900],
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  fontWeight: 600,
                }}
              >
                View
                <Icon icon={faChevronRight} color={theme.palette.green[900]} />
              </Link>
            </Layout>
          </Layout>
          <Layout flex={1}>
            <Text color="primary">Upcoming renewal: (next 6 months)</Text>
            <Text bold>{upcomingRenewals?.length}</Text>
          </Layout>
        </Layout>
      </Layout>
      <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
        <Layout direction="row" spacing={2}>
          <Layout flex={1}>
            <Text>Has MDM</Text>
            <Layout direction="row" spacing={1}>
              {mdmStatus === "YES" && <Tag backgroundColor="green" label="Yes" variant="filled" size="small" />}
              {mdmStatus === "NO" && <Tag backgroundColor="orange" label="No" variant="filled" size="small" />}
              {mdmStatus === "CHURN" && <Tag backgroundColor="red" label="Churn" variant="filled" size="small" />}
            </Layout>
          </Layout>
          <Layout flex={1}>
            <Text>Licence sold</Text>
            <Text bold>{soldLicences}</Text>
          </Layout>
        </Layout>
        <Layout direction="row" spacing={2}>
          <Layout flex={1}>
            <Text>
              Total ARR&nbsp;
              <span style={{ color: theme.palette.grey[500] }}>(and potential)</span>
            </Text>
            <Layout direction="row" spacing={1} divider={<Separator orientation="vertical" />}>
              <Text bold>{formatMoney(currentTotalARR, "EUR")}</Text>
              <Text bold color="secondary">
                {formatMoney(predictionTotalARR, "EUR")}
              </Text>
            </Layout>
          </Layout>
          <Layout flex={1}>
            <Text>Device enrolled</Text>
            <Text bold>{enrolledDevices}</Text>
          </Layout>
        </Layout>
      </Layout>

      <Layout direction="column" spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
        <Layout direction="row" spacing={2}>
          <Layout flex={1}>
            <Text>Total equipments</Text>
            <Text bold>{validDevices?.length}</Text>
          </Layout>
          <Layout flex={1}>
            <Text>Total users</Text>
            <Text bold>{company.employees?.length}</Text>
          </Layout>
        </Layout>
        <Layout direction="row" spacing={2}>
          <Layout flex={1}>
            <Text>Fleet equipement</Text>
            <Text bold>{validDevices?.filter((device) => device.source === "FLEET").length}</Text>
          </Layout>
          <Layout flex={1}>
            <Text>Has HRIS integration</Text>
            <Layout direction="row" spacing={1}>
              <Text bold>{sirhIntegration?.provider ?? "No"}</Text>
              {sirhIntegration?.logo && <CImage label={sirhIntegration?.logo} width={24} height={24} />}
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
}
