import React, { useContext, useEffect, useState } from "react";
import { Button, Icon, Layout, Select, TextField } from "@fleet.co/tarmac";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../../tools/UserContext";
import PhoneInput from "../../../tools/PhoneInput";
import CompanyContext from "../../../contexts/CompanyContext";

const AddUserRow = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_country: "",
    roles: ["EMPLOYEE", "ADMIN"],
    company_id: company.id,
  };
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(initialState);
  const [isPhoneValid, setPhoneIsValid] = useState(false);

  const clearForm = () => {
    setUser(initialState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setUser((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const selectRoles = (rolesList) => {
    setUser((prevState) => ({ ...prevState, roles: rolesList }));
  };

  const rolesOptions = [
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "Contract recipient",
      value: "CONTRACT_RECIPIENT",
    },
    {
      label: "Employee",
      value: "EMPLOYEE",
    },
    {
      label: "Legal representative",
      value: "LEGAL_REPRESENTATIVE",
    },
    {
      label: "Signatory",
      value: "SIGNATORY",
    },
  ];

  if (company.id === 1) {
    rolesOptions.push({
      label: "Super Admin",
      value: "FLEET_ADMIN",
    });
  }

  const saveUser = async () => {
    try {
      const newUser = await adminUser.api.addUser(user);

      setUser(newUser.data);
      setMessage("");
      handleCancel();
      props.loadUsers();
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessage(true);
    }
  };

  const handleCancel = () => {
    clearForm();
    props.setShowAddUser(false);
  };

  const handleSave = () => {
    if (!user.email) {
      setMessage("⚠️ Please fill all the fields");
      setShowMessage(true);
    } else if (user.phone && !isPhoneValid) {
      setMessage("⚠️ Please enter a valid phone");
      setShowMessage(true);
    } else {
      saveUser();
    }
  };

  useEffect(() => {
    if (!showMessage) return;
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [showMessage]);

  return (
    <tr className="newRow">
      <td>
        {showMessage && <p className="errorMsg">{message}</p>}
        <TextField
          type="text"
          name="firstName"
          label="First Name"
          value={user.firstName ? user.firstName : ""}
          onChange={handleChange}
        />
      </td>
      <td>
        <TextField
          type="text"
          name="lastName"
          label="Last Name"
          value={user.lastName ? user.lastName : ""}
          onChange={handleChange}
        />
      </td>
      <td>
        <TextField
          required
          type="text"
          name="email"
          label="Email"
          value={user.email ? user.email : ""}
          onChange={handleChange}
        />
      </td>
      <td>
        <PhoneInput
          value={user.phone}
          onChange={handlePhoneChange}
          country={user.phone_country || "fr"}
          setIsPhoneValid={setPhoneIsValid}
          placeholder="Enter phone number"
        />
      </td>
      <td>
        <Select label="Roles" multiple value={user.roles} options={rolesOptions} onChange={selectRoles} />
      </td>
      <td style={{ textAlign: "center" }}>-{/* EMPTY -- emails received */}</td>
      <td style={{ textAlign: "center" }}>0{/* EMPTY -- Notifications */}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon={faTimes} color="secondary" size="S" />
        {/* EMPTY -- password */}
      </td>
      <td>
        <Layout direction="row" spacing={1}>
          <Button variant="contained" color="primary" label="Save" size="small" onClick={handleSave} />

          <Button variant="outlined" color="secondary" label="Cancel" size="small" onClick={handleCancel} />
        </Layout>
      </td>
    </tr>
  );
};

export default AddUserRow;
