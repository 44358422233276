import React, { useContext, useState } from "react";
import { Button } from "@fleet.co/tarmac";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { downloadFileBlob } from "../../helpers/FilesHelpers";
import UserContext from "../../tools/UserContext";

const GenerateFiles = ({ fileType, order, children, setError }) => {
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const generateOrderForm = async () => {
    const response = await adminUser.api.orderForm(order.id);

    downloadFileBlob(response.data, `order_form_contract_${order.contract_number}.pdf`);
  };

  const generateDeliveryForm = async () => {
    const response = await adminUser.api.deliveryForm(order.id);

    downloadFileBlob(response.data, `${order.company.name}_${order.contract_number}_Delivery Confirmation.pdf`);
  };

  const generateInsuranceForm = async () => {
    try {
      const response = await adminUser.api.insuranceForm(order.id);

      setError(response?.data?.message);
    } catch (err) {
      setError(err.response?.data?.message);
    }
  };

  const generateBill = async () => {
    if (!order.reception_date || !order.leaser_rate || !order.delivery_date) {
      setError("You need a reception date, a delivery date and a leaser rate in order to generate an invoice");

      return;
    }

    const response = await adminUser.api.invoiceForm(order.id);

    downloadFileBlob(
      response.data,
      `invoice_${order.bill_number}_${order?.contract_number || order.request_number}.pdf`,
    );
  };

  const generate = async () => {
    setIsLoading(true);
    if (fileType === "BILL") {
      await generateBill();
    } else if (fileType === "DELIVERY_FORM") {
      await generateDeliveryForm();
    } else if (fileType === "ORDER_FORM") {
      await generateOrderForm();
    } else if (fileType === "INSURANCE_FORM") {
      await generateInsuranceForm();
    }
    setIsLoading(false);
  };

  return (
    <Button
      fullWidth
      variant="outlined"
      color="secondary"
      label={children}
      onClick={generate}
      loading={isLoading}
      endIcon={faDownload}
    />
  );
};

export default GenerateFiles;
