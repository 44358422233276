import React, { useMemo } from "react";
import { Icon, Layout, Separator, Text } from "@fleet.co/tarmac";
import { faClock } from "@fortawesome/pro-regular-svg-icons";

import { useSearchContext } from "../../contexts/SearchContext";

const UserResult = (props) => {
  const { user } = props;
  const { searchHistory } = useSearchContext();
  const userName = `${user.firstName || ""} ${user.lastName || ""}`;

  const isFromHistory = useMemo(() => searchHistory.users.some((item) => item.id === user.id), [searchHistory, user]);

  return (
    <Layout
      fullWidth
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      key={`users-${user.id}`}
      divider={<Separator orientation="vertical" />}
    >
      <Text variant="body2" align="flex-start" sx={{ width: "40%" }}>
        {isFromHistory && (
          <>
            <Icon icon={faClock} />{" "}
          </>
        )}
        {userName.trim()}
      </Text>
      <Text variant="body2" align="center" sx={{ width: "30%" }} noWrap>
        {user.email}
      </Text>
      <Text variant="body2" align="center" sx={{ width: "30%" }}>
        {user.company.name}
      </Text>
    </Layout>
  );
};

export default UserResult;
