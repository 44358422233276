import React, { useContext, useState } from "react";
import { Button, Icon, Tag } from "@fleet.co/tarmac";
import { useNavigate } from "react-router";
import { faArrowsRotate, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { leasersList } from "../../../data/leasersList";
import FormatDate from "../../../tools/FormatDate";
import CopyButton from "../../../tools/CopyButton";
import styles from "./DeliveryRow.module.scss";
import { formatMoney } from "../../../helpers/formatHelpers";
import { getLeaserLabel } from "../../../tools/OrderTools";
import UserContext from "../../../tools/UserContext";
import { useToastContext } from "../../../contexts/ToastContext";

const DeliveryRow = (props) => {
  const { user: adminUser } = useContext(UserContext);
  const { order, loadOrders } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToastContext();

  const yousignSignatureRequests = order.yousign_signature_requests;
  const manuallyValidation = ["Spain", "Germany"].includes(order.company.country);
  const notDeliverySigningAskedLeasers = leasersList.filter((leaser) => leaser.automatic_mail_leaser);

  const lastValidSignatureRequest = yousignSignatureRequests
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .find((p) => p.status === "ONGOING" && p.signature_status === "CLIENT_SIGNED_CONTRACT");

  const seeOrderDetails = () => {
    const orderUrl = `/orders/${props.order.id}`;

    navigate(orderUrl);
  };
  const seeOrderDevices = () => {
    const orderUrl = `/orders/${props.order.id}/devices`;

    navigate(orderUrl);
  };

  // Serial Number
  const allDevicesHaveSerialNumber = order.devices.every((device) => device.serial_number);

  const validateOrder = async () => {
    try {
      setIsLoading(true);
      if (notDeliverySigningAskedLeasers?.map((leaser) => leaser.name).includes(order?.leaser)) {
        await adminUser.api.sendDocumentsToLeaser(order.id);
      } else {
        // For order with all the other leaser modify status = received (hubspot = 10B )
        await adminUser.api.modifyOrder(order.id, { status: "BDL_SENT", reception_date: new Date() });
      }
      setIsLoading(false);
      loadOrders();
    } catch (error) {
      addToast(error.response?.data?.message || "An error has occured");
      setIsLoading(false);
    }
  };
  let baseUrl;

  if (import.meta.env.REACT_APP_PR_NUMBER) {
    baseUrl = `https://fleet-co-pr-${import.meta.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
  } else {
    baseUrl = import.meta.env.REACT_APP_APIURL;
  }

  return (
    <tr>
      <td onClick={seeOrderDetails} className={[styles.orderSection, styles.clickable].join(" ")}>
        {order.contract_number || order.request_number}
        <CopyButton value={order.contract_number || order.request_number} />
      </td>
      <td onClick={seeOrderDetails} className={[styles.orderSection, styles.clickable].join(" ")}>
        {order.order_number}
      </td>
      <td className={styles.orderSection}>{order.company.name}</td>
      <td className={styles.orderSection}>
        <FormatDate date={order.delivery_date} />
        <CopyButton value={new Date(order.delivery_date).toLocaleDateString("fr")} />
      </td>
      <td className={[styles.orderSection, styles.clickable].join(" ")} onClick={seeOrderDevices}>
        {allDevicesHaveSerialNumber ? (
          <Tag backgroundColor="green" variant="filled" size="small" label="Complete" />
        ) : (
          <Tag backgroundColor="red" variant="filled" size="small" label="Missing" />
        )}
      </td>
      <td className={[styles.orderSection, styles.clickable].join(" ")} onClick={seeOrderDevices}>
        {order.num_devices}
      </td>
      <td className={styles.orderSection}>{formatMoney(order.net_revenue, order.rent_currency)}</td>
      <td className={styles.orderSection}>{getLeaserLabel(order.leaser)}</td>
      <td>
        {manuallyValidation && (
          <Button
            onClick={() => validateOrder()}
            variant="text"
            color="primary"
            label="Validate"
            endIcon={faChevronRight}
            size="small"
          />
        )}
        {!manuallyValidation &&
          (lastValidSignatureRequest ? (
            <Button
              onClick={() =>
                window.open(
                  `${baseUrl}/yousign/signature-link/${lastValidSignatureRequest.encrypted_signature_request_id}/${lastValidSignatureRequest.encrypted_fleet_id}`,
                )
              }
              variant="text"
              color="primary"
              label="Sign"
              endIcon={isLoading ? <Icon icon={faArrowsRotate} color="secondary" size="S" /> : faChevronRight}
              size="small"
              fullWidth
            />
          ) : (
            <Tag backgroundColor="red" variant="filled" label="No active signature request" />
          ))}
      </td>
    </tr>
  );
};

export default DeliveryRow;
