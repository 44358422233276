import { useContext, useEffect, useState } from "react";
import qs from "qs";
import { Button, Heading, Layout } from "@fleet.co/tarmac";
import { COUNTRIES_LIST } from "src/common/i18n-consts";
import UserContext from "../../tools/UserContext";
import LoadingSpinner from "../common/LoadingSpinner";
import LeaserRow from "../tables/order/LeaserRow";
import OrderFilterMenu from "../blocks/OrderFilterMenu";
import LeaserModal from "../blocks/LeaserModal";
import usePersistedState from "../../hooks/usePersistedState";
import { sortLeaserScoringOrder } from "../../tools/OrderTools";

const Leasers = () => {
  const { user: adminUser } = useContext(UserContext);
  const initial_filters = {
    status: ["LEAD_AGREEMENT", "LEASER_REQUESTED", "LEASER_MORE_DOCUMENTS"],
    status_exclude: ["FAILED"],
    flags: [],
    minDate: null,
    maxDate: null,
    company_country: COUNTRIES_LIST,
  };
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [allOrders, setAllOrders] = useState([]);

  const [filters, setFilters] = usePersistedState("order_filter", {
    ...initial_filters,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [offset, setOffset] = useState(50);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const countFilters = () => {
    let nb_filters = 0;

    nb_filters += filters.flags.length || 0;
    nb_filters += filters.status.length || 0;

    if (filters.minDate) {
      nb_filters += 1;
    }

    if (!filters.status_exclude.length) {
      nb_filters += 1;
    }

    if (filters.maxDate) {
      nb_filters += 1;
    }
    setFilterCount(nb_filters);
  };

  const resetFilters = () => {
    setFilters(initial_filters);
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    let endpointQuery = "";
    const params = {};

    setOffset(50);

    // Copy non-empty state fiters to params
    for (const f of Object.keys(filters)) {
      if (filters[f]) {
        params[f] = filters[f];
      }
    }

    params.order_by = "order_date";
    params.order_dir = "desc";
    params.leaserScoring = true;
    params.kycScoring = true;
    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery}?${qs.stringify(params)}`;
    }
    const fetchedOrders = await adminUser.api.getOrders(endpointQuery);

    // TODO : Merge with OrderTools.getLeaserList ?
    fetchedOrders.data = fetchedOrders.data.map((order) => sortLeaserScoringOrder(order));

    setAllOrders(fetchedOrders.data);
    setIsLoading(false);
  };

  const fetchMoreOrders = async () => {
    setScrollY(window.scrollY);
    let endpointQuery = "/";
    const params = {};

    // Copy non-empty state fiters to params
    for (const f of Object.keys(filters)) {
      if (filters[f]) {
        params[f] = filters[f];
      }
    }

    // Offset
    params.offset = offset;
    params.order_by = "order_date";
    params.order_dir = "desc";

    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery}?${qs.stringify(params)}`;
    }
    const fetchedOrders = await adminUser.api.getOrders(endpointQuery);

    setAllOrders([...allOrders, ...fetchedOrders.data]);
    setOffset(offset + 50);
  };

  const tableCount = () =>
    allOrders.length > 1 ? `${allOrders.length} orders found` : `${allOrders.length} order found`;

  const closeLeaserModal = () => {
    setSelectedOrder(null);
  };

  useEffect(countFilters, [filters]);

  useEffect(() => {
    fetchOrders();
  }, [filters]);

  useEffect(() => window.scrollTo({ top: scrollY }), [offset]);

  if (isLoading) {
    return <LoadingSpinner isFullScreen={false} />;
  }

  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="row" justifyContent="space-between">
        <Heading variant="h2">Leasers</Heading>
        <OrderFilterMenu
          applyFilters={setFilters}
          resetFilters={resetFilters}
          filters={filters}
          filterCount={filterCount}
        />
      </Layout>

      <table className="table back-office-table">
        <thead>
          <tr>
            <td style={{ width: "5%" }}>Order date</td>
            <td style={{ width: "15%" }}>Company Name</td>
            <td style={{ width: "10%" }}>Status</td>
            <td style={{ width: "7%" }}>Nb. Devices</td>
            <td style={{ width: "5%" }}>GBS</td>
            <td
              style={{
                width: "8%",
                textAlign: "center",
                backgroundColor: "#C39BD3",
                color: "black",
              }}
            >
              1
            </td>
            <td
              style={{
                width: "8%",
                textAlign: "center",
                backgroundColor: "#C39BD3",
                color: "black",
              }}
            >
              2
            </td>
            <td
              style={{
                width: "8%",
                textAlign: "center",
                backgroundColor: "#C39BD3",
                color: "black",
              }}
            >
              3
            </td>
            <td
              style={{
                width: "8%",
                textAlign: "center",
                backgroundColor: "#C39BD3",
                color: "black",
              }}
            >
              Selected Leaser
            </td>
            <td style={{ width: "4%" }}>Hubspot</td>
            <td style={{ width: "5%", textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {allOrders.map((o) => (
            <LeaserRow order={o} key={o.id} showCieName setSelectedOrder={setSelectedOrder} />
          ))}
        </tbody>
      </table>

      <LeaserModal
        open={Boolean(selectedOrder)}
        closeAction={closeLeaserModal}
        order={selectedOrder}
        reload={fetchOrders}
      />

      <p className="table-count">{tableCount()}</p>

      <Button variant="outlined" color="secondary" label="See more" onClick={fetchMoreOrders} />
    </Layout>
  );
};

export default Leasers;
