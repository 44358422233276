import React from "react";
import { Alert, Button, Text } from "@fleet.co/tarmac";

const ActionConfirmation = (props) => (
  <Alert severity="warning" onClose={() => props.refuse()}>
    <Text>{props.message}</Text>
    <div className="flex-row">
      <Button variant="contained" color="error" label="Yes" size="small" onClick={() => props.confirm()} />
      <Button variant="contained" color="primary" label="No" size="small" onClick={() => props.refuse()} />
    </div>
  </Alert>
);

export default ActionConfirmation;
