import {
  faCircleCheck,
  faCircleXmark,
  faFacePensive,
  faFileCircleQuestion,
  faSpinner,
  faWindWarning,
} from "@fortawesome/pro-regular-svg-icons";

const leaserStatuses = [
  {
    value: "ACCEPTED",
    label: "Accepted",
    color: "primary",
    icon: faCircleCheck,
  },
  {
    value: "WAITING",
    label: "Waiting",
    color: "info",
    icon: faSpinner,
  },
  {
    value: "MORE_DOCUMENTS",
    label: "More documents",
    color: "warning",
    icon: faFileCircleQuestion,
  },
  {
    value: "CANCELED",
    label: "Canceled",
    color: "error",
    icon: faCircleXmark,
  },
  {
    value: "REJECTED",
    label: "Rejected",
    color: "error",
    icon: faFacePensive,
  },
  {
    value: "REQUEST_FAILED",
    label: "Failed - Auto retry",
    color: "warning",
    icon: faWindWarning,
  },
];

export default leaserStatuses;
