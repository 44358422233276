import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

import CompanyAddressRow from "../../tables/company/CompanyAddressRow";
import AdminAddressesModal from "../../common/addresses/AdminAddressesModal";

const CompanyAddressesInfo = () => {
  const { user: adminUser } = useContext(UserContext);
  const [addresses, setAddresses] = useState([]);
  const [isCompanyAddressModalOpened, setCompanyAddressModalOpened] = useState(false);
  const [currentEditingAddress, setCurrentEditingAddress] = useState(null);

  const params = useParams();

  const fetchAddresses = async () => {
    const fetchedAddresses = await adminUser.api.getAddresses(`?company_id=${params.id}`);

    setAddresses(fetchedAddresses.data);
  };

  const tableCount = () =>
    addresses.length > 1 ? `${addresses.length} addresses found` : `${addresses.length} address found`;

  const openAddressModal = (address) => {
    setCompanyAddressModalOpened(true);
    setCurrentEditingAddress(address);
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    // to always have HQ and default in first
    addresses.sort((a, b) => b.is_default - a.is_default || b.is_hq - a.is_hq);
  });

  return (
    <>
      <Button
        label="Add New Address"
        variant="contained"
        color="primary"
        onClick={() => setCompanyAddressModalOpened(true)}
        sx={{ alignSelf: "flex-end" }}
      />
      <table className="back-office-table table">
        <thead>
          <tr>
            <td style={{ width: "10%" }}>Status</td>
            <td style={{ width: "10%" }}>is HQ</td>
            <td style={{ width: "10%" }}>Label</td>
            <td style={{ width: "15%" }}>Contact info</td>
            <td style={{ width: "25%" }}>Address</td>
            <td style={{ width: "8%" }}>Zip Code</td>
            <td style={{ width: "16%" }}>City</td>
            <td style={{ width: "8%" }}>Country</td>
            <td style={{ width: "8%" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          <AdminAddressesModal
            open={isCompanyAddressModalOpened}
            closeAction={() => setCompanyAddressModalOpened(false)}
            onSave={fetchAddresses}
            companyId={params.id}
            address={currentEditingAddress}
          />

          {addresses.map((a) => (
            <CompanyAddressRow
              address={a}
              key={a.id}
              getAddresses={fetchAddresses}
              openModal={() => openAddressModal(a)}
            />
          ))}
        </tbody>
      </table>
      <p className="table-count"> {tableCount()}</p>
    </>
  );
};

export default CompanyAddressesInfo;
