import { useCallback, useState } from "react";

// Hook
const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      if (item) {
        if (typeof item === "object") {
          return item;
        }

        if (item[0] === "{" || item[0] === "[") {
          return JSON.parse(item);
        }

        return item;
      }

      // Return current value of localStorage item if initialValue is not defined
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        let valueToStore = value;

        // Allow value to be a function so we have same API as useState
        switch (typeof valueToStore) {
          case "string":
            break;
          case "object":
            if (valueToStore === null) {
              break;
            }

            if (Array.isArray(valueToStore)) {
              break;
            }

            valueToStore = JSON.stringify(valueToStore);
            break;
          case "function":
            valueToStore = valueToStore(storedValue);
            break;
          default:
            throw new Error("Type non supporté");
        }

        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(key, valueToStore);
        window.dispatchEvent(new Event(`${key}_storage`));
      } catch (error) {
        throw new Error("Erreur avec l'enregistrement local, veuillez contacter un administrateur.");
      }
    },
    [key, storedValue],
  );

  const removeValue = useCallback(() => {
    window.localStorage.removeItem(key);
    window.dispatchEvent(new Event(`${key}_storage`));
  }, [key]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
