import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Drawer, Heading, Layout, TextField } from "@fleet.co/tarmac";

import UserContext from "../../../tools/UserContext";
import PictureLoader from "../../common/PictureLoader";

const BannerModal = (props) => {
  const { user: adminUser } = useContext(UserContext);

  const [banner, setBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({});
  const [expandFrance, setExpandFrance] = useState(false);
  const [expandSpain, setExpandSpain] = useState(false);
  const [expandGermany, setExpandGermany] = useState(false);
  const [expandOther, setExpandOther] = useState(false);

  useEffect(() => {
    if (props.banner) {
      setBanner(props.banner);
    }
  }, [props.banner]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setBanner({ ...banner, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (Object.keys(files).length > 0) {
        const formData = new FormData();

        for (const key of Object.keys(files)) {
          formData.append(key, files[key]);
        }

        formData.append("title", banner.title);
        formData.append("url", banner.url);

        if (props.banner) {
          await adminUser.api.modifyBanner(props.banner.id, formData);
        } else {
          await adminUser.api.addBanner(formData);
        }
      } else if (props.banner) {
        await adminUser.api.modifyBanner(props.banner.id, banner);
      } else {
        await adminUser.api.addBanner(banner);
      }

      props.onSaveModal();
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const onImageChange = (field, value) => {
    setFiles({ ...files, [field]: value });
  };

  return (
    <Drawer
      onClose={props.onCloseModal}
      title={!props.banner ? "Create a Banner" : "Edit a Banner"}
      Actions={<Button variant="contained" color="primary" label="Save" loading={isLoading} onClick={handleSubmit} />}
      open={props.open}
    >
      <Layout direction="column" isScrollable>
        <Layout direction="column" spacing={2}>
          <TextField label="Title" name="title" value={banner?.title || ""} onChange={handleChange} />

          <TextField label="Url" name="url" value={banner?.url || ""} onChange={handleChange} />
        </Layout>

        <Accordion title="France" expanded={expandFrance} onChange={() => setExpandFrance(!expandFrance)}>
          <Layout direction="column" spacing={2} alignItems="center">
            <Heading variant="h5">Desktop Image must be at format 2880 x 450 px</Heading>
            <PictureLoader
              image={banner?.img_url?.desktopFranceImg}
              onChange={(file) => onImageChange("desktopFranceImg", file)}
            />

            <Heading variant="h5">Mobile Image must be at format 750 x 750 px</Heading>
            <PictureLoader
              image={banner?.img_url?.mobileFranceImg}
              onChange={(file) => onImageChange("mobileFranceImg", file)}
            />
          </Layout>
        </Accordion>

        <Accordion title="Spain" expanded={expandSpain} onChange={() => setExpandSpain(!expandSpain)}>
          <Layout direction="column" spacing={2} alignItems="center">
            <Heading variant="h5">Desktop Image must be at format 2880 x 450 px</Heading>
            <PictureLoader
              image={banner?.img_url?.desktopSpainImg}
              onChange={(file) => onImageChange("desktopSpainImg", file)}
            />

            <Heading variant="h5">Mobile Image must be at format 750 x 750 px</Heading>
            <PictureLoader
              image={banner?.img_url?.mobileSpainImg}
              onChange={(file) => onImageChange("mobileSpainImg", file)}
            />
          </Layout>
        </Accordion>

        <Accordion title="Germany" expanded={expandGermany} onChange={() => setExpandGermany(!expandGermany)}>
          <Layout direction="column" spacing={2} alignItems="center">
            <Heading variant="h5">Desktop Image must be at format 2880 x 450 px</Heading>
            <PictureLoader
              image={banner?.img_url?.desktopGermanyImg}
              onChange={(file) => onImageChange("desktopGermanyImg", file)}
            />

            <Heading variant="h5">Mobile Image must be at format 750 x 750 px</Heading>
            <PictureLoader
              image={banner?.img_url?.mobileGermanyImg}
              onChange={(file) => onImageChange("mobileGermanyImg", file)}
            />
          </Layout>
        </Accordion>

        <Accordion title="UK & Other" expanded={expandOther} onChange={() => setExpandOther(!expandOther)}>
          <Layout direction="column" spacing={2} alignItems="center">
            <Heading variant="h5">Desktop Image must be at format 2880 x 450 px</Heading>
            <PictureLoader
              image={banner?.img_url?.desktopOtherImg}
              onChange={(file) => onImageChange("desktopOtherImg", file)}
            />

            <Heading variant="h5">Mobile Image must be at format 750 x 750 px</Heading>
            <PictureLoader
              image={banner?.img_url?.mobileOtherImg}
              onChange={(file) => onImageChange("mobileOtherImg", file)}
            />
          </Layout>
        </Accordion>
      </Layout>
    </Drawer>
  );
};

export default BannerModal;
