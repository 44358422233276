import React from "react";
import styles from "./ConfigCheckbox.module.scss";

const ConfigCheckbox = (props) => (
  <div
    className={styles.configCheckbox}
    style={props.isChecked ? { border: "1px solid #0DD27A", backgroundColor: "rgba(13, 210, 122,0.1)" } : null}
    onClick={() => props.select(props.name, props.option)}
  >
    <div className={styles.greenMark} style={props.isChecked ? { border: "3px solid #0DD27A" } : null} />
    {props.option}
  </div>
);

export default ConfigCheckbox;
