import React from "react";
import { Icon, Tooltip } from "@fleet.co/tarmac";
import { faComment } from "@fortawesome/pro-regular-svg-icons";

const CommentCell = ({ row: order }) => {
  if (!order.comment) return null;

  return (
    <Tooltip title={order.comment}>
      <Icon icon={faComment} color="secondary" size="S" />
    </Tooltip>
  );
};

export default CommentCell;
