import React, { useState } from "react";
import { Button, Dialog, FileContainer, FileDropzone, Layout, Select, Text } from "@fleet.co/tarmac";
import { fileChecking, imgExtensions } from "../../helpers/FilesHelpers";
import UsersSelect from "../common/UsersSelect";
import LoadingSpinner from "../common/LoadingSpinner";

const FileUploadModal = ({ onClose, companyId, onSave, uploadFileTypes, canAssignUser, open }) => {
  const [fileData, setFileData] = useState({
    type: null,
    file_contents: null,
    user_id: null,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (field, value) => {
    if (field === "file_contents") {
      const errMsg = fileChecking(value, imgExtensions);

      if (errMsg) {
        setErrorMsg(errMsg);
        throw new Error(errMsg);
      }
    }
    setFileData((prevInfo) => ({ ...prevInfo, [field]: value }));
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await onSave(fileData);
    setFileData({
      type: null,
      file_contents: null,
      user_id: null,
    });
    setIsLoading(false);
  };

  const ActionComponent = (
    <>
      <Button color="secondary" label="Close" onClick={onClose} />
      <Button color="primary" label="Save" onClick={onSubmit} disabled={!fileData.file_contents || !fileData.type} />
    </>
  );

  return (
    <Dialog onClose={onClose} Actions={ActionComponent} title="File upload" open={open}>
      <Layout direction="column">
        {isLoading ? (
          <>
            <Text textAlign="center">The process may only take a few seconds.</Text>
            <LoadingSpinner />
          </>
        ) : (
          <>
            Please upload the missing file.
            <Layout direction="column" spacing={2}>
              <Select
                label="Type"
                options={uploadFileTypes}
                value={fileData.type}
                onChange={(value) => onChange("type", value)}
              />

              {canAssignUser && <UsersSelect companyId={companyId} onChange={(value) => onChange("user_id", value)} />}

              {fileData.type && !fileData.file_contents && (
                <FileDropzone
                  onFilesAdded={(files) => onChange("file_contents", files[0])}
                  errorText={errorMsg}
                  helperText="Drag and drop or select"
                />
              )}
              {fileData.file_contents && <FileContainer files={fileData.file_contents} />}
            </Layout>
          </>
        )}
      </Layout>
    </Dialog>
  );
};

export default FileUploadModal;
