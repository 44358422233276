import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, FileDropzone, Layout, Text } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import FileOrderSection from "./FileOrderSection";
import { useToastContext } from "../../contexts/ToastContext";
import CityOfBirthAutocomplete from "./CityOfBirthAutocomplete";

const SignatoryInfoSection = ({ order, signatory, refresh, isEditable }) => {
  const { user: adminUser } = useContext(UserContext);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToastContext();

  useEffect(() => {
    const updated_user = {
      id: signatory?.id,
      firstName: signatory?.firstName,
      lastName: signatory?.lastName,
      email: signatory?.email,
      phone: signatory?.phone,
      phone_country: signatory?.phone_country,
      roles: signatory?.roles ? signatory?.roles.map((r) => r.role) : [],
      notifications: signatory?.notifications ? signatory?.notifications.map((n) => n.type) : [],
      emails: signatory?.emails ? signatory?.emails : [],
      employee_access: signatory?.employee_access,
      files: signatory?.files ? signatory?.files : [],
      city_of_birth: signatory?.city_of_birth,
      country_of_birth: signatory?.country_of_birth,
      date_of_birth: signatory?.date_of_birth,
    };

    setUser(updated_user);
  }, [signatory]);

  const signatoryIdFile = order.company.files.find(
    (file) => signatory && order.signatory_id === signatory.id && file.type === "SIGNATORY_ID" && !file.deleted,
  );

  const signatoryAuthorityFile = order.company.files.find(
    (file) => signatory && order.signatory_id === signatory.id && file.type === "SIGNING_AUTHORITY" && !file.deleted,
  );

  const uploadFile = async (files, type) => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "ACCEPTED");
    formData.append("company_id", order.company_id);
    formData.append("user_id", signatory.id);
    formData.append("file_contents", files[0]);
    await adminUser.api.addFile(formData);
    refresh();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleFileUpload = (type) => (files) => {
    uploadFile(files, type);
  };

  const saveUser = async () => {
    try {
      setIsLoading(true);
      const updatedUser = await adminUser.api.modifyUser(user.id, user);

      setUser({
        ...updatedUser.data,
      });
      addToast("Employee update", "success");

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response?.data?.message) {
        addToast(e.response?.data?.message);
        setUser(signatory);
      }
    }
  };

  return (
    <Layout direction="column" spacing={3}>
      <Layout direction="column" spacing={1} fullWidth>
        <Text variant="body2" bold>
          Name
        </Text>
        <Text variant="body2" noWrap>
          {signatory?.firstName} {signatory?.lastName}
        </Text>
      </Layout>
      <Layout direction="row" spacing={2} alignItems="center" fullWidth>
        <Layout direction="row" spacing={1} fullWidth>
          <DatePicker
            label="Date of birth"
            name="date_of_birth"
            value={user?.date_of_birth ? new Date(user?.date_of_birth) : null}
            onChange={(date) => handleChange({ target: { name: "date_of_birth", value: new Date(date.setHours(12)) } })}
            disabled={!isEditable}
            inputProps={{ fullWidth: true }}
          />
          <Layout fullWidth>
            <CityOfBirthAutocomplete onChange={handleChange} isEditable={isEditable} user={user} />
          </Layout>
        </Layout>
        <Button
          variant="contained"
          color="primary"
          label="Submit"
          disabled={!isEditable}
          onClick={() => saveUser()}
          loading={isLoading}
        />
      </Layout>

      <Layout direction="column" spacing={2}>
        <Text variant="body2">ID DOCUMENT: {signatoryIdFile ? signatoryIdFile.status : "NONE"}</Text>
        {signatoryIdFile ? (
          <FileOrderSection file={signatoryIdFile} refresh={refresh} isEditable={isEditable} />
        ) : (
          <FileDropzone onFilesAdded={handleFileUpload("SIGNATORY_ID")} helperText="Drag and drop or select" />
        )}
      </Layout>

      <Layout direction="column" spacing={2}>
        <Text variant="body2">
          SIGNING AUTHORITY: {signatoryAuthorityFile ? signatoryAuthorityFile.status : "NONE"}
        </Text>
        {signatoryAuthorityFile ? (
          <FileOrderSection file={signatoryAuthorityFile} refresh={refresh} isEditable={isEditable} />
        ) : (
          <FileDropzone onFilesAdded={handleFileUpload("SIGNING_AUTHORITY")} helperText="Drag and drop or select" />
        )}
      </Layout>
    </Layout>
  );
};

export default SignatoryInfoSection;
