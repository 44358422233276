import { Button, Layout } from "@fleet.co/tarmac";
import { faMarker } from "@fortawesome/pro-regular-svg-icons";

export function EditButton({
  onEdit,
  onCancel,
  isEditing,
}: {
  onEdit: () => void;
  onCancel: () => void;
  isEditing: boolean;
}) {
  if (isEditing) {
    return (
      <Layout direction="row" spacing={1} alignItems="center">
        <Button onClick={onCancel} type="button" variant="outlined" size="small" color="secondary" label="Cancel" />
        <Button type="submit" variant="contained" size="small" color="primary" label="Save" />
      </Layout>
    );
  }

  return (
    <Button
      // @ts-expect-error: startIcon is not a valid prop for Button
      startIcon={faMarker}
      onClick={onEdit}
      type="button"
      variant="contained"
      size="small"
      color="primary"
      label="Edit"
    />
  );
}
