const FileTypeNiceName = (props) => {
  const type_option = {
    BALANCE_SHEET: "Balance Sheet",
    KBIS: "KBIS",
    SIGNATORY_ID: "Signatory ID",
    SIGNATORY_ID_VERSO: "Signatory ID Verso",
    IBAN: "IBAN",
    SIGNING_AUTHORITY: "Signing Authority",
    FLEET_INSURANCE: "Fleet Insurance",
    FLEET_INSURANCE_INVOICE: "Fleet Insurance Invoice",
    SEPA_MANDATE: "SEPA Mandate",
    INSURANCE: "Office Insurance",
    CONTRACT: "Contract",
    ORDER_FORM_SIGNED: "Order Form Signed",
    DELIVERY_RECEIPT: "Delivery Receipt",
    SHIPPING_NOTICE: "Shipping Notice",
    OTHER: "Other",
  };

  return type_option[props.type];
};

export default FileTypeNiceName;
