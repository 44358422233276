export const keyboardLayouts = [
  { label: "FR - Français - AZERTY", imgUrl: "France.png", shortName: "France" },
  { label: "ENG - Anglais international - QWERTY", emoji: "🌐", shortName: "globe" },
  { label: "UK - Anglais - QWERTY", imgUrl: "GreatBritain.png", shortName: "Great Britain" },
  { label: "DE - Allemand - QWERTZ", imgUrl: "Germany.png", shortName: "Germany" },
  { label: "US - Anglais américain - QWERTY", imgUrl: "USA.png", shortName: "USA" },
  { label: "ES - Espagnol - QWERTY", imgUrl: "Spain.png", shortName: "Spain" },
  { label: "IT - Italien - QWERTY", imgUrl: "Italy.png", shortName: "Italy" },
  { label: "PT - Portugais - QWERTY", imgUrl: "Portugal.png", shortName: "Portugal" },
  { label: "CH - Suisse - QWERTZ", imgUrl: "Switzerland.png", shortName: "Switzerland" },
];
