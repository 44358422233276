import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Drawer, Layout, TextField } from "@fleet.co/tarmac";
import UserContext from "../../tools/UserContext";
import styles from "./UpdateUnpaidModal.module.scss";

const UpdateUnpaidModal = ({ closeAction, reload, currentUnpaid, open }) => {
  const { user: adminUser } = useContext(UserContext);
  const [unpaidContractInput, setUnpaidContractInput] = useState("");
  const [updateInfo, setUpdateInfo] = useState({ newUnpaidLines: 0, newResolvedLines: 0 });
  const [isCheckBoxChecked, setCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);
      // Replace all emptyspace with replace all because trim replace \n too
      // Replace every element that may be empty (for many following \n for exemple)
      // Remove duplicate contract numbers
      const rawUnpaidContractNumbers = unpaidContractInput
        .replaceAll(" ", "")
        .split("\n")
        .filter(Boolean)
        .filter((item, index, self) => self.indexOf(item) === index);
      const unpaidContractInfos = {
        contract_ids: rawUnpaidContractNumbers,
      };

      await adminUser.api.batchUpdateUnpaid(unpaidContractInfos);
      setIsLoading(false);
      await reload();
      closeAction();
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }, [adminUser.api, closeAction, reload, unpaidContractInput]);

  const handleChange = (e) => {
    setUnpaidContractInput(e.target.value);
  };

  const checkDiff = useCallback(() => {
    const importedUnpaid = unpaidContractInput
      .replaceAll(" ", "")
      .split("\n")
      .filter(Boolean)
      .filter((item, index, self) => self.indexOf(item) === index);
    const oldUnpaid = currentUnpaid?.filter((cu) => cu.status === "UNPAID").map((cu) => cu.order.contract_number);
    const newUnpaidLines = importedUnpaid.filter((iu) => !oldUnpaid.includes(iu)).length;
    const newResolvedLines = oldUnpaid.filter((ou) => !importedUnpaid.includes(ou)).length;

    setUpdateInfo({ newUnpaidLines, newResolvedLines });
  }, [currentUnpaid, unpaidContractInput]);

  useEffect(() => {
    checkDiff();
  }, [checkDiff, currentUnpaid, unpaidContractInput]);

  return (
    <Drawer
      open={open}
      onClose={closeAction}
      Actions={
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          label="Update"
          loading={isLoading}
          disabled={!isCheckBoxChecked}
        />
      }
      title="Update Unpaid"
    >
      <Layout direction="column">
        <TextField
          label="Add the list of orders flagged as unpaid"
          placeholder="Paste the list of unpaid contract number separated by new line"
          value={unpaidContractInput}
          onChange={handleChange}
          className={styles.unpaidTextArea}
          multiline
          rows={10}
        />

        <Checkbox
          label={`By clicking on "UPDATE" you will add ${updateInfo.newUnpaidLines} possible new unpaid and resolve ${updateInfo.newResolvedLines}`}
          checked={isCheckBoxChecked}
          onChange={() => setCheckbox(!isCheckBoxChecked)}
        />
      </Layout>
    </Drawer>
  );
};

export default UpdateUnpaidModal;
