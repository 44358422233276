import React, { useCallback, useContext, useEffect, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Icon, Layout, TextField } from "@fleet.co/tarmac";
import qs from "qs";
import styles from "./ContractEndingCSM.module.scss";
import UserContext from "../../../tools/UserContext";
import ContractReturnRow from "./ContractReturnRow";
import RenewalReminderConfirmation from "./RenewalReminderConfirmation";
import LoadingSpinner from "../../common/LoadingSpinner";
import ReturnFilterMenu from "../../blocks/ReturnFilterMenu";
import usePersistedState from "../../../hooks/usePersistedState";

const initialFilters = {
  contractStartMinDate: null,
  contractStartMaxDate: null,
  contractEndMinDate: null,
  contractEndMaxDate: null,
  searchCompanyNameSerialNumber: "",
  someParcelStatus: "",
};

const ContractReturnCSM = () => {
  const { user: adminUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [dateSorting, setDateSorting] = useState("asc");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const [filters, setFilters] = usePersistedState("renewal_return_filters", { ...initialFilters });

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    let endpointQuery = "";
    const params = {};

    params.order_by = "contract_end_date";
    params.order_dir = dateSorting;
    params.orderStatus = ["CLOSED", "INACTIVE"];
    params.renewalDecision = true;
    params.devicesRenewalType = ["BROKEN", "STOP", "RENEW"];

    // Filters
    if (filters.contractStartMinDate) {
      params.contractMinDate = new Date(filters.contractStartMinDate);
    }

    if (filters.contractStartMaxDate) {
      params.contractMaxDate = new Date(filters.contractStartMaxDate);
    }

    if (filters.contractEndMinDate) {
      params.contractEndMinDate = new Date(filters.contractEndMinDate);
    }

    if (filters.contractEndMaxDate) {
      params.contractEndMaxDate = new Date(filters.contractEndMaxDate);
    }

    if (filters.searchCompanyNameSerialNumber !== "") {
      params.search_company_name_serial_number = filters.searchCompanyNameSerialNumber;
    }

    if (filters.someParcelStatus !== "") {
      params.someParcelStatus = filters.someParcelStatus;
    }

    if (Object.keys(params).length) {
      endpointQuery = `${endpointQuery}?${qs.stringify(params)}`;
    }
    const fetchedOrders = await adminUser.api.getOrders(endpointQuery);

    setOrders(fetchedOrders.data);
    setIsLoading(false);
  }, [adminUser.api, dateSorting, filters]);

  const handleSort = useCallback(() => {
    if (dateSorting === "desc") {
      setDateSorting("asc");
    } else {
      setDateSorting("desc");
    }
  }, [dateSorting]);

  const sendReminders = useCallback(async () => {
    setIsSending(true);
    for (const order of selectedOrders) {
      await adminUser.api.sendRenewalReturnReminder(order.id);
    }
    setSelectedOrders([]);
    setIsSending(false);
    setIsConfirmModalOpen(false);
    fetchOrders();
  }, [adminUser.api, fetchOrders, selectedOrders]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders, dateSorting, filters]);

  return (
    <>
      <Layout direction="column" spacing={2}>
        <Layout direction="row" spacing={1} justifyContent="flex-end">
          <TextField
            type="search"
            label="Company, Serial Number..."
            value={filters.searchCompanyNameSerialNumber}
            onChange={(e) => setFilters({ ...filters, searchCompanyNameSerialNumber: e.target.value })}
          />
          <ReturnFilterMenu applyFilters={setFilters} resetFilters={resetFilters} filters={filters} />
        </Layout>
        <Layout direction="row" className={styles.tableHead}>
          <div className={styles.littleCell} />
          <div>Contract number</div>
          <div>Contract start</div>
          <Layout direction="row" className={styles.pointer} onClick={handleSort} spacing={1}>
            <div>Contract end</div>{" "}
            <Icon icon={dateSorting === "desc" ? faChevronDown : faChevronUp} color="secondary" size="S" />
          </Layout>
          <div>Company</div>
          <div>Generated labels</div>
          <div>Sent devices</div>
          <div>Late pickups</div>
          <div />
        </Layout>

        <Layout direction="column">
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            orders.map((o) => (
              <ContractReturnRow
                order={o}
                key={o.id}
                reloadOrders={fetchOrders}
                isSelected={selectedOrders.includes(o)}
              />
            ))}
        </Layout>
      </Layout>
      <RenewalReminderConfirmation
        closeModal={() => setIsConfirmModalOpen(false)}
        numberOfMail={selectedOrders.length}
        confirmAction={sendReminders}
        isLoading={isSending}
        open={isConfirmModalOpen}
      />
    </>
  );
};

export default ContractReturnCSM;
