import React from "react";
import { Tag } from "@fleet.co/tarmac";

const eolTypeColors = {
  OTHER: "grey",
  RENEW: "green",
  STOP: "orange",
  BUYBACK: "cyan",
  LOST: "pink",
  BROKEN: "red",
  UPGRADE: "purple",
};

const eolLabels = {
  OTHER: "🕣 NOT CHOSEN",
  RENEW: "♻️ RENEW",
  STOP: "⏮️ RETURN",
  BUYBACK: "💵 BUYBACK",
  LOST: "❓ LOST",
  BROKEN: "🔨 BROKEN",
  UPGRADE: "📈 UPGRADE",
};

const EndOfLifeTypeTag = (props) => {
  const color = eolTypeColors[props.type || "OTHER"];

  return <Tag label={eolLabels[props.type || "OTHER"]} backgroundColor={color} variant="filled" size="small" />;
};

export default EndOfLifeTypeTag;
