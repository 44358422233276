import React from "react";
import { Icon } from "@fleet.co/tarmac";
import { faCaretDown } from "@fortawesome/pro-regular-svg-icons";

const FileStatusNiceName = (props) => {
  const statusOption = {
    ACCEPTED: "Accepted",
    REFUSED: "Refused",
    TO_EXAMINE: "To examine",
    EMPTY: "Empty",
  };

  const statusColor = {
    ACCEPTED: "#A4DBC1", // $f_green_130
    REFUSED: "#F5ABAB", // #f5abab
    TO_EXAMINE: "#F9E79F", // /$f_yellow_200
    EMPTY: "#F5ABAB", // #f5abab
  };

  const style = {
    width: "90px",
    height: "32px",
    borderRadius: "4px",
    backgroundColor: statusColor[props.status],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
  };

  if (props.isEditable) {
    style.cursor = "pointer";
  }

  return (
    <div style={style}>
      {statusOption[props.status]}
      {props.isEditable && <Icon icon={faCaretDown} color="secondary" size="S" style={{ marginLeft: "6px" }} />}
    </div>
  );
};

export default FileStatusNiceName;
