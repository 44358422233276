import React from "react";
import { Layout, Text } from "@fleet.co/tarmac";

const OfferCompanyDetails = (props) => {
  const { order } = props;

  const startedOrderStatus = ["LEAD_AGREEMENT", "LEASER_MORE_DOCUMENTS", "LEAD_SIGNING"];
  const signedOrderStatus = ["READY_FOR_PURCHASE", "PURCHASED", "SHIPPED", "DELIVERED", "BDL_SENT", "RECEIVED"];

  const completedOrderStatus = ["FINALIZED", "CLOSED", "LEASER_DISAGREEMENT", "INACTIVE"];

  const displayStatus = () => {
    if (startedOrderStatus.includes(order.status)) {
      return "Started";
    }

    if (completedOrderStatus.includes(order.status)) {
      return "Completed";
    }

    if (signedOrderStatus.includes(order.status)) {
      return "Signed";
    }

    return "Failed";
  };

  return (
    <Layout direction="row" justifyContent="space-between">
      <Text textAlign="start" size="S">
        {order?.company?.name || "-"}{" "}
      </Text>
      <Text textAlign="start" size="S">
        {order?.purchaser?.email || "-"}
      </Text>
      <Text textAlign="end" size="S">
        {displayStatus()}
      </Text>
    </Layout>
  );
};

export default OfferCompanyDetails;
